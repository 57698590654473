import cx from 'classnames';
import { Text } from 'components/@tedui';
import type { ButtonGroupProps } from './ButtonGroup.props';

const ButtonGroup = ({
  buttons,
  activeButton,
  buttonAction
}: ButtonGroupProps): React.ReactNode => {
  return (
    <div className="flex gap-2">
      {buttons.map(button => (
        <button
          key={button.value}
          className={cx('rounded-md px-3 py-2 transition-colors lg-tui:px-4', {
            'bg-white': activeButton === button.value,
            'bg-white/20': activeButton !== button.value
          })}
          onClick={() => buttonAction(button.value)}
          data-testid={`Button Group ${
            activeButton === button.value ? 'Active' : ''
          } Item`}
        >
          <Text
            variant="body2"
            useNewTextStyles
            weight={
              activeButton === button.value ? 'font-semibold' : 'font-normal'
            }
            color={{
              override: activeButton !== button.value ? '#828282' : undefined
            }}
          >
            {button.label}
          </Text>
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;
