import type { ComponentPropsWithoutRef } from 'react';

import type IconProps from '../../Icon/Icon.props';

export enum IconPositionType {
  Leading = 'LEADING',
  Trailing = 'TRAILING'
}

type IconOptions = {
  name?: IconProps['iconName'];
  position?: IconPositionType;
};

type Valid = {
  status: 'valid';
};

type Error = {
  status: 'error';
  errorMessage: string;
};

export type Validation = Valid | Error | null;

export function isError(validation?: Validation): validation is Error {
  return (validation as Error)?.errorMessage !== undefined;
}

export type InputProps = ComponentPropsWithoutRef<'input'> & {
  id: string;
  label: string;
  helper?: string;
  iconOptions?: IconOptions;
  validation?: Validation;
  isDarkBackground?: boolean;
  searchOnClick?: () => void;
  filterOnClick?: () => void;
  testId?: string;
  useNewComponent?: boolean;
};
