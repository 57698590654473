import cn from 'classnames';
import { truncate } from 'lodash-es';

import Text from '../../Text';
import CardTitleProps from './CardTitle.props';

const CardTitle = ({
  text,
  isBold,
  testId,
  truncateText = true,
  truncateLength = 36,
  variant = 'header3',
  UNSAFE_className,
  useNewTextStyles = true,
  weight = 'font-semibold',
  color,
  callback,
  addBottomMargin = true
}: CardTitleProps) => {
  return (
    <div
      onClick={callback}
      aria-hidden="true"
      className={cn(addBottomMargin && 'mb-2')}
    >
      <Text
        UNSAFE_className={UNSAFE_className}
        variant={variant}
        isBold={isBold}
        weight={weight}
        testId={testId}
        useNewTextStyles={useNewTextStyles}
        color={color}
      >
        {truncateText ? truncate(text, { length: truncateLength }) : text}
      </Text>
    </div>
  );
};

export default CardTitle;
