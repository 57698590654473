import SkeletonItem from 'components/pages/homepage/SkeletonItem/SkeletonItem';
import { FC } from 'react';

interface RibbonSkeletonProps {
  count: number;
  darkMode?: boolean;
  showTitle?: boolean;
}

const RibbonSkeleton: FC<RibbonSkeletonProps> = ({
  count,
  darkMode = false,
  showTitle = false
}) => {
  return (
    <div className="w-full">
      {showTitle && (
        <div className="mb-6 flex flex-col gap-2">
          <SkeletonItem className="h-4 w-32" darkMode={darkMode} />
          <SkeletonItem className="h-6 w-64" darkMode={darkMode} />
        </div>
      )}

      <div className="flex w-full gap-4">
        {Array.from({ length: count }).map((_, index) => (
          <div key={index} className="flex w-full flex-col gap-2">
            <SkeletonItem
              className="aspect-h-9 aspect-w-16 w-full"
              darkMode={darkMode}
            />

            <div className="flex flex-col gap-2">
              <SkeletonItem className="h-4 w-3/4" darkMode={darkMode} />
              <SkeletonItem className="h-4 w-1/2" darkMode={darkMode} />
            </div>

            <div className="mt-2 flex gap-2">
              <SkeletonItem
                className="h-6 w-16 rounded-full"
                darkMode={darkMode}
              />
              <SkeletonItem
                className="h-6 w-16 rounded-full"
                darkMode={darkMode}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RibbonSkeleton;
