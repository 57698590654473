import { crush } from '@tedconf/js-crushinator-helpers';
import cx from 'classnames';
import { Link } from 'components/router';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import dynamic from 'next/dynamic';

import { useCallback, useEffect, useRef, useState } from 'react';
import SwiperCore, {
  Lazy,
  Mousewheel,
  Navigation,
  Pagination,
  Swiper
} from 'swiper/core';
import { SwiperSlide } from 'swiper/react';

import mixpanel from 'lib/analytics/mixpanel';

import { useDarkMode } from 'components/theme/DarkModeProvider';
import { trackVWOCustomConversionById } from 'lib/analytics/vwo';
import TitleBar from '../../TitleBar';
import { NavigationButton } from './NavigationButton';
import type RibbonProps from './Ribbon.props';
import { RibbonItem } from './RibbonItem';
import RibbonSkeleton from './RibbonSkeleton';

const DynamicSwiper = dynamic(
  () => import('swiper/react').then(mod => mod.Swiper),
  { ssr: false }
);

SwiperCore.use([Mousewheel, Navigation, Pagination, Lazy]);

const Ribbon = ({
  slides,
  slidesPerView,
  titleBar,
  testId = 'ribbon',
  isNewCard = false,
  isTalkCard = false,
  mixpanelEventPrefix
}: RibbonProps) => {
  const { isDarkMode } = useDarkMode();
  const isTabletWidth = useIsBreakpointWidth({
    size: 'xl',
    breakPointType: 'tui'
  });
  const isMobileWidth = useIsBreakpointWidth({});

  const ribbonMediaRef = useRef<HTMLDivElement>(null);
  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);

  const [isSwiperReady, setSwiperReady] = useState(false);
  const [ribbon, setRibbon] = useState<Swiper | null>(null);
  const [ribbonMediaHeight, setRibbonMediaHeight] = useState<number>(0);

  const navigationClasses = cx(
    'absolute z-10 flex items-center justify-between',
    titleBar ? 'top-7 lg-tui:top-10' : 'top-0'
  );

  const handleResize = useCallback(() => {
    const offSet = 180;
    if (ribbonMediaRef.current) {
      const newHeight = ribbonMediaRef.current.getBoundingClientRect().height;
      setRibbonMediaHeight(prev => {
        if (newHeight !== prev) {
          return isNewCard ? newHeight + offSet : newHeight;
        }
        return prev;
      });
    }
  }, [isNewCard]);

  useEffect(() => {
    if (
      ribbonMediaHeight <= 0 ||
      ribbonMediaRef?.current?.getBoundingClientRect().height !==
        ribbonMediaHeight
    ) {
      handleResize();
    }
  }, [ribbonMediaHeight, ribbonMediaRef, ribbon, handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, ribbonMediaRef]);

  const renderSkeleton = () => {
    let skeletonCount = slidesPerView?.desktop || 4;

    if (isMobileWidth) {
      skeletonCount = slidesPerView?.mobile || 1;
    }

    if (isTabletWidth) {
      skeletonCount = slidesPerView?.tablet || 2;
    }

    return (
      <RibbonSkeleton
        count={skeletonCount}
        darkMode={isDarkMode}
        showTitle={!!titleBar}
      />
    );
  };

  return (
    <div className="relative w-full">
      {!isSwiperReady ? renderSkeleton() : null}

      <DynamicSwiper
        data-testid={testId}
        slidesPerView={slidesPerView?.desktop}
        slidesPerGroup={slidesPerView?.desktop}
        spaceBetween={20}
        lazy
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: slidesPerView?.mobile,
            slidesPerGroup: slidesPerView?.mobile,
            spaceBetween: 12
          },
          // when window width is >= 768px
          768: {
            slidesPerView: slidesPerView?.tablet,
            slidesPerGroup: slidesPerView?.tablet,
            spaceBetween: 12
          },
          // when window width is >= 640px
          1024: {
            slidesPerView: slidesPerView?.desktop,
            slidesPerGroup: slidesPerView?.desktop,
            spaceBetween: 20
          }
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current
        }}
        pagination={{
          bulletClass: `cursor-pointer swiper-pagination-bullet ${
            isDarkMode && 'dark'
          }`,
          bulletActiveClass: `swiper-active-bullet ${isDarkMode && 'dark'}`,
          clickable: true,
          clickableClass: '!bottom-0',
          dynamicMainBullets: 3
        }}
        mousewheel={{
          releaseOnEdges: true,
          forceToAxis: true,
          thresholdDelta: 3
        }}
        onInit={() => setSwiperReady(true)}
        onSwiper={swiper => {
          setRibbon(swiper);
          setTimeout(() => {
            if (!swiper) return;

            // Override prevEl & nextEl now that refs are defined
            if (swiper.params && typeof swiper.params.navigation === 'object') {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;

              // Re-init navigation
              if (swiper.navigation) {
                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();
              }
            }
          }, 300);
        }}
        data-desktop-slides-per-view={slidesPerView?.desktop}
        data-tablet-slides-per-view={slidesPerView?.tablet}
        data-mobile-slides-per-view={slidesPerView?.mobile}
        className="relative !pb-10"
      >
        {titleBar && (
          <section data-testid={`${testId}-Title-Bar`} slot="container-start">
            <TitleBar
              title={titleBar?.title}
              iconName={titleBar?.iconName}
              secondaryAction={titleBar?.secondaryAction}
            />
          </section>
        )}

        {slides.map(
          ({
            badges,
            label,
            type = 'Talk',
            image,
            landscapeImage,
            link,
            onClick,
            title,
            publishedAt,
            topics
          }) => {
            const isTalk = type === 'Talk';
            const imageUrl = crush(
              isTalk ? (image?.src ?? '') : (landscapeImage?.src ?? ''),
              {
                width: 600
              }
            ).toString();
            const imageAlt = isTalk
              ? (image?.alt ?? '')
              : (landscapeImage?.alt ?? '');

            return (
              <SwiperSlide key={link} data-testid={`${testId}-slide`}>
                <Link
                  onClick={() => {
                    const eventPrefix = isNewCard ? 'new' : 'talk';
                    if (isNewCard || isTalkCard) {
                      mixpanel.track(`${eventPrefix}_card_ribbon_click`, {
                        talk_slug: link
                      });
                      trackVWOCustomConversionById(217);
                    }
                    onClick?.();
                  }}
                  href={link}
                  isZenithLink
                  data-testid={`${testId}-slide-link`}
                  className="z-20"
                >
                  <RibbonItem
                    isNewCard={isNewCard}
                    ref={ribbonMediaRef as React.RefObject<HTMLInputElement>}
                    isTalk={isTalk}
                    image={{
                      src: imageUrl,
                      alt: imageAlt,
                      sizes: image?.sizes
                    }}
                    badges={badges}
                    title={title}
                    label={label}
                    publishedAt={publishedAt}
                    mixpanelEventPrefix={mixpanelEventPrefix}
                    topics={topics}
                  />
                </Link>
              </SwiperSlide>
            );
          }
        )}
      </DynamicSwiper>

      {ribbonMediaHeight > 0 && (
        <>
          <div
            style={{ height: ribbonMediaHeight }}
            className={navigationClasses}
          >
            <NavigationButton
              isNewCard={isNewCard}
              isTalkCard={isTalkCard}
              direction="left"
              navigationRef={navigationPrevRef}
            />
          </div>
          <div
            style={{ height: ribbonMediaHeight }}
            className={cx(navigationClasses, `right-0`)}
          >
            <NavigationButton
              isNewCard={isNewCard}
              isTalkCard={isTalkCard}
              direction="right"
              navigationRef={navigationNextRef}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Ribbon;
