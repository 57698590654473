import cx from 'classnames';
import { motion } from 'motion/react';
import type { ReactElement } from 'react';
import {
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { createPortal } from 'react-dom';

import { client } from 'f';

import { Icon, Text } from 'components/@tedui';
import { useClickOutside } from 'lib/hooks/useClickOutside';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';

import type { DropdownProps } from './Dropdown.props';
import type { DropdownItemProps } from './DropdownItem/DropdownItem.props';
import { useKeyboardEvents } from './hooks';
import { useDropdownStore } from './store';

/**
 * Dropdown is a customizable dropdown component that allows users to select a single option from a list.
 * @param label - The label for the dropdown.
 * @param onChange - The callback function called when an option is selected (optional).
 * @param selectedValue - The default selected value of the dropdown (optional).
 * @param portal - The portal to render the dropdown menu in (optional).
 * @param children - The list of dropdown items to display within the dropdown.
 * @param buttomProps - The list of dropdown items to display within the dropdown.
 * @returns The Dropdown component with the specified label, options, and functionality.
 */
export const Dropdown = ({
  label,
  children,
  selectedValue,
  onChange,

  portal = {
    containerId: 'filterBar',
    scrollContainerId: 'filterBarScrollArea'
  },

  useNewComponent = false
}: DropdownProps) => {
  const { openLabel, toggleDropdown } = useDropdownStore();
  const isMobileWidth = useIsBreakpointWidth({
    size: 'lg',
    breakPointType: 'tui'
  });

  const [isContainerScrolled, setIsContainerScrolled] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [selectedOption, setSelectedOption] = useState(() => {
    const defaultOption = selectedValue
      ? { label: selectedValue.label, value: selectedValue.value }
      : null;
    return defaultOption;
  });

  const isOpen = openLabel === label;

  const containerElement = client
    ? document.getElementById(portal.containerId)
    : null;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownId = `dropdown-menu`;
  const dropdownItems = Children.toArray(children).filter(
    (child): child is ReactElement => isValidElement(child)
  );

  const scrollContainer = client
    ? (document.getElementById(portal.scrollContainerId) as HTMLElement)
    : null;

  useEffect(() => {
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', () => {
        setIsContainerScrolled(scrollContainer.scrollLeft > 0);
      });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', () => {
          setIsContainerScrolled(false);
        });
      }
    };
  }, [isOpen, scrollContainer]);

  useClickOutside({
    ref: dropdownRef,
    onClickOutside: () => {
      toggleDropdown(null);
    },
    portalContainerId: dropdownId
  });

  useEffect(() => {
    if (selectedValue) {
      const defaultOption = Children.toArray(children).find(
        child =>
          isValidElement<DropdownItemProps>(child) &&
          child.props?.value === selectedValue?.value
      ) as ReactElement<DropdownItemProps> | undefined;

      if (defaultOption) {
        setSelectedOption({
          label: defaultOption.props?.label,
          value: defaultOption.props?.value
        });
      }

      setSelectedOption({
        label: selectedValue.label,
        value: selectedValue.value
      });
    }

    if (!selectedValue && label) {
      setSelectedOption({
        label: '',
        value: ''
      });
    }
  }, [children, label, selectedValue]);

  const handleToggleDropdown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    toggleDropdown(isOpen ? null : label);
  };

  const handleSelectItem = useCallback(
    ({ label, value }: Omit<DropdownItemProps, 'onSelect'>) => {
      const selectedItem = Children.toArray(children).find(
        child =>
          isValidElement<DropdownItemProps>(child) &&
          child.props.value === value
      );

      if (isValidElement<DropdownItemProps>(selectedItem)) {
        if (selectedItem.props.onSelect) selectedItem.props.onSelect(value);

        if (selectedItem.props.buttonOptions) {
          selectedItem.props.buttonOptions.onClick?.();
        }
      }

      setSelectedOption({
        label,
        value
      });
      toggleDropdown(label);
      if (onChange) {
        onChange(value);
      }
    },
    [children, onChange, toggleDropdown]
  );

  const { handleKeyDown } = useKeyboardEvents({
    isOpen,
    label,
    highlightedIndex,
    handleSelectItem,
    setHighlightedIndex,
    childrenArray: dropdownItems
  });

  const shouldShrinkLabel =
    isOpen || (selectedOption && selectedOption.label.trim() !== '');

  const classNames = {
    label: cx('absolute transition-all', {
      '-top-2 left-1 bg-white px-1 text-left text-tui-xs': shouldShrinkLabel,
      'left-2 top-1/2 -translate-y-1/2': !shouldShrinkLabel
    }),
    input: cx(
      'peer w-full overflow-hidden whitespace-nowrap rounded-sm border-thin border-solid border-black/16 py-3 pl-2 text-left placeholder-transparent outline-none focus:border-blue-300',
      {
        'min-h-[3rem] pr-4 md-tui:pr-12': !useNewComponent,
        'min-w-24 !border-none !pr-12': useNewComponent,
        'bg-white':
          (selectedOption && selectedOption.label.trim() !== '') ||
          (selectedValue && selectedValue.label.trim() !== '') ||
          isOpen
      }
    ),
    inputGradientContainer: cx(
      'pointer-events-none absolute inset-0 flex flex-col justify-center'
    ),
    inputGradient: cx('absolute right-px h-[90%] w-12', {
      'bg-gradient-to-r from-transparent via-white to-white':
        (selectedOption && selectedOption.label.trim() !== '') ||
        (selectedValue && selectedValue.label.trim() !== '')
    }),
    icon: cx('absolute right-2 top-1/2 mr-2 -translate-y-1/2 transform'),
    dropdownMenu: cx(
      'border absolute z-50 mt-1 flex w-max flex-col border-thin border-solid border-black/16 bg-white',
      {
        'rounded-sm lg-tui:left-0': !useNewComponent,
        'shadow-[0px 4px 4px 0px #0000001A] -left-20 rounded-[6px] p-6':
          useNewComponent,
        'left-0': !portal.containerId
      }
    )
  };

  const getPosition = useCallback(() => {
    const menuWidth = dropdownRef.current?.offsetWidth || 0;
    const left =
      isContainerScrolled && dropdownRef.current
        ? dropdownRef.current?.offsetLeft - (scrollContainer?.scrollLeft || 0)
        : dropdownRef.current?.offsetLeft;
    const right = window.innerWidth - (left || 0) - menuWidth;

    if (
      (left || 0) > window.innerWidth / 2 &&
      !isContainerScrolled &&
      isMobileWidth
    ) {
      return { right };
    }

    return { left };
  }, [isContainerScrolled, scrollContainer, isMobileWidth]);

  const renderItem = useMemo(() => {
    const position = isOpen && isMobileWidth ? getPosition() : {};

    return (
      <ul
        role="listbox"
        id={dropdownId}
        className={classNames.dropdownMenu}
        style={
          isOpen && !useNewComponent && isMobileWidth && dropdownRef.current
            ? {
                ...position,
                top:
                  dropdownRef.current.offsetTop +
                  dropdownRef.current.offsetHeight
              }
            : {}
        }
      >
        {Children.map(children, (child, index) => {
          if (isValidElement<DropdownItemProps>(child)) {
            return cloneElement(child, {
              onSelect: () =>
                handleSelectItem({
                  label: child.props?.label,
                  value: child.props?.value
                }),
              isSelected: highlightedIndex === index
            });
          }
          return child;
        })}
      </ul>
    );
  }, [
    dropdownId,
    children,
    classNames.dropdownMenu,
    handleSelectItem,
    isOpen,
    highlightedIndex,
    isMobileWidth,
    getPosition
  ]);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        type="button"
        className={classNames.input}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        onClick={handleToggleDropdown}
        onKeyDown={handleKeyDown}
      >
        <div className={classNames.inputGradientContainer}>
          <div className={classNames.inputGradient} aria-hidden="true" />
        </div>
        <Icon
          iconName={isOpen ? 'chevron-up' : 'chevron-down'}
          className={classNames.icon}
        />
        {label && selectedOption && (
          <motion.span
            transition={{ duration: 0.2 }}
            className={classNames.label}
          >
            <Text
              variant={shouldShrinkLabel ? 'caption' : 'body2'}
              color={{ color: 'secondary' }}
              tag={shouldShrinkLabel ? 'span' : 'p'}
            >
              {label}
            </Text>
          </motion.span>
        )}
        {selectedOption?.label.trim() !== '' && (
          <Text
            tag="p"
            variant="body2"
            color={{
              color: 'secondary'
            }}
          >
            {selectedOption?.label}
          </Text>
        )}
      </button>

      {isOpen &&
        (containerElement && isMobileWidth
          ? createPortal(renderItem, containerElement)
          : renderItem)}
    </div>
  );
};
