const fonts = {
  display1: {
    xs: 'xs-tui:text-tui-11xl xs-tui:leading-[100%] xs-tui:-tracking-[2.75px]',
    sm: 'sm-tui:text-tui-11xl sm-tui:leading-[100%] sm-tui:-tracking-[2.75px]',
    md: 'md-tui:text-tui-12xl md-tui:leading-[100%] md-tui:-tracking-[3.08px]',
    lg: 'lg-tui:text-tui-14xl lg-tui:leading-[100%] lg-tui:-tracking-[4.18px]',
    xl: 'xl-tui:text-tui-15xl xl-tui:leading-[100%] xl-tui:-tracking-[4.62px]'
  },
  display1Regular: {
    xs: 'xs-tui:text-tui-11xl  xs-tui:leading-[100%] xs-tui:-tracking-[2.75px]',
    sm: 'sm-tui:text-tui-11xl  sm-tui:leading-[100%] sm-tui:-tracking-[2.75px]',
    md: 'md-tui:text-tui-12xl md-tui:leading-[100%] md-tui:-tracking-[3.08px]',
    lg: 'lg-tui:text-tui-14xl lg-tui:leading-[98%] lg-tui:-tracking-[4.56px]',
    xl: 'xl-tui:text-tui-15xl xl-tui:leading-[98%] xl-tui:-tracking-[5.04px]'
  },
  link1: {
    base: 'text-tui-base leading-[135%] -tracking-[0.32px]'
  },
  link2: {
    base: 'text-tui-sm leading-[135%] -tracking-[0.28px]'
  },
  label1: {
    base: 'text-tui-xs leading-[130%] tracking-[0.24px] uppercase'
  },
  label2: {
    base: 'text-tui-sm leading-[135%] tracking-[0.14px] uppercase'
  },
  header1: {
    xs: 'xs-tui:text-tui-10xl xs-tui:leading-[105%] xs-tui:-tracking-[2.2px]',
    sm: 'sm-tui:text-tui-10xl sm-tui:leading-[105%] sm-tui:-tracking-[2.2px]',
    md: 'md-tui:text-tui-11xl md-tui:leading-[105%] md-tui:-tracking-[2.5px]',
    lg: 'lg-tui:text-tui-12xl lg-tui:leading-[105%] lg-tui:-tracking-[2.8px]',
    xl: 'xl-tui:text-tui-13xl xl-tui:leading-[105%] xl-tui:-tracking-[3.2px]'
  },
  header2: {
    xs: 'xs-tui:text-tui-5xl xs-tui:leading-[110%] xs-tui:-tracking-[1.2px]',
    sm: 'sm-tui:text-tui-5xl sm-tui:leading-[110%] sm-tui:-tracking-[1.2px]',
    md: 'md-tui:text-tui-6xl md-tui:leading-[110%] md-tui:-tracking-[1.28px]',
    lg: 'lg-tui:text-tui-7xl lg-tui:leading-[105%] lg-tui:-tracking-[1.8px]',
    xl: 'xl-tui:text-tui-8xl xl-tui:leading-[105%] xl-tui:-tracking-[2px]'
  },
  header3: {
    xs: 'xs-tui:text-tui-3xl xs-tui:leading-[115%] xs-tui:-tracking-[0.96px]',
    sm: 'sm-tui:text-tui-3xl sm-tui:leading-[115%] sm-tui:-tracking-[0.96px]',
    md: 'md-tui:text-tui-3xl md-tui:leading-[115%] md-tui:-tracking-[0.96px]',
    lg: 'lg-tui:text-tui-4xl lg-tui:leading-[110%] lg-tui:-tracking-[1.12px]',
    xl: 'xl-tui:text-tui-6xl xl-tui:leading-[110%] xl-tui:-tracking-[1.28px]'
  },
  header4: {
    xs: 'xs-tui:text-tui-xl xs-tui:leading-[115%] xs-tui:-tracking-[0.6px]',
    sm: 'sm-tui:text-tui-xl sm-tui:leading-[115%] sm-tui:-tracking-[0.6px]',
    md: 'md-tui:text-tui-xl md-tui:leading-[115%] md-tui:-tracking-[0.6px]',
    lg: 'lg-tui:text-tui-2xl lg-tui:leading-[115%] lg-tui:-tracking-[0.77px]',
    xl: 'xl-tui:text-tui-3xl xl-tui:leading-[115%] xl-tui:-tracking-[0.96px]'
  },
  subhead1: {
    xs: 'xs-tui:text-tui-lg xs-tui:leading-[115%] xs-tui:-tracking-[0.36px]',
    sm: 'sm-tui:text-tui-lg sm-tui:leading-[115%] sm-tui:-tracking-[0.36px]',
    md: 'md-tui:text-tui-lg md-tui:leading-[115%] md-tui:-tracking-[0.36px]',
    lg: 'lg-tui:text-tui-lg lg-tui:leading-[115%] lg-tui:-tracking-[0.36px]',
    xl: 'xl-tui:text-tui-xl xl-tui:leading-[115%] xl-tui:-tracking-[0.6px]'
  },
  subhead2: {
    xs: 'xs-tui:text-tui-base xs-tui:leading-[120%] xs-tui:-tracking-[0.48px]',
    sm: 'sm-tui:text-tui-base sm-tui:leading-[120%] sm-tui:-tracking-[0.48px]',
    md: 'md-tui:text-tui-base md-tui:leading-[120%] md-tui:-tracking-[0.48px]',
    lg: 'lg-tui:text-tui-base lg-tui:leading-[120%] lg-tui:-tracking-[0.48px]',
    xl: 'xl-tui:text-tui-lg xl-tui:leading-[115%] xl-tui:-tracking-[0.72px]'
  },
  caption1: {
    base: 'text-tui-xs leading-[125%] -tracking-[0.042px]'
  },
  bodyCopy1: {
    base: 'text-tui-base leading-[130%] -tracking-[0.24px]'
  },
  bodyCopy2: {
    base: 'text-tui-sm leading-[130%] -tracking-[0.21px]'
  }
};

const tedUIFonts = {
  display: `display ${fonts.display1.xs} ${fonts.display1.sm} ${fonts.display1.md} ${fonts.display1.lg} ${fonts.display1.xl}`,
  displayRegular: `displayRegular ${fonts.display1Regular.xs} ${fonts.display1Regular.sm} ${fonts.display1Regular.md} ${fonts.display1Regular.lg} ${fonts.display1Regular.xl}`,
  link1: `link1 ${fonts.link1.base}`,
  link2: `link2 ${fonts.link2.base}`,
  label1: `label1 ${fonts.label1.base}`,
  label2: `label2 ${fonts.label2.base}`,
  header1: `header1 ${fonts.header1.xs} ${fonts.header1.sm} ${fonts.header1.md} ${fonts.header1.lg} ${fonts.header1.xl}`,
  header2: `header2 ${fonts.header2.xs} ${fonts.header2.sm} ${fonts.header2.md} ${fonts.header2.lg} ${fonts.header2.xl}`,
  header3: `header3 ${fonts.header3.xs} ${fonts.header3.sm} ${fonts.header3.md} ${fonts.header3.lg} ${fonts.header3.xl}`,
  header4: `header4 ${fonts.header4.xs} ${fonts.header4.sm} ${fonts.header4.md} ${fonts.header4.lg} ${fonts.header4.xl}`,
  subheader1: `subheader1 ${fonts.subhead1.xs} ${fonts.subhead1.sm} ${fonts.subhead1.md} ${fonts.subhead1.lg} ${fonts.subhead1.xl}`,
  subheader2: `subheader2 ${fonts.subhead2.xs} ${fonts.subhead2.sm} ${fonts.subhead2.md} ${fonts.subhead2.lg} ${fonts.subhead2.xl}`,
  caption1: `caption1 ${fonts.caption1.base}`,
  body1: `body1 ${fonts.bodyCopy1.base}`,
  body2: `body2 ${fonts.bodyCopy2.base}`
};

export { tedUIFonts };
