import cx from 'classnames';
import { Icon, Text } from 'components/@tedui';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Accordion as PureAccordion
} from 'components/shared/Accordion';
import mixpanel from 'lib/analytics/mixpanel';
import { useState } from 'react';
import type AccordionProps from './Accordion.props';

const Accordion = ({
  multiple = false,
  collapsible = true,
  items,
  UNSAFE_classNames = {},
  onClick,
  headerTagName = 'div',
  testId = ''
}: AccordionProps) => {
  const [indices, setIndices] = useState<number[]>([]);

  const handleChange = (toggledIndex: number) => {
    if (onClick) {
      onClick();
    }
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter(currentIndex => currentIndex !== toggledIndex));
    } else if (multiple) {
      setIndices([...indices, toggledIndex].sort());
    } else {
      setIndices([toggledIndex]);
    }
  };

  const accordionClassName = UNSAFE_classNames?.accordion || 'w-full';
  const itemClassName = UNSAFE_classNames?.item || 'mb-5';
  const buttonClassName = cx(
    'flex w-full items-center justify-between text-left',
    {
      [UNSAFE_classNames.button ?? '']: UNSAFE_classNames?.button,
      'rounded-sm bg-gray-300 px-5 py-3': !UNSAFE_classNames?.button
    }
  );
  const titleClassName = UNSAFE_classNames?.title || null;
  const iconClassName = cx(
    'align-center flex h-4 w-4 items-center justify-center rounded-full lg:h-8 lg:w-8',
    {
      [UNSAFE_classNames.buttonIcon ?? '']: UNSAFE_classNames?.buttonIcon,
      'hover:bg-white': !UNSAFE_classNames?.buttonIcon
    }
  );
  const panelClassName = UNSAFE_classNames?.panel || 'px-5 py-3';

  return (
    <PureAccordion
      multiple={multiple}
      collapsible={collapsible}
      onChange={handleChange}
      className={accordionClassName}
      data-testid={testId}
    >
      {items.map(({ title, content }, index) => (
        <AccordionItem key={title} className={itemClassName} index={index}>
          <AccordionButton
            className={buttonClassName}
            onClick={
              testId === 'membership-faq-accordion'
                ? () => mixpanel.track('faq_item_click', { faq_title: title })
                : undefined
            }
          >
            <Text
              variant="body1"
              tag={headerTagName}
              {...(titleClassName ? { UNSAFE_className: titleClassName } : {})}
            >
              {title}
            </Text>
            <div className={iconClassName}>
              <Icon
                iconName={
                  indices.includes(index) ? 'chevron-up' : 'chevron-down'
                }
              />
            </div>
          </AccordionButton>
          <AccordionPanel className={panelClassName}>{content}</AccordionPanel>
        </AccordionItem>
      ))}
    </PureAccordion>
  );
};

export default Accordion;
