import cx from 'classnames';

import { Button } from 'components/@tedui';

import type { ScrollButtonProps } from './ScrollButton.props';

export const ScrollButton = ({
  direction,
  scroll,
  adjustPosition
}: ScrollButtonProps): React.ReactNode => (
  <div
    className={cx(
      'absolute z-40 flex h-6 w-6 items-center justify-center rounded-full bg-white shadow-2xl',
      {
        'left-4': direction === 'left' && !adjustPosition,
        'right-4': direction === 'right' && !adjustPosition,
        'left-2': direction === 'left' && adjustPosition,
        'right-2': direction === 'right' && adjustPosition
      }
    )}
  >
    <Button
      testId={`scroll-${direction}-button`}
      variant="inline"
      iconOptions={{
        iconName: `chevron-${direction}`,
        iconPosition: 'trailing'
      }}
      onClick={() => scroll(direction)}
    />
  </div>
);
