/* eslint-disable no-restricted-imports */
import type { ImageLoaderProps, ImageProps } from 'next/image';
import NextImage from 'next/image';
import crushLoader from '../../../config/next/loader';

const defaultSizes =
  '(min-width: 1920px) 33vw, (min-width: 1024px) 50vw, (min-width: 768px) 66vw, 100vw';

type CustomImageProps = ImageProps & {
  loaderWidth?: number;
};

/**
 * Enhanced loader that respects the actual requested width from srcset
 * @param loaderProps - The loader props for the image.
 * @param fallbackWidth - The fallback width to use if the actual requested width is not available.
 * @returns The enhanced loader.
 */
const enhancedLoader = (
  loaderProps: ImageLoaderProps,
  fallbackWidth: number
) => {
  // For srcset generation, Next.js will pass different widths
  // Use the actual requested width if available
  const width = loaderProps.width || fallbackWidth;
  return crushLoader({ ...loaderProps, width });
};

/**
 * Custom Image component that allows for a custom loader width.
 * @param props - The props for the Image component.
 * @param props.loaderWidth - The width to use for the crushinator loader. This is different from the native width prop.
 * @returns The Image component.
 */

const Image = (props: CustomImageProps) => {
  const { loaderWidth: customLoaderWidth, ...imageProps } = props;

  let fallbackWidth =
    typeof imageProps.width === 'number'
      ? imageProps.width
      : parseInt(imageProps.width as string, 10);
  const modifiedProps = { ...imageProps };

  // If using fill mode and width isn't defined, set a fallback for the loader.
  if (imageProps.fill && !imageProps.width) {
    fallbackWidth = customLoaderWidth || 800; // Use custom loader width if provided, else fallback to 800
    // Remove width and height props to avoid conflict with fill mode.
    delete modifiedProps.width;
    delete modifiedProps.height;
  }

  return (
    <NextImage
      {...modifiedProps}
      loader={loaderProps => enhancedLoader(loaderProps, fallbackWidth)}
      sizes={imageProps.sizes || defaultSizes}
    />
  );
};

export default Image;
