import { useEffect, useRef, useState } from 'react';
import { FormattedDate } from 'react-intl';

import { useOptimizedImage } from 'lib/hooks/useOptimizedImage';

import {
  HOMEPAGE_TALK_CARD_EVENTS,
  sendHomepageTalkCardEvents
} from 'components/pages/homepage/mixpanel-events';
import { useDarkMode } from 'components/theme/DarkModeProvider';
import useVideoPlayerStore from 'components/video-player/store';
import Badge from '../../../Badge';
import { Card, CardBody, CardLabel, CardMedia, CardTitle } from '../../../Card';
import Text from '../../../Text';
import RibbonProps, { Slide } from '../Ribbon.props';

type RibbonItemProps = Pick<
  Slide,
  'badges' | 'title' | 'label' | 'publishedAt' | 'topics'
> &
  Pick<RibbonProps, 'mixpanelEventPrefix'> & {
    image: {
      src: string;
      alt: string;
      sizes?: string;
    };
    isTalk: boolean;
    isNewCard?: boolean;
  };

const RibbonItem = ({
  ref,
  image,
  isTalk,
  title,
  badges,
  label,
  publishedAt,
  topics,
  isNewCard = false,
  mixpanelEventPrefix
}: RibbonItemProps & {
  ref: React.RefObject<HTMLDivElement | null>;
}) => {
  const { isDarkMode } = useDarkMode();
  const [hovered, setHovered] = useState(false);

  const hoverRef = useRef<HTMLDivElement>(null);

  const autoplayState = useVideoPlayerStore(state => state.autoplayed);

  const toggleHover = () => {
    setHovered(prevState => !prevState);
  };

  useEffect(() => {
    if (hoverRef?.current) {
      hoverRef.current.onmouseenter = toggleHover;
      hoverRef.current.onmouseleave = toggleHover;
    }
  }, [hoverRef]);

  const handleHomepageEvent = (context: HOMEPAGE_TALK_CARD_EVENTS) => {
    sendHomepageTalkCardEvents(context, autoplayState, mixpanelEventPrefix);
  };

  const { src, blurredSrc, setIsLoading } = useOptimizedImage({
    imageUrl: image.src,
    width: 1200,
    height: 675
  });

  return (
    <Card ref={hoverRef} isNewCard={isNewCard}>
      <div ref={ref}>
        <CardMedia
          sendHomepageMixpanelEventByContext={handleHomepageEvent}
          showPlayIcon={hovered}
          badges={badges}
          image={{
            src: src || blurredSrc,
            alt: image.alt,
            aspect: 'rectangle',
            sizes: image.sizes
          }}
          blurUntilLoaded
          setIsLoading={setIsLoading}
          isNewCard={isNewCard}
        />
      </div>

      {!isNewCard ? (
        <>
          <CardBody addHorizontalPadding={false} UNSAFE_className="pt-6">
            {isTalk && topics?.[0]?.name && (
              <CardLabel
                addBottomMargin
                variant="caption1"
                weight="font-semibold"
                useNewTextStyles
                isBold={false}
                UNSAFE_className="!leading-[140%] uppercase"
                color={{ override: '#EB0028' }}
              >
                {topics?.[0]?.name}
              </CardLabel>
            )}
            <CardTitle
              callback={() => handleHomepageEvent('talk_title')}
              text={title}
              variant="subheader1"
              useNewTextStyles={false}
              UNSAFE_className="!leading-[115%] !tracking-[-0.8px] md-tui:!tracking-[-0.96px] md-tui:!font-semibold"
              color={{
                isOnLightSurface: !isDarkMode
              }}
              truncateText={false}
            />
            {isTalk ? (
              <CardLabel
                variant="link2"
                useNewTextStyles
                UNSAFE_className="!leading-[130%]"
                weight="font-normal"
                isBold={false}
                color={{
                  override: isDarkMode ? '#8C8C8C' : undefined
                }}
              >
                {label}
              </CardLabel>
            ) : (
              <div className="mt-1">
                <Badge
                  text="Playlist"
                  backgroundColor="purple"
                  iconOptions={{
                    iconName: 'playlist-fill',
                    iconPosition: 'leading'
                  }}
                />
              </div>
            )}
          </CardBody>
          {publishedAt && (
            <div className="mt-1">
              <Text variant="caption" color={{ color: 'tertiary' }}>
                <FormattedDate
                  value={new Date(publishedAt)}
                  year="numeric"
                  month="long"
                />
              </Text>
            </div>
          )}
        </>
      ) : (
        <div className="flex h-full flex-col justify-end px-2 py-2">
          <Text UNSAFE_className="text-tui-lg text-white ">{title}</Text>
          <Text
            isBold
            color={{ override: '#828282' }}
            UNSAFE_className="text-tui-base"
          >
            {label}
          </Text>
        </div>
      )}
    </Card>
  );
};

export default RibbonItem;
