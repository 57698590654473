import cx from 'classnames';
import type { ChangeEvent, FocusEvent, KeyboardEvent, RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

import { Button, Icon, Text } from 'components/@tedui';

import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';

import { IconPositionType, InputProps, isError } from './Input.props';

/**
 * Input is a customizable input component with optional validation and icon support.
 * @param id - The id attribute for the input element.
 * @param label - The label text for the input element.
 * @param helper - The helper text to display below the input element (optional).
 * @param type - The type attribute for the input element (e.g., "text", "number") (optional).
 * @param name - The name attribute for the input element (optional).
 * @param iconOptions - The options for displaying an icon within the input component (optional).
 * @param validation - The validation status and error message for the input component (optional).
 * @param isDarkBackground - Indicates whether the input is displayed on a dark background (optional).
 * @param searchOnClick - The callback function called when the search icon is clicked (optional, applicable to "search" type).
 * @param filterOnClick - The callback function called when the filter icon is clicked (optional, applicable to "search" type).
 * @param testId - The testId attribute for the input element, used for testing purposes (optional).
 * @param value - The value of the input element (optional).
 * @param min - The minimum value for the input element (optional, applicable to "number" type).
 * @param onBlur - The callback function called when the input loses focus (optional).
 * @param onChange - The callback function called when the input value changes (optional).
 * @param required - Indicates whether the input field is required (optional).
 * @param useNewComponent - Indicates whether to use the new component styles (optional).
 * @param pattern - A pattern attribute specifying a regular expression the input's value must match (optional).
 * @returns The Input component with the specified attributes and functionality.
 */
const Input = ({
  ref,
  id,
  label,
  helper,
  iconOptions = { name: '', position: IconPositionType.Leading },
  validation,
  isDarkBackground,
  onChange,
  type,
  searchOnClick,
  filterOnClick,
  onBlur,
  testId = 'Input',
  useNewComponent = false,
  ...otherProps
}: InputProps & {
  ref?: React.RefObject<HTMLInputElement | null>;
}) => {
  const inputRef = ref as RefObject<HTMLInputElement | null>;
  const isMobileWidth = useIsBreakpointWidth({
    size: 'lg',
    breakPointType: 'tui'
  });
  const [inputValue, setInputValue] = useState('');

  const [isFocused, setIsFocused] = useState(false);
  const [isSearchButtonFocused, setIsSearchButtonFocused] = useState(false);
  const [searchIconPosition, setSearchIconPosition] = useState(
    IconPositionType.Leading
  );
  const isText = type === 'text';
  const isSearch = type === 'search';
  const shouldAnimateIcon = isSearch && isFocused;

  const searchButtonRef = useRef<HTMLButtonElement>(null);

  const { name: icon, position: iconPosition } = iconOptions;
  const iconOnTheRight =
    iconPosition === IconPositionType.Trailing ||
    searchIconPosition === IconPositionType.Trailing;
  const status = validation?.status;
  const errorMessage = isError(validation) ? validation.errorMessage : null;
  const hasError = status === 'error';
  const isValid = status === 'valid';

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);

    if (onChange) {
      onChange(e);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (isSearch && !inputValue) {
      setSearchIconPosition(IconPositionType.Trailing);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (isSearch && !inputValue) {
      setSearchIconPosition(IconPositionType.Leading);
    }

    if (onBlur) {
      onBlur(e);
    }
  };

  const handleSearchMousedown = e => {
    e.preventDefault();
    if (searchOnClick) {
      searchOnClick();

      if (inputRef && inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  const handleFilterClick = () => {
    if (filterOnClick) {
      filterOnClick();
    }
  };

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab' && inputValue && searchButtonRef.current) {
      e.preventDefault();
      searchButtonRef.current.focus();
    }

    if (e.key === 'Enter' && isSearch && inputValue) {
      handleSearchMousedown(e);
    }
  };

  const handleSearchKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      handleSearchMousedown(e);
    }
  };

  const handleSearchButtonFocus = () => {
    setIsSearchButtonFocused(true);
  };

  const handleSearchButtonBlur = () => {
    setIsSearchButtonFocused(false);
  };

  useEffect(() => {
    if (otherProps?.value !== inputValue && otherProps?.value !== undefined) {
      setInputValue(otherProps?.value as string);
    }
  }, [otherProps, inputValue]);

  const classNames = {
    label: cx(
      'absolute -top-1 text-tui-xs transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:transform peer-placeholder-shown:bg-transparent peer-placeholder-shown:text-tui-sm peer-focus:-top-0 peer-focus:px-2 peer-focus:text-tui-xs',
      {
        '-top-2 left-1 px-2 peer-focus:-top-2': inputValue && !useNewComponent,
        'left-0 top-1 !bg-transparent px-2 peer-placeholder-shown:top-1/3 peer-placeholder-shown:translate-y-0 peer-focus:left-0 peer-focus:top-1':
          useNewComponent,
        'left-2': (!icon || iconOnTheRight) && !inputValue,
        'left-8': icon && !iconOnTheRight && !inputValue,
        'bg-white peer-focus:bg-white': !isDarkBackground,
        'bg-black peer-focus:bg-black': isDarkBackground,
        'text-textTertiary-onLight': !isDarkBackground && !isSearch && !isText,
        'text-textTertiary-onDark': isDarkBackground && !isSearch,
        'text-textSecondary-onLight': !isDarkBackground && (isSearch || isText),
        'text-textSecondary-onDark': isDarkBackground && (isSearch || isText)
      }
    ),
    input: cx(
      'peer w-full rounded-sm border-thin border-solid border-black/16 py-3 pr-4 placeholder-transparent outline-none',
      {
        'pl-2': !icon || iconOnTheRight,
        'pl-8': icon && !iconOnTheRight,
        'pr-8': icon && iconOnTheRight,
        'pt-5': useNewComponent,
        '!bg-transparent':
          isDarkBackground ||
          ((isSearch || isText) && !inputValue && !isFocused),
        'bg-white':
          !isDarkBackground ||
          (isSearch && inputValue) ||
          (isSearch && isFocused),
        'text-textPrimary-onDark': isDarkBackground,
        'text-textSecondary-onLight': !isDarkBackground,
        'focus:border-blue-300': !hasError,
        'border-systemInfo-error-onLight focus:border-systemInfo-error-onLight':
          hasError,
        'border-systemInfo-error-onDark focus:border-systemInfo-error-onDark':
          hasError && isDarkBackground,
        'border-systemInfo-success focus:border-systemInfo-success': isValid,
        'h-[48px]': isSearch
      }
    ),
    inputGradientContainer: cx(
      'pointer-events-none absolute inset-0 flex flex-col justify-center'
    ),
    inputGradient: cx(
      'absolute right-px h-[90%] bg-gradient-to-r from-transparent',
      {
        'w-16': status || (icon && iconOnTheRight),
        'w-12': !status || (icon && iconOnTheRight),
        'w-40': iconOnTheRight && isSearch && isMobileWidth,
        'via-white to-white': !isDarkBackground && (isFocused || inputValue),
        'via-black to-black': isDarkBackground
      }
    ),
    icon: cx(
      'linear absolute top-1/2 mr-2 h-auto -translate-y-1/2 transform transition-transform duration-200',
      {
        'left-2': !iconOnTheRight,
        'right-2':
          iconOnTheRight || (iconOnTheRight && !isSearch && !isMobileWidth),
        'right-[3.25rem]': iconOnTheRight && isSearch && isMobileWidth,
        'text-gray-500': isDarkBackground
      }
    ),
    filterIcon: cx(
      'absolute right-0 top-1/2 w-12 -translate-y-1/2 transform rounded-r-sm',
      {
        'bg-white': isDarkBackground && (isFocused || inputValue),
        'h-full bg-black': !isDarkBackground && (isFocused || inputValue)
      }
    ),
    statusIcon: cx('absolute right-2 top-1/2 mr-2 -translate-y-1/2 transform', {
      'text-systemInfo-error-onLight': hasError,
      'text-systemInfo-error-onDark': hasError && isDarkBackground,
      'text-systemInfo-success': isValid
    })
  };

  const StatusIcon = () => {
    if (!status) return null;
    const iconName =
      status === 'valid' ? 'check-circle-filled' : 'alert-triangle';
    return <Icon iconName={iconName} className={classNames.statusIcon} />;
  };

  const InputIcon = () => {
    if (!icon) return null;
    return <Icon iconName={icon} className={classNames.icon} />;
  };

  const SearchIcon = () => {
    if (!isSearch) return null;

    return (
      <button
        type="button"
        tabIndex={isFocused || isSearchButtonFocused ? 0 : -1}
        onMouseDown={handleSearchMousedown}
        onKeyDown={handleSearchKeyDown}
        onFocus={handleSearchButtonFocus}
        onBlur={handleSearchButtonBlur}
        className={classNames.icon}
        ref={searchButtonRef}
      >
        <Icon iconName="search" />
      </button>
    );
  };

  const FilterIcon = () => {
    return (
      <div className={classNames.filterIcon}>
        <Button
          variant="inline"
          iconOptions={{ iconName: 'filter-3', iconSize: 'medium' }}
          onClick={handleFilterClick}
          isDarkBackground={isFocused || !!inputValue}
        />
      </div>
    );
  };

  return (
    <div data-testid={testId}>
      <div className="relative">
        <div className={classNames.inputGradientContainer}>
          <div className={classNames.inputGradient} aria-hidden="true" />
        </div>
        {isSearch && (shouldAnimateIcon || inputValue) ? (
          <SearchIcon />
        ) : (
          <InputIcon />
        )}
        {isSearch && isMobileWidth && <FilterIcon />}
        <input
          ref={ref}
          id={id}
          placeholder={label}
          type={type}
          onKeyDown={handleInputKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={classNames.input}
          value={inputValue}
          onChange={handleChange}
          {...otherProps}
        />
        <label className={classNames.label} htmlFor={id}>
          {label}
        </label>
        <StatusIcon />
      </div>

      {helper && (
        <Text
          tag="p"
          variant="caption"
          UNSAFE_className="mt-1 ml-2"
          color={{ isOnLightSurface: !isDarkBackground, color: 'tertiary' }}
        >
          {helper}
        </Text>
      )}

      {errorMessage && (
        <Text
          tag="p"
          variant="caption"
          UNSAFE_className="mt-1 ml-2"
          color={{ isOnLightSurface: !isDarkBackground, color: 'error' }}
        >
          <span aria-live="polite">{errorMessage}</span>
        </Text>
      )}
    </div>
  );
};

export default Input;
