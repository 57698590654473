import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useInstantSearch } from 'react-instantsearch';

import { useLocalStorage } from 'lib/hooks/useLocalStorage';

import { useTrackClickEvent } from '../../analytics';
import { useTopicsModalContext } from './context';
import { useModalStore } from './store';

type UseTopicsModalLogicProps = {
  refine: (topic: string) => void;
  refinedTopics: string[];
};

type UseTopicsModalLogicReturn = {
  handleShowResults: () => void;
  handleResetTopics: () => void;
  closeModalAndDiscardLocalState: () => void;
};

const useTopicsModalLogic = ({
  refine,
  refinedTopics
}: UseTopicsModalLogicProps): UseTopicsModalLogicReturn => {
  const router = useRouter();
  const ISTALKSPAGE = router.pathname === '/talks';

  const { indexUiState, setIndexUiState, results } = useInstantSearch();
  const {
    localCheckedTopicState,
    localSelectedTopicsCount,
    setSelectedTopicsCount,
    discardLocalModalState,
    setLocalCheckedTopicState,
    setLocalSelectedTopicsCount,
    transferLocalStateToCheckedState
  } = useModalStore();

  const { closeModal } = useTopicsModalContext();
  const trackClick = useTrackClickEvent(
    results
      ? {
          index: results.index,
          nbHits: results.nbHits
        }
      : undefined
  );
  const [, setCategoryContext] = useLocalStorage<string | null>(
    'category',
    null
  );

  const handleShowResults = useCallback(async () => {
    const newTopicsSet = new Set<string>();
    const keyTopicsArray = Object.keys(localCheckedTopicState);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(localCheckedTopicState || {}).forEach(([_, topics]) => {
      (topics as string[]).forEach(topic => newTopicsSet.add(topic));
    });
    const currentTopicsSet = new Set(refinedTopics);
    const topicsToRemove = Array.from(currentTopicsSet).filter(
      topic => !newTopicsSet.has(topic)
    );
    const topicsToAdd = Array.from(newTopicsSet).filter(
      topic => !currentTopicsSet.has(topic)
    );

    if (!ISTALKSPAGE) {
      topicsToAdd.forEach(topic => refine(topic));
    } else {
      if (indexUiState.sortBy === undefined) {
        setIndexUiState(prevUiState => ({
          ...prevUiState,
          sortBy: 'relevance'
        }));
      }

      topicsToRemove.forEach(topic => refine(topic));
      topicsToAdd.forEach(topic => refine(topic));
    }
    setCategoryContext(keyTopicsArray.join(', '));
    trackClick({
      eventName: 'show_topics_results',
      additionalEvents: {
        topics: Array.from(newTopicsSet),
        category: keyTopicsArray
      },
      waitForResultsVolume: true
    });
    transferLocalStateToCheckedState();
    setSelectedTopicsCount(localSelectedTopicsCount);
    closeModal();
  }, [
    localCheckedTopicState,
    refinedTopics,
    ISTALKSPAGE,
    setCategoryContext,
    trackClick,
    transferLocalStateToCheckedState,
    setSelectedTopicsCount,
    localSelectedTopicsCount,
    closeModal,
    router,
    indexUiState.sortBy,
    refine,
    setIndexUiState
  ]);

  const handleResetTopics = useCallback(() => {
    setLocalCheckedTopicState({});
    setLocalSelectedTopicsCount(0);

    trackClick({
      eventName: 'reset_topic_selection'
    });
  }, [setLocalCheckedTopicState, setLocalSelectedTopicsCount, trackClick]);

  const closeModalAndDiscardLocalState = useCallback(() => {
    discardLocalModalState();
    closeModal();
  }, [discardLocalModalState, closeModal]);

  return {
    handleShowResults,
    handleResetTopics,
    closeModalAndDiscardLocalState
  };
};

export { useTopicsModalLogic };
