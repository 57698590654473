import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useInstantSearch, useSortBy } from 'react-instantsearch';

import { Dropdown, DropdownItem } from 'components/@tedui';

import { FILTER_BAR_CONSTANTS } from '..';
import { useTrackClickEvent } from '../../../analytics';
import { updateUrlWithParams } from '../../../utils';
import type { SortByProps } from './SortBy.props';

const defaultSortByOption = FILTER_BAR_CONSTANTS.sortByOptions.find(
  option => option.value === 'relevance'
);

export function SortBy({
  showDefaultSort = false
}: SortByProps): React.ReactNode {
  const router = useRouter();

  const { indexUiState, results } = useInstantSearch();
  const { refine, options } = useSortBy({
    items: FILTER_BAR_CONSTANTS.sortByOptions
  });

  const trackClick = useTrackClickEvent(
    results
      ? {
          index: results.index,
          nbHits: results.nbHits
        }
      : undefined
  );

  const initialSelectedOption =
    FILTER_BAR_CONSTANTS.sortByOptions.find(
      option => option.value === indexUiState.sortBy
    ) || defaultSortByOption;

  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);

  useEffect(() => {
    const currentSortOption =
      FILTER_BAR_CONSTANTS.sortByOptions.find(
        option => option.value === indexUiState.sortBy
      ) || defaultSortByOption;
    setSelectedOption(currentSortOption);
  }, [showDefaultSort, indexUiState.sortBy]);

  const handleOnSelect = useCallback(
    (label: string, value: string) => {
      setSelectedOption({ label, value });

      trackClick({
        eventName: 'sort_selection',
        additionalEvents: { sort: value },
        waitForResultsVolume: true
      });

      updateUrlWithParams(router, { sort: value });

      refine(value);
    },
    [refine, router, trackClick]
  );

  return (
    <Dropdown
      label="Sort by"
      {...(showDefaultSort && {
        selectedValue: selectedOption
      })}
    >
      {options.map(({ label, value }) => {
        return (
          <DropdownItem
            key={value}
            label={label}
            value={value}
            onSelect={() => handleOnSelect(label, value)}
            isSelected={value === selectedOption?.value}
          />
        );
      })}
    </Dropdown>
  );
}
