import cx from 'classnames';
import { useRef } from 'react';
import { useInstantSearch } from 'react-instantsearch';

import {
  FloatingActionButton,
  Icon,
  Pagination,
  Text,
  TitleBar
} from 'components/@tedui';
import { Grid } from 'components/@tedui/Layout';
import { useTrackClickEvent } from 'components/pages/talks/TalkIndex/analytics';
import { Link } from 'components/router';

import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';

import { TalkGridProps } from './TalkGrid.props';
import { TalkGridItem } from './TalkGridItem';

const talkGridEmptyState = {
  title: `Hmm it seems like we can't find exactly what you're looking for.`,
  description: ` Trying adjusting your filters, enter a specific term in the search field, or start a new search with the categories below.`
};

/**
 * The TalkGrid component is a specialized component that displays talks in a grid layout.
 * It includes a title, grid of talks, and pagination options.
 * @param talks - An array of talk data to be displayed in the grid.
 * @param title - An optional title to be displayed above the grid.
 * @param isOnResultsPage - A boolean indicating if the component is on a search results page.
 * @param pagination - Pagination options including visible items, total items, and button options.
 * @returns The TalkGrid component that displays talks with the specified layout and pagination options.
 */
export const TalkGrid = ({
  talks,
  title = 'Newest Talks',
  isOnResultsPage = false,
  pagination
}: TalkGridProps): React.ReactNode => {
  const viewRef = useRef<HTMLDivElement>(null);
  const { status, results } = useInstantSearch();
  const isMobileWidth = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'tui'
  });

  const classNames = {
    buttonWrapper: 'mt-5 flex flex-col items-center md-tui:mt-6 2xl-tui:mt-8',
    button: 'flex items-center gap-1 border-b-thin border-black'
  };

  const trackClick = useTrackClickEvent(
    results
      ? {
          index: results?.index,
          nbHits: results?.nbHits
        }
      : undefined
  );

  const handleFABClick = () => {
    trackClick({
      eventName: 'back_to_top'
    });
  };

  if (!talks || (isOnResultsPage && talks.length === 0 && status === 'idle'))
    return (
      <section
        className={cx(
          'border-b-thin border-black border-opacity-16',
          'mb-6 mt-4 border-t-thin pb-6 pt-7 xs-tui:mx-3 sm-tui:mx-5 md-tui:mx-10 lg-tui:mb-8 lg-tui:mt-5 lg-tui:pb-20 xl-tui:mx-16 2xl-tui:mt-8'
        )}
      >
        <div className="mx-auto flex max-w-xl flex-col justify-center py-3 text-center">
          <Text testId="Empty State Title" tag="h3" variant="header4" isBold>
            {talkGridEmptyState.title}
          </Text>
          <span className="mx-auto max-w-md">
            <Text testId="Empty State Description" variant="body2">
              {talkGridEmptyState.description}
            </Text>
          </span>
        </div>
      </section>
    );

  return (
    <section
      test-id={`Talk Grid ${isOnResultsPage ? 'Results' : 'Default'}`}
      className={cx(
        'mb-6 xs-tui:mx-3 md-tui:mx-5 lg-tui:mx-10 lg-tui:mb-8 xl-tui:mx-16',
        'border-b-thin border-black border-opacity-16',
        {
          'mt-6 lg-tui:mt-8 2xl-tui:mt-10': !isOnResultsPage,
          [`mt-4 pb-6 lg-tui:mt-5 lg-tui:pb-12 2xl-tui:mt-8`]: isOnResultsPage
        }
      )}
    >
      <div className="relative" ref={viewRef}>
        <div
          className={cx({
            [`mb-3 border-b-thin border-black border-opacity-16 pb-1 lg-tui:mb-4 lg-tui:pb-0`]:
              isOnResultsPage
          })}
        >
          {title &&
            ((isOnResultsPage && isMobileWidth) || !isOnResultsPage) && (
              <TitleBar
                title={{
                  label: title,
                  variant: isOnResultsPage ? 'body2' : 'header3'
                }}
                addMargin={!isOnResultsPage}
              />
            )}
        </div>

        <div ref={viewRef}>
          <Grid
            columns={{
              xs: isOnResultsPage ? 1 : 2,
              md: 3,
              lg: 4
            }}
            spacing={{
              xs: {
                row: 3,
                column: isOnResultsPage ? 5 : 6
              }
            }}
          >
            {talks.map((talk, index) => (
              <TalkGridItem
                key={
                  isOnResultsPage
                    ? // eslint-disable-next-line no-underscore-dangle
                      `${talk.id}-${talk.updated_at}-${talk.__position}`
                    : `${talk.slug}-${talk.updated_at}`
                }
                talk={talk}
                // eslint-disable-next-line no-underscore-dangle
                position={talk.__position || index + 1}
                isOnResultsPage={isOnResultsPage}
              />
            ))}
          </Grid>
          {!isOnResultsPage && (
            <div className={classNames.buttonWrapper}>
              <Link
                href="talks?sort=newest&utm_medium=website&utm_source=talk-index_see-all-newest"
                className="outline-inside flex items-center py-4"
              >
                <button
                  type="button"
                  className={classNames.button}
                  tabIndex={-1}
                >
                  <Text tag="p" variant="body2">
                    See all newest videos
                  </Text>
                  <Icon iconName="arrow-right" />
                </button>
              </Link>
            </div>
          )}
          {!pagination?.isLoadingMoreItems && isOnResultsPage && (
            <FloatingActionButton onClick={handleFABClick} />
          )}
        </div>

        <div
          className={cx({
            'mt-8 md-tui:mt-12 lg-tui:mt-16': !isOnResultsPage,
            'mt-5 md-tui:mt-6 lg-tui:mt-12': isOnResultsPage
          })}
        >
          <Pagination
            visibleItems={pagination?.visibleItems}
            totalItems={pagination?.totalItems}
            showLoadMoreButton={pagination?.showLoadMoreButton}
            isLoadingMoreItems={pagination?.isLoadingMoreItems}
            showCount={isOnResultsPage}
            buttonOptions={{
              onClick: pagination?.buttonOptions?.onClick,
              disabled: pagination?.buttonOptions?.disabled,
              text: pagination?.buttonOptions?.text
            }}
          />
        </div>
      </div>
    </section>
  );
};
