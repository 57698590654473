import cx from 'classnames';

import CardBodyProps from './CardBody.props';

const CardBody = ({
  addHorizontalPadding = true,
  children,
  colors = {},
  testId,
  UNSAFE_className
}: CardBodyProps) => {
  const cardBodyClassName = cx(
    'flex h-full flex-col pt-2',
    {
      [`bg-${colors.background?.replace(/\s/g, '')}`]: colors?.background,
      [`text-${colors.text?.replace(/\s/g, '')}`]: colors?.text,
      'px-2 lg-tui:px-4': addHorizontalPadding
    },
    UNSAFE_className
  );

  return (
    <div className={cardBodyClassName} data-testid={testId}>
      {children}
    </div>
  );
};

export default CardBody;
