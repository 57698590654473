import { useEffect, useMemo } from 'react';
import { useInstantSearch, useRefinementList } from 'react-instantsearch';

import { Modal, Text } from 'components/@tedui';
import { ModalType } from 'components/@tedui/Modal/Modal.props';

import { useModalStore } from '.';
import { Footer } from './Footer';
import { TopicsModalTabs } from './Tabs/Tabs';
import type { Topic } from './TopicsModal.props';
import { useTopicsModalLogic } from './hooks';
import { findKeyTopics, transformItems } from './utils';

export function TopicsModal(): React.ReactNode {
  const {
    isModalOpen,
    localSelectedTopicsCount,
    checkedTopicState,
    setCheckedTopicState,
    selectedTopicsCount,
    setSelectedTopicsCount,
    setLocalCheckedTopicState,
    setLocalSelectedTopicsCount
  } = useModalStore();
  const { indexUiState } = useInstantSearch();

  const refinedTopics = useMemo(() => {
    const tags = indexUiState.refinementList?.tags;
    return Array.isArray(tags) ? tags : [];
  }, [indexUiState.refinementList?.tags]);

  const { items: transformedItems, refine } = useRefinementList({
    attribute: 'tags',
    limit: 500,
    transformItems
  });

  const {
    handleShowResults,
    handleResetTopics,
    closeModalAndDiscardLocalState
  } = useTopicsModalLogic({
    refine,
    refinedTopics
  });

  // Update checkedTopicState when refinedTopics changes
  // This is to ensure that the checkedTopicState is always up-to-date
  useEffect(() => {
    const newCheckedTopicState = { ...checkedTopicState };
    refinedTopics.forEach(topicValue => {
      const keyTopics = findKeyTopics(topicValue);
      keyTopics.forEach(keyTopic => {
        const existingTopics = newCheckedTopicState[keyTopic] || [];
        const updatedTopics = Array.from(
          new Set([...existingTopics, topicValue])
        );
        newCheckedTopicState[keyTopic] = updatedTopics;
      });
    });
    setCheckedTopicState(newCheckedTopicState);
  }, [refinedTopics]);

  // Update selectedTopicsCount when checkedTopicState changes
  useEffect(() => {
    const uniqueTopicsSet = new Set<string>();
    if (Object.keys(checkedTopicState).length === 0) {
      setSelectedTopicsCount(0);
      return;
    }
    Object.entries(checkedTopicState).forEach(([, topics]) => {
      if (!topics) {
        return;
      }
      (topics as string[]).forEach(topic => {
        uniqueTopicsSet.add(topic);
      });
    });
    setSelectedTopicsCount(uniqueTopicsSet.size);
  }, [checkedTopicState, setSelectedTopicsCount, refinedTopics]);

  // Update localCheckedTopicState and localSelectedTopicsCount when isModalOpen changes
  // This is to ensure that the correct count is shown when the modal is opened
  useEffect(() => {
    if (isModalOpen) {
      const sanitizedCheckedTopicState = Object.fromEntries(
        Object.entries(checkedTopicState).filter(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ([_, topics]) => topics && (topics as string[]).length > 0
        )
      );
      setLocalCheckedTopicState(sanitizedCheckedTopicState);
      setLocalSelectedTopicsCount(selectedTopicsCount);
    }
  }, [isModalOpen, checkedTopicState, selectedTopicsCount]);

  return (
    <Modal
      isOpen={isModalOpen}
      onDismiss={closeModalAndDiscardLocalState}
      type={ModalType.Large}
    >
      <div className="px-3 py-6 lg-tui:px-12 lg-tui:py-8">
        <Text variant="header3" tag="h3" isBold>
          Topics
        </Text>
      </div>

      <div className="relative">
        <TopicsModalTabs items={transformedItems as unknown as Topic[]} />

        {localSelectedTopicsCount > 0 && (
          <Footer
            selectedTopicsCount={localSelectedTopicsCount}
            reset={handleResetTopics}
            showResults={handleShowResults}
          />
        )}
      </div>
    </Modal>
  );
}
