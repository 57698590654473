import { useEffect, useMemo, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import {
  adBreakpoints,
  breakpoints,
  prismicBreakpoints,
  tuiBreakpoints
} from '../../../../design-tokens/constants';

import type {
  Breakpoint,
  UseIsBreakpointWidthProps
} from './useIsBreakpointWidth.props';

type UseIsMobileOptions = UseIsBreakpointWidthProps & {
  checkDevice?: boolean;
  mobileMaxSize?: Breakpoint;
  tabletMaxSize?: Breakpoint;
};

type UseIsMobileReturn = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isMobileOrTablet: boolean;
  isMobileDevice: boolean;
  currentScreenWidth: number;
};

type BreakpointObject = { [key in Breakpoint]?: number };

// Default return values to avoid hydration mismatches
const defaultValues: UseIsMobileReturn = {
  isMobile: false,
  isTablet: false,
  isDesktop: true,
  isMobileOrTablet: false,
  isMobileDevice: false,
  currentScreenWidth: 1200
};

export const useIsMobile = (
  options?: UseIsMobileOptions
): UseIsMobileReturn => {
  const {
    breakPointType = 'default',
    checkDevice = true,
    mobileMaxSize = 'md',
    tabletMaxSize = 'lg'
  } = options || {};

  // Track if we're in the browser
  const [isClient, setIsClient] = useState(false);

  // Start with undefined for screen width to avoid hydration mismatch
  const [currentScreenWidth, setCurrentScreenWidth] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    setIsClient(true);
    setCurrentScreenWidth(window.innerWidth);

    const handleResize = () => {
      setCurrentScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobileDevice = isClient && checkDevice ? isAndroid || isIOS : false;

  const result = useMemo(() => {
    // If we're not in the browser yet or don't have a screen width, return default values
    if (!isClient || typeof currentScreenWidth === 'undefined') {
      return defaultValues;
    }

    const getBreakpoints = (): BreakpointObject => {
      switch (breakPointType) {
        case 'tui':
          return tuiBreakpoints;
        case 'ads':
          return adBreakpoints.standard;
        case 'playlistAds':
          return adBreakpoints.playlists;
        case 'topicResultsAds':
          return adBreakpoints.topicResults;
        case 'prismic':
          return prismicBreakpoints;
        default:
          return breakpoints;
      }
    };

    const currentBreakpoints = getBreakpoints();

    const getBreakpointValue = (breakpoint: Breakpoint): number => {
      const value = currentBreakpoints[breakpoint];

      if (value === undefined) {
        console.warn(
          `Breakpoint ${breakpoint} not found in current breakpoint set.`
        );
        return Infinity; // Treat unknown breakpoints as largest possible
      }

      return value - 1;
    };

    const mobileMax = getBreakpointValue(mobileMaxSize);
    const tabletMax = getBreakpointValue(tabletMaxSize);

    const isMobile = currentScreenWidth <= mobileMax;
    const isTablet =
      currentScreenWidth > mobileMax && currentScreenWidth <= tabletMax;
    const isDesktop = currentScreenWidth > tabletMax;

    return {
      isMobile: checkDevice ? isMobileDevice || isMobile : isMobile,
      isTablet,
      isDesktop,
      isMobileOrTablet: isMobile || isTablet,
      isMobileDevice,
      currentScreenWidth
    };
  }, [
    breakPointType,
    checkDevice,
    isMobileDevice,
    mobileMaxSize,
    tabletMaxSize,
    currentScreenWidth,
    isClient
  ]);

  return result;
};
