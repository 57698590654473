import cx from 'classnames';
import mixpanel from 'lib/analytics/mixpanel';

import { Button } from 'components/@tedui';

import { useDarkMode } from 'components/theme/DarkModeProvider';
import { trackVWOCustomConversionById } from 'lib/analytics/vwo';
import type { NavigationButtonProps } from './NavigationButton.props';

export const NavigationButton = ({
  isNewCard,
  isTalkCard,
  direction,
  navigationRef,
  iconSize,
  UNSAFE_className
}: NavigationButtonProps) => {
  const { isDarkMode } = useDarkMode();
  const baseClass = cx(
    'absolute flex h-6 w-6 items-center justify-center rounded-full border-thin',
    {
      'border-white bg-black': isDarkMode,
      'border-black bg-white': !isDarkMode
    }
  );
  const positionClass =
    direction === 'left'
      ? `-left-2 sm-tui:-left-4 lg-tui:-left-8 xl-tui:-left-9`
      : `-right-2 sm-tui:-right-4 lg-tui:-right-8 xl-tui:-right-9`;

  return (
    <div
      ref={navigationRef}
      test-id={`navigation-button-${direction}`}
      className={cx(baseClass, positionClass, UNSAFE_className)}
    >
      <Button
        onClick={() => {
          if (isNewCard || isTalkCard) {
            const eventPrefix = isTalkCard ? 'talk_card' : 'new_card';

            mixpanel.track(`${eventPrefix}_ribbon_horizontal_scroll`, {
              direction
            });
            trackVWOCustomConversionById(217);
          }
        }}
        variant="inline"
        isDarkBackground={isDarkMode}
        iconOptions={{
          iconName: `chevron-${direction}`,
          iconPosition: 'trailing',
          iconSize
        }}
      />
    </div>
  );
};
