import classNames from 'classnames';
import { memo } from 'react';

const SkeletonItem = memo(
  ({
    className,
    itemClassName,
    darkMode
  }: {
    className: string;
    itemClassName?: string;
    darkMode?: boolean;
  }) => (
    <div className={classNames('animate-pulse', className)}>
      <div
        className={classNames(
          'size-full rounded-sm',
          darkMode ? 'bg-white/20' : 'bg-gray-300',
          itemClassName
        )}
      />
    </div>
  )
);

SkeletonItem.displayName = 'SkeletonItem';
export default SkeletonItem;
