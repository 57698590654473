import cx from 'classnames';
import React, { createContext, useCallback, useContext } from 'react';

type AccordionContextType = {
  openIndices: number[];
  toggleItem: (index: number) => void;
  multiple: boolean;
  collapsible: boolean;
};

const AccordionContext = createContext<AccordionContextType>({
  openIndices: [],
  toggleItem: () => {},
  multiple: false,
  collapsible: true
});

type AccordionProps = {
  children: React.ReactNode;
  multiple?: boolean;
  collapsible?: boolean;
  onChange?: (index: number) => void;
  className?: string;
  'data-testid'?: string;
};

export const Accordion = ({
  children,
  multiple = false,
  collapsible = true,
  onChange,
  className,
  'data-testid': testId
}: AccordionProps) => {
  const [openIndices, setOpenIndices] = React.useState<number[]>([]);

  const toggleItem = useCallback(
    (toggledIndex: number) => {
      onChange?.(toggledIndex);

      setOpenIndices(current => {
        if (current.includes(toggledIndex)) {
          // If collapsible is false and this is the only open item, don't close it
          if (!collapsible && current.length === 1) {
            return current;
          }
          return current.filter(i => i !== toggledIndex);
        }

        if (multiple) {
          return [...current, toggledIndex].sort();
        }

        return [toggledIndex];
      });
    },
    [multiple, collapsible, onChange]
  );

  return (
    <AccordionContext.Provider
      value={{ openIndices, toggleItem, multiple, collapsible }}
    >
      <div className={className} data-testid={testId}>
        {children}
      </div>
    </AccordionContext.Provider>
  );
};

type AccordionItemProps = {
  children: React.ReactNode;
  className?: string;
  index: number;
};

export const AccordionItem = ({
  children,
  className,
  index
}: AccordionItemProps) => {
  const { openIndices } = useContext(AccordionContext);
  const isExpanded = index !== undefined && openIndices.includes(index);

  return (
    <div className={className} data-state={isExpanded ? 'open' : 'collapsed'}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { index } as Partial<unknown>);
        }
        return child;
      })}
    </div>
  );
};

type AccordionButtonProps = {
  children: React.ReactNode;
  className?: string;
  index?: number;
  onClick?: () => void;
};

export const AccordionButton = ({
  children,
  className,
  index,
  onClick
}: AccordionButtonProps) => {
  const { openIndices, toggleItem } = useContext(AccordionContext);
  const isExpanded = index !== undefined && openIndices.includes(index);

  return (
    <button
      className={className}
      onClick={() => {
        if (index !== undefined) {
          toggleItem(index);
          onClick?.();
        }
      }}
      aria-expanded={isExpanded}
      type="button"
    >
      {children}
    </button>
  );
};

type AccordionPanelProps = {
  children: React.ReactNode;
  className?: string;
  index?: number;
};

export const AccordionPanel = ({
  children,
  className,
  index
}: AccordionPanelProps) => {
  const { openIndices } = useContext(AccordionContext);
  const isExpanded = index !== undefined && openIndices.includes(index);

  if (!isExpanded) {
    return null;
  }

  return (
    <div className={cx(className, 'accordion-panel')} role="region">
      {children}
    </div>
  );
};
