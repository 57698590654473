import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { capitalizeFirstLetter } from 'f';
import {
  consumeLocalStorage,
  generateMixpanelEvent
} from 'lib/hooks/useTrackPageViews';

import { FILTER_BAR_CONSTANTS } from '../../FilterBar/Dropdowns';

type Payload = Record<string, unknown>;

export type SearchResults = {
  index: string;
  nbHits: number;
  [key: string]: unknown;
};

export function useGenerateMixpanelPayload(results?: SearchResults): [Payload] {
  const router = useRouter();
  const [payload, setPayload] = useState<Payload>({});
  const { query } = router;

  useEffect(() => {
    if (!results) {
      const basePayload = generateMixpanelEvent(router);
      setPayload(basePayload);
      return;
    }

    const resultsView = FILTER_BAR_CONSTANTS.sortByOptions.some(
      option => option.value === results?.index
    );

    const topics: string[] =
      Object.keys(query)
        .filter(key => key.startsWith('topics['))
        .sort()
        .map(key => capitalizeFirstLetter(query[key] as string)) || [];

    const resultsCount = resultsView ? results?.nbHits : null;

    const basePayload = generateMixpanelEvent(router);

    const categoryFromLocalStorage = consumeLocalStorage('category');
    const topicLaunchpadFromLocalStorage =
      consumeLocalStorage('topic_launchpad');

    const generatedPayload = {
      ...basePayload,
      category: categoryFromLocalStorage
        ? JSON.parse(categoryFromLocalStorage)
        : null,
      search_term: query?.q || null,
      sort: query?.sort || null,
      topics: topics.length > 0 ? topics : null,
      topic_launchpad: topicLaunchpadFromLocalStorage
        ? JSON.parse(topicLaunchpadFromLocalStorage)
        : null,
      search_subtitle_language: query?.language || null,
      duration_filter: query?.duration ? `${query?.duration}min` : null,
      search_result_volume: resultsCount
    };

    setPayload(generatedPayload);
  }, [router, query]);

  return [payload];
}
