import create from 'zustand';

type DropdownStore = {
  openLabel: string | null;
  toggleDropdown: (label: string | null) => void;
};

export const [useDropdownStore] = create<DropdownStore>(set => ({
  openLabel: null,
  toggleDropdown: (label: string | null) =>
    set(() => ({
      openLabel: label || null
    }))
}));
