import { useRef, useState } from 'react';

import mixpanel from 'lib/analytics/mixpanel';

import { debounce } from 'lodash-es';
import { SearchResults, useGenerateMixpanelPayload } from '../utils';

type Props = {
  eventName?: string;
  additionalEvents?: Record<string, unknown>;
  waitForResultsVolume?: boolean;
};

type TrackClickEvent = (props: Props) => void;

export function useTrackClickEvent(results?: SearchResults): TrackClickEvent {
  const [mixpanelEvent] = useGenerateMixpanelPayload(results);
  const [lastKnownVolume, setLastKnownVolume] = useState<number | null>(null);
  const [eventDetails, setEventDetails] = useState<Props | null>(null);
  const eventSentRef = useRef(false);

  const generateClickPayload = ({ additionalEvents }: Props) => {
    return {
      ...mixpanelEvent,
      ...additionalEvents
    };
  };

  debounce(() => {
    if (eventDetails && eventDetails.waitForResultsVolume) {
      const currentVolume = mixpanelEvent.search_result_volume;
      if (currentVolume !== lastKnownVolume && !eventSentRef.current) {
        const payload = generateClickPayload(eventDetails);
        mixpanel.track(
          eventDetails.eventName || 'search_result_volume',
          payload
        );
        eventSentRef.current = true;
      }
    }
  }, 3000);

  return ({
    eventName,
    additionalEvents,
    waitForResultsVolume
  }: Props): void => {
    eventSentRef.current = false;

    if (waitForResultsVolume) {
      setLastKnownVolume(mixpanelEvent.search_result_volume as number);
      setEventDetails({ eventName, additionalEvents, waitForResultsVolume });
    }

    if (!waitForResultsVolume) {
      const payload = generateClickPayload({ additionalEvents });
      mixpanel.track(eventName || 'search_result_volume', payload);
    }
  };
}
