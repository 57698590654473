import cx from 'classnames';
import { capitalize } from 'lodash-es';

import { tedUIFonts } from './fonts-constants';

import type { TextProps } from './Text.props';

const Text = ({
  children,
  tag = 'span',
  variant = 'body1',
  UNSAFE_className = '',
  isBold = false,
  testId = '',
  direction = 'ltr',
  color = { isOnLightSurface: true, color: 'primary', override: '' },
  useNewTextStyles = false,
  weight = 'font-normal'
}: TextProps) => {
  const addBoldClass = isBold || tag === 'strong';

  const fontWeight = addBoldClass ? 'font-bold' : weight;

  const textConfig = {
    display: cx(fontWeight, {
      'text-tui-8xl leading-tui-xs tracking-tui-tightest md:text-tui-7xl lg:text-tui-8xl':
        !useNewTextStyles,
      [tedUIFonts.display]: useNewTextStyles
    }),
    displayRegular: cx(fontWeight, {
      'text-tui-8xl leading-tui-xs tracking-tui-tightest md:text-tui-7xl lg:text-tui-8xl':
        !useNewTextStyles,
      [tedUIFonts.displayRegular]: useNewTextStyles
    }),

    links: {
      link1: cx(tedUIFonts.link1, fontWeight),
      link2: cx(tedUIFonts.link2, fontWeight)
    },
    headers: {
      header1: cx(fontWeight, {
        'text-tui-3xl leading-tui-sm tracking-tui-tighter md:text-tui-7xl md:leading-tui-xs md:tracking-tui-tightest':
          !useNewTextStyles,
        [tedUIFonts.header1]: useNewTextStyles
      }),
      header2: cx(fontWeight, {
        'text-tui-2xl leading-tui-sm tracking-tui-tight md:text-tui-3xl md:tracking-tui-tightest lg:text-tui-4xl':
          !useNewTextStyles,
        [tedUIFonts.header2]: useNewTextStyles
      }),
      header3: cx(fontWeight, {
        'text-tui-lg leading-tui-sm tracking-tui-normal md:leading-tui-md md:tracking-tui-tight md:tracking-tui-tightest lg:text-tui-3xl lg:leading-tui-sm':
          !useNewTextStyles,
        [tedUIFonts.header3]: useNewTextStyles
      }),
      header4: cx(fontWeight, {
        'text-tui-sm leading-tui-md tracking-tui-normal md:leading-tui-sm lg:text-tui-lg lg:leading-tui-md lg:tracking-tui-tight':
          !useNewTextStyles,
        [tedUIFonts.header4]: useNewTextStyles
      })
    },
    subheaders: {
      subheader1: cx(fontWeight, {
        'text-tui-xl leading-tui-xl tracking-tui-tight lg:text-tui-2xl':
          !useNewTextStyles,
        [tedUIFonts.subheader1]: useNewTextStyles
      }),
      subheader2: cx(fontWeight, {
        'text-tui-lg leading-tui-xl tracking-tui-tight lg:text-tui-xl':
          !useNewTextStyles,
        [tedUIFonts.subheader2]: useNewTextStyles
      })
    },
    bodies: {
      body1: cx(fontWeight, {
        'text-tui-base leading-tui-lg tracking-tui-tight': !useNewTextStyles,
        [tedUIFonts.body1]: useNewTextStyles
      }),
      body2: cx(fontWeight, {
        'text-tui-sm leading-tui-md tracking-tui-tight lg:leading-tui-lg':
          !useNewTextStyles,
        [tedUIFonts.body2]: useNewTextStyles
      })
    },
    caption: cx('text-tui-sm leading-tui-lg tracking-tui-tight', fontWeight),
    caption1: cx(tedUIFonts.caption1, fontWeight),
    label: cx(
      'text-tui-xs uppercase leading-tui-lg tracking-tui-wider',
      fontWeight
    ),
    label1: cx(tedUIFonts.label1, fontWeight),
    label2: cx(tedUIFonts.label2, fontWeight)
  };

  const Tag = tag;

  const {
    display,
    displayRegular,
    headers,
    subheaders,
    bodies,
    caption,
    label,
    caption1,
    label1,
    label2,
    links
  } = textConfig;
  let textClassName: string;

  switch (variant) {
    case 'display':
      textClassName = display;
      break;
    case 'displayRegular':
      textClassName = displayRegular;
      break;
    case 'header1':
    case 'header2':
    case 'header3':
    case 'header4':
      textClassName = headers[variant];
      break;
    case 'subheader1':
    case 'subheader2':
      textClassName = subheaders[variant];
      break;
    case 'body1':
    case 'body2':
      textClassName = bodies[variant];
      break;
    case 'link1':
    case 'link2':
      textClassName = links[variant];
      break;
    case 'caption':
      textClassName = caption;
      break;
    case 'caption1':
      textClassName = caption1;
      break;
    case 'label':
      textClassName = label;
      break;
    case 'label1':
      textClassName = label1;
      break;
    case 'label2':
      textClassName = label2;
      break;
    default:
      textClassName = '';
  }

  const renderTextColorClassName = () => {
    if (variant === 'caption1') return 'text-[#767676]';

    const systemInfoColors = ['warning', 'success', 'link'];
    const systemInfoColorWithSurface = 'error';
    const { color: colorProp = 'primary', isOnLightSurface = true } = color;

    const surface = isOnLightSurface ? 'onLight' : 'onDark';

    if (colorProp === systemInfoColorWithSurface) {
      return `text-systemInfo-${colorProp}-${surface}`;
    }

    if (systemInfoColors.includes(colorProp)) {
      return `text-systemInfo-${colorProp}`;
    }

    return `text-text${capitalize(colorProp)}-${surface}`;
  };

  return (
    <Tag
      className={cx(
        UNSAFE_className,
        renderTextColorClassName(),
        textClassName
      )}
      data-testid={testId}
      style={{
        ...(color.override && { color: color.override })
      }}
      dir={direction}
    >
      {children}
    </Tag>
  );
};

export default Text;
