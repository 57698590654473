import cx from 'classnames';

import { createMessage } from 'components/error';

import type RatioProps from './Ratio.props';

function calculateAspectRatio(
  aspectRatio: string | number | Array<number>
): number {
  if (typeof aspectRatio === 'number') {
    return 1 / aspectRatio;
  }

  if (
    Array.isArray(aspectRatio) &&
    aspectRatio[0] !== undefined &&
    aspectRatio[1] !== undefined
  ) {
    return aspectRatio[1] / aspectRatio[0];
  }

  if (typeof aspectRatio === 'string') {
    const parsedValue = Number.parseFloat(aspectRatio);
    if (Number.isNaN(parsedValue)) {
      throw new Error(`Cannot parse props.aspectRatio: ${aspectRatio}`);
    }
    return 1 / Number.parseFloat(aspectRatio);
  }

  throw new Error(
    createMessage('calculateAspectRatio')('error')(
      `Cannot parse props.aspectRatio: ${aspectRatio}`
    )
  );
}

const Ratio = ({
  ratio = [16, 9],
  children,
  className,
  wrapperClassName,
  ariaLabel,
  isAriaHidden = false,
  customStyle
}: RatioProps): React.ReactNode => {
  const multiplier = (r: Array<number>): number => calculateAspectRatio(r);

  return (
    <div
      className="relative block w-full"
      aria-hidden={isAriaHidden}
      {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
      style={customStyle}
    >
      <div
        className={cx('relative block size-full', wrapperClassName)}
        style={{ paddingTop: `${100 * multiplier(ratio)}%` }}
      >
        <div
          className={cx(
            'absolute bottom-0 left-0 right-0 top-0 block',
            className
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Ratio.calculate = calculateAspectRatio;

Ratio.displayName = 'Zenith(Ratio)';

export { calculateAspectRatio, Ratio as default };
