import cx from 'classnames';

import type CardActionBarProps from './CardActionBar.props';

import Text from '../../Text';
import Icon from '../../Icon';

const CardActionBar = ({
  captionText,

  callToAction = {
    actionText: '',
    isBold: true,
    iconName: '',
    link: { href: '', isUnderline: true }
  },

  testId
}: CardActionBarProps) => {
  const renderCallToActionElement = () => {
    if (!callToAction.actionText) return false;

    const callToActionTextElement = (
      <Text
        variant="body2"
        tag="p"
        isBold={callToAction.isBold !== false}
        color={{ isOnLightSurface: false }}
      >
        {callToAction.actionText}
      </Text>
    );

    const callToActionLinkElement = callToAction?.link?.href ? (
      <a
        href={callToAction.link.href}
        className={cx({
          underline: callToAction.link.isUnderline !== false
        })}
      >
        {callToActionTextElement}
      </a>
    ) : null;

    return (
      <div className="flex items-center">
        {callToAction.iconName && (
          <Icon className="mr-1" iconName={callToAction.iconName} />
        )}
        {callToActionLinkElement || callToActionTextElement}
      </div>
    );
  };

  return (
    <div
      className="flex items-center justify-between bg-black px-2 py-1 text-white"
      data-testid={testId}
    >
      <Text variant="caption" tag="p" color={{ isOnLightSurface: false }}>
        {captionText}
      </Text>

      {renderCallToActionElement()}
    </div>
  );
};

export default CardActionBar;
