import cx from 'classnames';

import Text from '../../Text';
import CardLabelProps from './CardLabel.props';

const CardLabel = ({
  addBottomMargin = true,
  children,
  color,
  isBold = true,
  showTopDivider = false,
  testId,
  useNewTextStyles,
  weight = 'font-semibold',
  variant = 'label1',
  UNSAFE_className
}: CardLabelProps) => (
  <div
    className={cx('flex flex-col', {
      'mb-2': addBottomMargin
    })}
  >
    {showTopDivider && <div className="mb-1 h-px w-full bg-gray-300" />}
    <Text
      isBold={isBold}
      variant={variant}
      tag="p"
      weight={weight}
      testId={testId}
      color={color}
      UNSAFE_className={UNSAFE_className}
      useNewTextStyles={useNewTextStyles}
    >
      {children}
    </Text>
  </div>
);

export default CardLabel;
