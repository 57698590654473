import cx from 'classnames';
import { useMemo } from 'react';

import Icon from '../Icon';
import Text from '../Text';

import { ColorType } from '../Text/Text.props';
import type ButtonProps from './Button.props';

const Button = ({
  onClick,
  variant = 'primary',
  text,
  type = 'button',
  disabled = false,

  iconOptions = {
    iconName: '',
    iconPosition: 'leading',
    iconSize: 'large',
    UNSAFE_className: ''
  },

  isActive = false,
  isDarkBackground = false,
  isFullWidth = true,
  testId,
  textProps,
  UNSAFE_className,
  children
}: ButtonProps) => {
  const isTextOnLightSurface =
    (variant !== 'primary' && !isDarkBackground && variant !== 'group-child') ||
    (variant === 'group-child' && !isActive && !isDarkBackground);

  const { iconName: icon, iconPosition, iconSize } = iconOptions;
  const iconOnTheRight = iconPosition === 'trailing';

  const textSpanClasses = cx('z-1 relative whitespace-nowrap');

  const disabledTextColor = useMemo(
    () => (disabled ? '#3B3A3E' : null),
    [disabled]
  );

  const iconClasses = cx(
    textSpanClasses,
    isTextOnLightSurface ? 'text-black' : 'text-white',
    iconOnTheRight ? 'order-last' : 'order-first',
    iconOptions.UNSAFE_className,
    {
      'text-tui-xl': iconSize === 'large',
      'text-tui-lg': iconSize === 'medium',
      'text-tui-base': iconSize === 'small'
    }
  );

  const groupChildClasses = cx({
    'bg-black hover:bg-black active:bg-white':
      (!isDarkBackground && isActive) || (isDarkBackground && !isActive),
    'bg-white hover:bg-white active:bg-black':
      (isDarkBackground && isActive) || (!isDarkBackground && !isActive)
  });

  const getTextColorVariant = () => {
    let textColorVariant: ColorType['color'] | undefined = 'secondary';

    if (variant === 'primary') {
      textColorVariant = 'primary';
    }

    if (variant === 'group-child' && !isActive) {
      textColorVariant = 'tertiary';
    }

    return textColorVariant;
  };

  const renderButtonClassNames = (variant: string) => {
    const variants: { [key: string]: string } = {
      // TODO: update design tokens to include current arbitrary values for hover/active states
      primary: 'bg-red-500 hover:bg-red-900 active:bg-red-700 min-w-[12rem]',
      secondary: `border-solid border-thin bg-white hover:bg-white/[0.30] ${
        isDarkBackground
          ? 'border-white border-opacity-50 hover:border-white active:hover:border-[#FBCCD4]'
          : 'border-outline-onLight border-opacity-16 hover:border-black'
      }`,
      tertiary: `${
        isDarkBackground
          ? 'hover:bg-white hover:bg-opacity-16 active:bg-[#4D3136]'
          : 'hover:bg-black hover:bg-opacity-8 active:bg-[#ECD0D4] active:bg-opacity-12'
      }`,
      'group-child': `min-w-[8rem] ${groupChildClasses}`,
      inline: 'gap-1 h-full',
      disabled: 'bg-[#AAAAAB] text-[#3B3A3E] border-none'
    };

    return variants[variant];
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={cx(
        'group relative flex items-center justify-center overflow-hidden ring-blue-300 ring-offset-2 transition-all duration-300 ease-in-out focus-visible:ring-1 active:transition-none active:hover:bg-opacity-100',
        renderButtonClassNames(disabled ? 'disabled' : variant),
        isFullWidth ? 'w-full' : 'w-max',
        variant === 'group-child' || variant === 'inline'
          ? 'rounded-none'
          : 'rounded-sm',
        {
          'h-11 gap-2 px-4': variant !== 'inline'
        },
        UNSAFE_className
      )}
      data-testid={testId}
    >
      {icon && (
        <Icon
          iconName={icon}
          className={iconClasses}
          testId={`button-icon-${icon}`}
        />
      )}
      {text && (
        <Text
          tag="p"
          variant={textProps?.variant ?? 'body2'}
          isBold={textProps?.isBold ?? true}
          color={
            textProps?.color || {
              color: getTextColorVariant(),
              isOnLightSurface: isTextOnLightSurface,
              override: disabledTextColor ?? undefined
            }
          }
          UNSAFE_className={textProps?.UNSAFE_className}
          direction={textProps?.direction}
          weight={textProps?.weight}
          useNewTextStyles={textProps?.useNewTextStyles}
        >
          <span className={textSpanClasses}>{text}</span>
        </Text>
      )}
      {children}
    </button>
  );
};

export default Button;
