import cx from 'classnames';
import { isNil } from 'lodash-es';
import type { MouseEventHandler } from 'react';

type PlayButtonProps = {
  animated?: boolean;
  callback?: MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
  playButtonClasses?: string;
  playIconClasses?: string;
};

import PlayIcon from 'icons/PlayIcon';

export default function PlayButton({
  animated = true,
  callback,
  playButtonClasses = 'w-20 h-20',
  playIconClasses = 'w-8'
}: PlayButtonProps): React.ReactNode {
  const Tag = isNil(callback) ? 'div' : 'button';

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    if (callback) {
      callback(e);
    }
  };

  return (
    <div className="group absolute inset-0 flex size-full cursor-pointer items-center justify-center">
      <Tag
        type="button"
        className={cx(
          playButtonClasses,
          'relative flex items-center justify-center rounded-full',
          animated ? 'bg-white' : 'bg-[#FFFFFF99]'
        )}
        {...(callback && {
          onClick: handleClick
        })}
      >
        <div
          className={cx(
            'absolute size-full rounded-full p-1 opacity-0 transition-transform delay-1000 duration-150 ease-in-out group-hover:scale-[1.15]',
            {
              'animate-playButton': animated
            }
          )}
        />
        <PlayIcon
          variant="default"
          className={cx('h-auto translate-x-[12%] text-black', playIconClasses)}
        />
      </Tag>
    </div>
  );
}
