import cx from 'classnames';
import { Dialog } from 'radix-ui';
import { type ComponentPropsWithoutRef, forwardRef, useRef } from 'react';

export interface DialogOverlayProps
  extends ComponentPropsWithoutRef<typeof Dialog.Overlay> {
  isOpen?: boolean;
  onDismiss?: () => void;
  initialFocusRef?: React.RefObject<HTMLElement>;
  className?: string;
}

export interface DialogContentProps
  extends ComponentPropsWithoutRef<typeof Dialog.Content> {
  'aria-label'?: string;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  title?: string;
}

const VisuallyHidden = ({ children }: { children: React.ReactNode }) => (
  <span className="sr-only">{children}</span>
);

export const DialogOverlay = ({
  isOpen = false,
  onDismiss,
  className,
  children,
  ...props
}: DialogOverlayProps) => {
  const mouseDownTarget = useRef<EventTarget | null>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (event: React.MouseEvent) => {
    mouseDownTarget.current = event.target;
  };

  const handleClick = (event: React.MouseEvent) => {
    // Only dismiss if the click started and ended on the overlay itself
    if (
      mouseDownTarget.current === event.target &&
      event.target === overlayRef.current
    ) {
      event.preventDefault();
      onDismiss?.();
    }
  };

  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={open => !open && onDismiss?.()}
      modal={true}
    >
      <Dialog.Portal>
        <Dialog.Overlay
          ref={overlayRef}
          className={cx(
            'fixed inset-0 z-40 animate-fadeIn bg-black/20',
            className
          )}
          onMouseDown={handleMouseDown}
          onClick={handleClick}
          {...props}
        >
          {children}
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
  (
    { className, children, onClick, title, 'aria-label': ariaLabel, ...props },
    forwardedRef
  ) => {
    const dialogTitle = title || ariaLabel || 'Dialog';

    return (
      <Dialog.Content
        ref={forwardedRef}
        className={cx('bg-white focus:outline-none', className)}
        onClick={event => {
          // Always stop propagation from content
          event.stopPropagation();
          onClick?.(event);
        }}
        {...props}
      >
        <VisuallyHidden>
          <Dialog.Title>{dialogTitle}</Dialog.Title>
        </VisuallyHidden>
        {children}
      </Dialog.Content>
    );
  }
);

DialogOverlay.displayName = 'DialogOverlay';
DialogContent.displayName = 'DialogContent';
