type TakeawaysIconProps = {
  className?: string;
  /** Optional: Appends 'data-testid' attribute used for testing purposes. */
  testId?: string;
};

export const TakeawaysIcon = ({ className, testId }: TakeawaysIconProps) => {
  return (
    <svg
      className={className}
      data-testid={testId}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6038_76304)">
        <path
          d="M13.0444 8.09642C12.7577 8.09642 12.5256 8.3285 12.5256 8.61515V8.97019C12.5256 9.91774 11.731 10.6889 10.7543 10.6889H9.61153C9.31719 10.6889 9.03977 10.8165 8.84995 11.039L7.48934 12.6359C7.3122 12.8434 7.05437 12.9625 6.78156 12.9625C6.50874 12.9625 6.25053 12.8434 6.07377 12.6359L4.71316 11.039C4.52334 10.8165 4.24592 10.6889 3.95158 10.6889H2.80884C1.83208 10.6889 1.03746 9.91813 1.03746 8.97019V2.94791C1.03746 2.00036 1.83208 1.22918 2.80884 1.22918H5.34332C5.62997 1.22918 5.86206 0.997091 5.86206 0.710443C5.86206 0.423796 5.62997 0.191711 5.34332 0.191711H2.80884C1.25994 0.192096 0 1.42822 0 2.94791V8.97019C0 10.4899 1.25994 11.7264 2.80884 11.7264H3.93583L5.28415 13.3087C5.65841 13.7483 6.20442 14 6.78156 14C7.35869 14 7.90471 13.7479 8.27896 13.3087L9.62728 11.7264H10.7543C12.3032 11.7264 13.5631 10.4899 13.5631 8.97019V8.61515C13.5631 8.3285 13.331 8.09642 13.0444 8.09642Z"
          fill="#121212"
        />
        <path
          d="M13.7434 2.31623C13.6551 2.0438 13.3623 1.89472 13.0898 1.98309L11.0741 2.63823V0.518732C11.0741 0.232085 10.842 0 10.5553 0C10.2687 0 10.0366 0.232085 10.0366 0.518732V2.63823L8.02086 1.98309C7.74843 1.89472 7.45563 2.0438 7.36725 2.31623C7.27888 2.58866 7.42797 2.88146 7.7004 2.96984L9.71615 3.62498L8.47042 5.33987C8.30212 5.57157 8.35361 5.89587 8.58531 6.06455C8.67753 6.13141 8.78397 6.16369 8.88964 6.16369C9.04987 6.16369 9.20818 6.08953 9.30962 5.94966L10.5553 4.23477L11.8011 5.94966C11.9694 6.18136 12.2937 6.23285 12.5258 6.06455C12.7575 5.89625 12.8089 5.57157 12.6406 5.33987L11.3949 3.62498L13.4107 2.96984C13.6831 2.88146 13.8322 2.58866 13.7438 2.31623H13.7434Z"
          fill="#121212"
        />
      </g>
      <defs>
        <clipPath id="clip0_6038_76304">
          <rect width="13.7691" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
