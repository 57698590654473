import React from 'react';

import Text from '../../Text';
import { CardDescriptionProps } from './CardDescription.props';

const CardDescription = ({
  children,
  testId
}: CardDescriptionProps & {
  children: React.ReactNode;
}) => (
  <div className="mb-4">
    <Text
      tag="p"
      variant="body2"
      testId={testId}
      useNewTextStyles
      UNSAFE_className="line-clamp-3"
    >
      {children}
    </Text>
  </div>
);
export default CardDescription;
