import { splitAmpersandTopics } from '../../utils';

interface Tabs {
  [key: string]: string[];
}

const TABS: Tabs = {
  'Art & Design': [
    'Architecture',
    'Art',
    'Augmented reality',
    'Beauty',
    'Conducting',
    'Creativity',
    'Design',
    'Fashion',
    'Film',
    'Journalism',
    'Language',
    'Literature',
    'Media',
    'Museums',
    'Music',
    'Performance',
    'Photography',
    'Poetry',
    'Science fiction',
    'Sound',
    'Storytelling',
    'Theater',
    'Virtual reality',
    'Visualizations',
    'Writing'
  ],
  Business: [
    'Behavioral economics',
    'Blockchain',
    'Business',
    'Capitalism',
    'Consumerism',
    'Entrepreneur',
    'Investing',
    'Manufacturing',
    'Marketing',
    'Money',
    'Women in business'
  ],
  'Climate Change': [
    'Climate change',
    'Driverless cars',
    'Electricity',
    'Energy',
    'Fossil fuels',
    'Natural resources',
    'Plastic',
    'Pollution',
    'Renewable energy',
    'Sustainability'
  ],
  Communication: [
    'Body language',
    'Collaboration',
    'Communication',
    'Language',
    'Social media',
    'Public speaking',
    'Storytelling',
    'Visualizations'
  ],
  Education: [
    'Animation',
    'Autism spectrum disorder',
    'Education',
    'Kids',
    'Math',
    'Museums',
    'Teaching',
    'TED-Ed',
    'Toys',
    'Visualizations'
  ],
  Entertainment: [
    'Entertainment',
    'Film',
    'Gaming',
    'Humor',
    'Magic',
    'Music',
    'Performance',
    'Television',
    'Theater',
    'Toys',
    'Travel'
  ],
  'Global Issues': [
    'Activism',
    'Ancient world',
    'Anthropology',
    'Global issues',
    'Government',
    'Health care',
    'History',
    'Immigration',
    'Infrastructure',
    'International development',
    'Military',
    'Policy',
    'Politics',
    'Public health',
    'Public space',
    'Religion',
    'Sociology',
    'Surveillance',
    'Transportation',
    'War'
  ],
  'Government & Politics': [
    'Capitalism',
    'Cities',
    'Community',
    'Corruption',
    'Crime',
    'Democracy',
    'Government',
    'Health care',
    'Human rights',
    'Immigration',
    'Infrastructure',
    'International development',
    'Justice system',
    'Law',
    'Military',
    'Policy',
    'Politics',
    'Poverty',
    'Public health',
    'Public space',
    'Refugees',
    'Terrorism',
    'Transportation',
    'Urban planning',
    'War'
  ],
  Health: [
    'Addiction',
    'Aging',
    'Autism spectrum disorder',
    'Bacteria',
    'Brain',
    'Cancer',
    'Cognitive science',
    'Coronavirus',
    'Death',
    'Disability',
    'Disease',
    'Drugs',
    'Exercise',
    'Genetics',
    'Health',
    'Health care',
    'Human body',
    'Medicine',
    'Pregnancy',
    'Public health',
    'Sex',
    'Sleep',
    'Surgery',
    'Transgender'
  ],
  Leadership: [
    'Communication',
    'Creativity',
    'Goals',
    'Leadership',
    'Motivation',
    'Productivity',
    'Public speaking',
    'Success',
    'Women in business',
    'Work-life balance'
  ],
  Nature: [
    'Animals',
    'Bees',
    'Biodiversity',
    'Birds',
    'Conservation',
    'Coral reefs',
    'Dinosaurs',
    'Ecology',
    'Fish',
    'Fungi',
    'Glaciers',
    'Insects',
    'Nature',
    'Ocean',
    'Plants',
    'Rivers',
    'Trees',
    'Water'
  ],
  Parenting: [
    'Animation',
    'Autism spectrum disorder',
    'Bullying',
    'Education',
    'Family',
    'Kids',
    'Parenting',
    'Pregnancy',
    'Teaching',
    'Toys'
  ],
  'Personal growth': [
    'Aging',
    'Communication',
    'Compassion',
    'Creativity',
    'Curiosity',
    'Death',
    'Depression',
    'Emotions',
    'Empathy',
    'Ethics',
    'Fear',
    'Happiness',
    'Love',
    'Mental health',
    'Mindfulness',
    'Motivation',
    'Personal growth',
    'Sex',
    'Sleep',
    'Trust',
    'Vulnerability'
  ],
  Psychology: [
    'Addiction',
    'Behavioral economics',
    'Brain',
    'Compassion',
    'Consciousness',
    'Creativity',
    'Curiosity',
    'Depression',
    'Emotions',
    'Empathy',
    'Ethics',
    'Fear',
    'Happiness',
    'Love',
    'Memory',
    'Mental health',
    'Mindfulness',
    'Motivation',
    'Personality',
    'Psychology',
    'Violence',
    'Vulnerability'
  ],
  Relationships: [
    'Communication',
    'Family',
    'Friendship',
    'Love',
    'Relationships',
    'Trust'
  ],
  Science: [
    'Aliens',
    'Astronomy',
    'Bacteria',
    'Chemistry',
    'Dinosaurs',
    'Electricity',
    'Evolution',
    'Life',
    'Marine biology',
    'Mars',
    'Moon',
    'Physics',
    'Planets',
    'Science',
    'Space',
    'Universe'
  ],
  'Sports & Fitness': ['Dance', 'Exercise', 'Food', 'Motivation', 'Sports'],
  'Social Change': [
    'Activism',
    'Anthropology',
    'Disability',
    'Diversity',
    'Equality',
    'Human rights',
    'Inclusion',
    'Indigenous peoples',
    'LGBTQIA+',
    'Philanthropy',
    'Protest',
    'Race',
    'Slavery',
    'Social change',
    'Transgender'
  ],
  Technology: [
    '3D printing',
    'AI',
    'Blockchain',
    'Computers',
    'Cyber security',
    'Data',
    'Driverless cars',
    'Drones',
    'Engineering',
    'Future',
    'Internet',
    'Nanotechnology',
    'Robots',
    'Social media',
    'Software',
    'Surveillance',
    'Technology',
    'Virtual reality'
  ],
  Women: [
    'Feminism',
    'Gender',
    'Pregnancy',
    'Sexual violence',
    'Transgender',
    'Women',
    'Women in business',
    'Work-life balance'
  ]
};

const LOWERCASED_TABS = Object.entries(TABS).reduce<Tabs>(
  (acc, [key, values]) => {
    if (Array.isArray(values)) {
      const splitKey = splitAmpersandTopics([key]);
      const lowerKey = splitKey.map(key => key.toLowerCase());
      lowerKey.forEach(key => {
        acc[key] = values.map(value => value.toLowerCase());
      });
    }
    return acc;
  },
  {}
);

export const TOPICS_MODAL_CONSTANTS = {
  TABS,
  LOWERCASED_TABS
} as const;
