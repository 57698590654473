type MessageSquareIconProps = {
  className?: string;
  /** Optional: Appends 'data-testid' attribute used for testing purposes. */
  testId?: string;
};

export const MessageSquareIcon = ({
  className,
  testId
}: MessageSquareIconProps) => {
  return (
    <svg
      className={className}
      data-testid={testId}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9362 3.125C4.65993 3.125 4.39498 3.23475 4.19963 3.4301C4.00428 3.62545 3.89453 3.8904 3.89453 4.16667V15.9911L6.16092 13.7247C6.27813 13.6075 6.4371 13.5417 6.60286 13.5417H16.6029C16.8791 13.5417 17.1441 13.4319 17.3394 13.2366C17.5348 13.0412 17.6445 12.7763 17.6445 12.5V4.16667C17.6445 3.8904 17.5348 3.62545 17.3394 3.4301C17.1441 3.23475 16.8791 3.125 16.6029 3.125H4.9362ZM3.31574 2.54621C3.74552 2.11644 4.32841 1.875 4.9362 1.875H16.6029C17.2107 1.875 17.7935 2.11644 18.2233 2.54621C18.6531 2.97598 18.8945 3.55888 18.8945 4.16667V12.5C18.8945 13.1078 18.6531 13.6907 18.2233 14.1205C17.7935 14.5502 17.2107 14.7917 16.6029 14.7917H6.86175L3.71147 17.9419C3.53272 18.1207 3.2639 18.1742 3.03035 18.0774C2.79681 17.9807 2.64453 17.7528 2.64453 17.5V4.16667C2.64453 3.55888 2.88597 2.97598 3.31574 2.54621Z"
        fill="#121212"
      />
    </svg>
  );
};
