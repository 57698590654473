import { useState, useEffect, useCallback } from 'react';
import { crush } from '@tedconf/js-crushinator-helpers';
import type { Dispatch, SetStateAction } from 'react';

interface UseOptimizedImageProps {
  imageUrl: string;
  width: number;
  height: number;
}

type UseOptimizedImageReturn = {
  /* used when you want to used the blurred image loading */
  src: string | undefined;
  /* used when you want to used the blurred image loading */
  blurredSrc: string | undefined;
  /* used when you want to used the optimized image without the blurred loading */
  optimizedImage: string | undefined;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  error: Error | null;
};

export const useOptimizedImage = ({
  imageUrl,
  width,
  height
}: UseOptimizedImageProps): UseOptimizedImageReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [src, setSrc] = useState<string>();
  const [error, setError] = useState<Error | null>(null);

  const getCrushedImage = useCallback(
    (url: string, width?: number, height?: number, quality?: number) => {
      try {
        return crush(url, { width, height, quality });
      } catch (e) {
        setError(e);
        return url;
      }
    },
    []
  );

  const blurredImageUrl = getCrushedImage(imageUrl, 5, 5, 50);

  const optimizedImageUrl = getCrushedImage(imageUrl, width, height, 75);

  useEffect(() => {
    if (isLoading) {
      setSrc(blurredImageUrl);
    }

    if (!isLoading) {
      setSrc(optimizedImageUrl);
    }

    return () => {
      setSrc(undefined);
    };
  }, [isLoading, blurredImageUrl, optimizedImageUrl]);

  return {
    src,
    isLoading,
    setIsLoading,
    error,
    blurredSrc: blurredImageUrl,
    optimizedImage: optimizedImageUrl
  };
};
