import type { JSX } from 'react';

type PlayIconProps = {
  className?: string;
  variant: 'default' | 'circle' | 'outlined' | 'video';
};

const PlayIcon = (props: PlayIconProps): JSX.Element => {
  const { className, variant } = props;
  switch (variant) {
    case 'circle':
      return (
        <svg
          className={className || ''}
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="64" height="64" rx="32" fill="currentColor" />
          <path
            d="M27 23L41 32L27 41V23Z"
            fill="white"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case 'video':
    case 'outlined':
      return (
        <svg
          className={className || ''}
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.7"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.9993 4.16663C9.18784 4.16663 3.66602 9.68845 3.66602 16.5C3.66602 23.3115 9.18784 28.8333 15.9993 28.8333C22.8109 28.8333 28.3327 23.3115 28.3327 16.5C28.3327 9.68845 22.8109 4.16663 15.9993 4.16663ZM1.66602 16.5C1.66602 8.58388 8.08327 2.16663 15.9993 2.16663C23.9154 2.16663 30.3327 8.58388 30.3327 16.5C30.3327 24.416 23.9154 30.8333 15.9993 30.8333C8.08327 30.8333 1.66602 24.416 1.66602 16.5Z"
            fill="white"
          />
          <path
            opacity="0.7"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.8621 10.285C13.1873 10.1109 13.5818 10.13 13.8887 10.3346L21.8887 15.6679C22.1669 15.8534 22.334 16.1656 22.334 16.5C22.334 16.8343 22.1669 17.1465 21.8887 17.332L13.8887 22.6653C13.5818 22.8699 13.1873 22.889 12.8621 22.715C12.537 22.5409 12.334 22.2021 12.334 21.8333V11.1666C12.334 10.7978 12.537 10.459 12.8621 10.285ZM14.334 13.0351V19.9648L19.5312 16.5L14.334 13.0351Z"
            fill="white"
          />
        </svg>
      );
    case 'default':
    default:
      return (
        <svg className={className || ''} viewBox="0 -4 20 31" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2741 12.2497L2.27343 21.7694C1.58317 22.2074 0.668487 22.003 0.230434 21.3127C0.0799232 21.0755 0 20.8004 0 20.5195V1.48027C0 0.662739 0.662739 0 1.48027 0C1.76117 0 2.03627 0.0799232 2.27343 0.230434L17.2741 9.75007C17.9643 10.1881 18.1688 11.1028 17.7307 11.7931C17.614 11.9771 17.4581 12.133 17.2741 12.2497Z"
            fill="currentColor"
          />
        </svg>
      );
  }
};
export default PlayIcon;
