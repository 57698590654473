import cx from 'classnames';
import { memo } from 'react';

import { GridItemProps } from '../Grid.props';
import { renderClasses } from '../utils';

/**
 * The GridItem component is a layout component that represents an individual item within a Grid.
 * It accepts props for specifying the column span for each breakpoint.
 * @param span - Specifies how many columns the GridItem should span for each breakpoint.
 * @param rowSpan - Specifies how many rows the GridItem should span for each breakpoint.
 * @param children - Child elements to be rendered within the GridItem.
 * @returns The GridItem component with the specified column span.
 */
const GridItem = memo(
  ({ span = { xs: 1 }, rowSpan, children }: GridItemProps) => {
    const columnClasses = renderClasses({
      prop: span,
      classPrefix: 'col-span'
    });

    const rowClasses = renderClasses({
      prop: rowSpan,
      classPrefix: 'row-span'
    });

    return <div className={cx(columnClasses, rowClasses)}>{children}</div>;
  }
);

export default GridItem;
