import cx from 'classnames';
import dynamic from 'next/dynamic';

import PlayButton from 'components/play-button';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import Badge from '../../Badge';
import CardMediaProps from './CardMedia.props';

const ShimmerImage = dynamic(
  () => import('components/@tedui/Atoms/ShimmerImage'),
  {
    ssr: false
  }
);

const CardMedia = ({
  image: { src, alt, aspect = 'rectangle', sizes = '' },
  badges = null,
  testId,
  showPlayIcon,
  sendHomepageMixpanelEventByContext,
  blurUntilLoaded = false,
  setIsLoading,
  onClick,
  isNewCard = false
}: CardMediaProps) => {
  const isMobileWidth = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'tui'
  });
  const imageContainerClassName = cx('relative flex size-full', {
    'aspect-h-9 aspect-w-16': aspect === 'rectangle',
    'aspect-h-1 aspect-w-1': aspect === 'square',
    'aspect-none': aspect === 'none'
  });

  const topBadgeClassName = 'absolute top-2';
  const leftBadgeClassName = 'left-2';
  const bottomBadgeClassName = 'absolute bottom-2';
  const rightBadgeClassName = 'right-2';
  const cardBorderRadius = isNewCard ? 'rounded-t-md' : 'rounded-xs';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMixpanelEvent = (_e: any, context: string) => {
    sendHomepageMixpanelEventByContext?.(context);
  };

  return (
    <div
      className={imageContainerClassName}
      data-testid={testId}
      {...(onClick && {
        role: 'none',
        onClick: () => onClick()
      })}
    >
      {blurUntilLoaded ? (
        <ShimmerImage
          src={src || ''}
          alt={alt}
          fill
          sizes={sizes}
          className={cx(cardBorderRadius, 'object-cover object-top')}
          onLoad={() => setIsLoading?.(false)}
          loading="eager"
        />
      ) : (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className={`w-full ${cardBorderRadius} object-cover`}
          src={src || ''}
          alt={alt}
        />
      )}
      {badges && (
        <div
          role="none"
          className="absolute bottom-0 left-0 right-0 top-0 size-full"
          {...(sendHomepageMixpanelEventByContext && {
            onClick: e => handleMixpanelEvent(e, 'talk_thumbnail')
          })}
        >
          {showPlayIcon && !isMobileWidth && (
            <PlayButton
              animated={false}
              {...(sendHomepageMixpanelEventByContext && {
                callback: e => handleMixpanelEvent(e, 'talk_play_icon')
              })}
              playButtonClasses="w-12 h-12"
              playIconClasses="w-5"
            />
          )}
          {badges.topLeft && (
            <div className={`${topBadgeClassName} ${leftBadgeClassName}`}>
              <Badge
                text={badges.topLeft.text}
                testId="badge--topLeft"
                isDarkBackground={badges.topLeft.isDarkBackground}
                backgroundColor={badges.topLeft.backgroundColor}
              />
            </div>
          )}
          {badges.topRight && (
            <div className={`${topBadgeClassName} ${rightBadgeClassName}`}>
              <Badge
                text={badges.topRight.text}
                testId="badge--topRight"
                isDarkBackground={badges.topRight.isDarkBackground}
                backgroundColor={badges.topRight.backgroundColor}
              />
            </div>
          )}
          {badges.bottomLeft && (
            <div className={`${bottomBadgeClassName} ${leftBadgeClassName}`}>
              <Badge
                text={badges.bottomLeft.text}
                testId="badge--bottomLeft"
                isDarkBackground={badges.bottomLeft.isDarkBackground}
                backgroundColor={badges.bottomLeft.backgroundColor}
              />
            </div>
          )}
          {badges.bottomRight && (
            <div className={`${bottomBadgeClassName} ${rightBadgeClassName}`}>
              <Badge
                text={badges.bottomRight.text}
                testId="badge--bottomRight"
                isDarkBackground={badges.bottomRight.isDarkBackground}
                backgroundColor={badges.bottomRight.backgroundColor}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CardMedia;
