import cx from 'classnames';
import { useState } from 'react';

import Icon from 'components/@tedui/Icon';
import { ChipProps } from './Chip.props';

interface ExtendedChipProps extends ChipProps {
  totalOptions?: number;
  currentIndex?: number;
}

const Chip = ({
  id,
  text,
  onChange,
  checked = false,
  applied = false,
  testId = null,
  disabled = false,
  applyHover = false,
  totalOptions = 0,
  currentIndex = 0,
  ...props
}: ExtendedChipProps): React.ReactNode => {
  const [focused, setFocusedState] = useState(false);
  const activeState = checked || applied;

  const chipClasses = cx(
    'inline-flex cursor-pointer select-none items-center whitespace-nowrap rounded-full border-thin border-outline-onLight border-opacity-16 px-4 py-2 text-tui-xs',
    {
      'bg-[#f1f1fb]': activeState,
      'outline-default': focused,
      'gap-1': checked,
      'gap-2': applied,
      'pointer-events-none opacity-50': disabled,
      'hover:bg-[#c4c4c4]': applyHover
    }
  );

  // Create descriptive status for screen readers
  const getAriaLabel = () => {
    const position = totalOptions
      ? ` Option ${currentIndex + 1} of ${totalOptions}.`
      : '';
    const status = checked ? 'Selected.' : 'Not selected.';
    const disabledStatus = disabled ? ' This option is disabled.' : '';
    return `${text}. ${status}${position}${disabledStatus} Press Space or Enter to ${checked ? 'unselect' : 'select'}.`;
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!disabled && onChange) {
      onChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      if (!disabled && onChange) {
        onChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
      }
    }
  };

  return (
    <button
      id={id}
      className={chipClasses}
      data-testid={testId}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onFocus={() => setFocusedState(true)}
      onBlur={() => setFocusedState(false)}
      type="button"
      role="checkbox"
      aria-checked={checked}
      aria-label={getAriaLabel()}
      aria-disabled={disabled}
      disabled={disabled}
      {...props}
    >
      <span className="capitalize">{text}</span>
      {checked && (
        <span className="inline-flex rounded-full text-base" aria-hidden="true">
          <Icon iconName="check" className="font-semibold" />
        </span>
      )}
      {applied && (
        <span
          className="flex h-4 w-4 items-center justify-center rounded-full bg-black"
          aria-hidden="true"
        >
          <Icon iconName="x" className="text-xs font-semibold text-white" />
        </span>
      )}
    </button>
  );
};

export default Chip;
