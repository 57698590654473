import cx from 'classnames';

import type IconProps from './Icon.props';

const Icon = ({ iconName, className, testId }: IconProps) => (
  <i
    aria-hidden
    data-testid={testId}
    className={cx(`icon-${iconName}`, className)}
  />
);

export default Icon;
