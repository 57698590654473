import cx from 'classnames';
import { useDarkMode } from 'components/theme/DarkModeProvider';

type DividerProps = {
  isLarge?: boolean;
  className?: string;
};

const Divider = ({ isLarge, className }: DividerProps): React.ReactNode => {
  const { isDarkMode } = useDarkMode();

  const dividerClasses = cx(className, 'bg-black dark:bg-[#898989]', {
    'h-2': isLarge,
    'h-px': !isLarge,
    'opacity-8': isLarge && !isDarkMode,
    'opacity-16': !isLarge && !isDarkMode,
    'opacity-100': isDarkMode
  });

  return <div className={dividerClasses} />;
};

export default Divider;
