import { useEffect, useState } from 'react';

import {
  adBreakpoints,
  breakpoints,
  tuiBreakpoints,
  prismicBreakpoints
} from '../../../../design-tokens/constants';
import { useMediaMatch } from './useMediaMatch';

import type {
  Breakpoint,
  UseIsBreakpointWidthProps
} from './useIsBreakpointWidth.props';

export function useIsBreakpointWidth({
  size = 'lg',
  breakPointType = 'default'
}: UseIsBreakpointWidthProps): boolean {
  const [isBreakpointWidth, setIsBreakpointWidth] = useState<boolean>(true);

  const breakpoint = (type: string): number => {
    switch (type) {
      case 'tui':
        return (tuiBreakpoints as Record<Breakpoint, number>)[size] - 1 || 0;
      case 'ads':
        return (
          (adBreakpoints.standard as Record<Breakpoint, number>)[size] - 1 || 0
        );
      case 'playlistAds':
        return (
          (adBreakpoints.playlists as Record<Breakpoint, number>)[size] - 1 || 0
        );
      case 'topicResultsAds':
        return (
          (adBreakpoints.topicResults as Record<Breakpoint, number>)[size] -
            1 || 0
        );
      case 'prismic':
        return (
          (prismicBreakpoints as Record<Breakpoint, number>)[size] - 1 || 0
        );
      default:
        return (breakpoints as Record<Breakpoint, number>)[size] - 1 || 0;
    }
  };

  const matchesMedia = useMediaMatch(
    `(max-width: ${breakpoint(breakPointType)}px)`
  );

  /* This useEffect is required, otherwise this method runs once on the server
   * during SSR and won't update with the actual screen width initially on the
   * client-side */
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setIsBreakpointWidth(matchesMedia);
  }, [size, breakPointType, matchesMedia]);

  return isBreakpointWidth;
}
