import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useInstantSearch, useNumericMenu } from 'react-instantsearch';

import { Dropdown, DropdownItem } from 'components/@tedui';

import { FILTER_BAR_CONSTANTS } from '..';
import { useTrackClickEvent } from '../../../analytics';
import { updateUrlWithParams, waitForRouteChange } from '../../../utils';

export const Duration = (): React.ReactNode => {
  const router = useRouter();
  const ISTALKSPAGE = router.pathname === '/talks';

  const { indexUiState, results } = useInstantSearch();
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  }>();

  const { refine, items } = useNumericMenu({
    attribute: 'duration',
    items: FILTER_BAR_CONSTANTS.durationOptions
  });

  const trackClick = useTrackClickEvent(
    results
      ? {
          index: results.index,
          nbHits: results.nbHits
        }
      : undefined
  );
  const handleOnSelect = useCallback(
    async (label: string, value: string) => {
      const currentDurationObj = FILTER_BAR_CONSTANTS.durationOptions.find(
        option => option.value === value
      );

      setSelectedOption({ label, value });

      trackClick({
        eventName: 'duration_selection',
        additionalEvents: {
          duration_filter: currentDurationObj?.trackingValue
        },
        waitForResultsVolume: true
      });

      updateUrlWithParams(router, {
        duration: currentDurationObj?.queryString || ''
      });

      if (!ISTALKSPAGE) {
        await waitForRouteChange();
        refine(value);
      }
    },
    [ISTALKSPAGE, indexUiState.sortBy, refine, router, trackClick]
  );

  useEffect(() => {
    const durationFromState = indexUiState.numericMenu?.duration;

    if (durationFromState) {
      const startFromState = durationFromState.split(':').map(Number);

      let currentDurationObj = FILTER_BAR_CONSTANTS.durationOptions[0];

      FILTER_BAR_CONSTANTS.durationOptions.some(option => {
        if (option.start === startFromState[0]) {
          if (typeof option.end !== 'undefined') {
            if (option.end === startFromState[1]) {
              currentDurationObj = option;
              return true;
            }
          } else {
            currentDurationObj = option;
            return true;
          }
        }
        return false;
      });

      setSelectedOption({
        label: currentDurationObj.label,
        value: currentDurationObj.value
      });
    } else {
      setSelectedOption(undefined);
    }
  }, [indexUiState.numericMenu?.duration]);

  return (
    <Dropdown label="Duration" selectedValue={selectedOption}>
      {items.map(({ label, value }) => {
        return (
          <DropdownItem
            key={label}
            label={label}
            value={value}
            onSelect={() => handleOnSelect(label, value)}
            isSelected={value === selectedOption?.value}
          />
        );
      })}
    </Dropdown>
  );
};
