import { PatternFormat } from 'react-number-format';

import Input from '../Input';
import MaskedInputProps from './MaskedInput.props';

const numberFormat = {
  phoneNumber: '(###) ###-####',
  creditCard: '#### #### #### ####'
};

/**
 * MaskedInput is a formatted input component that uses predefined masks to format and validate user input.
 * @param id - The id attribute for the input element.
 * @param label - The label text for the input element.
 * @param helper - The helper text to display below the input element (optional).
 * @param format - The predefined format for the input value (e.g., "phoneNumber", "creditCard").
 * @param iconOptions - The options for displaying an icon within the input component (optional).
 * @param validation - The validation status and error message for the input component (optional).
 * @param isDarkBackground - Indicates whether the input is displayed on a dark background (optional).
 * @param onChange - The callback function called when the input value changes (optional).
 * @param testId - The testId attribute for the input element, used for testing purposes (optional).
 * @returns The MaskedInput component with the specified format, attributes, and functionality.
 */
const MaskedInput = ({
  id,
  label,
  helper,
  format,
  iconOptions = {},
  validation,
  isDarkBackground,
  onChange,
  testId = 'Masked Input'
}: MaskedInputProps) => (
  <PatternFormat
    format={numberFormat[format]}
    customInput={Input}
    id={id}
    label={label}
    helper={helper}
    type="text"
    iconOptions={iconOptions}
    validation={validation}
    isDarkBackground={isDarkBackground}
    onChange={onChange}
    testId={testId}
  />
);

export default MaskedInput;
