import cx from 'classnames';
import { kebabCase } from 'lodash-es';
import L, { LinkProps } from 'next/link';

export * from 'next/router';

type LinkRouterProps = Partial<LinkProps> & {
  ref?: React.RefObject<HTMLAnchorElement | null>;
  id?: string;
  title?: string;
  children: React.ReactNode;
  isZenithLink?: boolean;
  className?: string;
  tabIndex?: number;
  target?: string;
};

// Checks if url starts with http(s)
// and if it is one of the TED.com domains
// including staging and local environments
const internalURL = (url: string) => {
  if (url.search(/^http/g) === -1) {
    return true;
  }

  return (
    url.search(
      /^https?:\/\/(www\.|zenith--.*\.staging\.|local\.|blog\.|tedatwork\.)?ted.com(:4000)?/
    ) === 0
  );
};

export const Link = ({
  ref,
  scroll,
  href,
  as,
  isZenithLink,
  target = undefined,
  prefetch,
  className = '',
  id,
  ...props
}: LinkRouterProps) => {
  if (!href) {
    console.warn('href is required');
    return null;
  }

  if (
    typeof href === 'string' &&
    !isZenithLink &&
    (!!target || !internalURL(href))
  ) {
    return (
      <a
        ref={ref}
        id={id ?? `${kebabCase(props.title)}`}
        rel="noreferrer noopener"
        target={target}
        href={href}
        {...props}
        className={cx(className, 'relative')}
      />
    );
  }

  return (
    <L
      id={id}
      prefetch={prefetch}
      href={href}
      as={as}
      scroll={scroll}
      className={cx(className, 'relative')}
      ref={ref}
      {...props}
    />
  );
};
