import cx from 'classnames';
import { debounce, throttle } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useDidMount } from 'lib/hooks/useDidMount';

import type { ScrollArrowsProps } from './ScrollArrows.props';
import { ScrollButton } from './ScrollButton';

interface GradientPosition {
  top?: number;
  left?: number;
}

/**
 * ScrollArrows is a component that provides left and right scroll functionality for scrollable containers.
 * It takes a scrollAreaRef and showArrows prop to control the scrolling behavior and visibility of the arrows.
 * @param showArrows - A boolean indicating whether the arrows should be shown.
 * @param scrollAreaRef - A reference to the scrollable container.
 * @param scrollMode - Indicates if the scroll is step or continuous.
 * @param adjustArrowButtonPosition - A boolean indicating whether the arrow buttons should be adjusted to the scrollable container.
 * @returns The ScrollArrows with the specified behavior and visibility.
 */
export const ScrollArrows = ({
  showArrows,
  scrollAreaRef,
  scrollMode = 'continuous',
  adjustArrowButtonPosition = false,
  gradientColor = 'gray-50'
}: ScrollArrowsProps): React.ReactNode => {
  const hasMounted = useDidMount();
  const [hasScrollLeft, setHasScrollLeft] = useState(true);
  const [hasScrollRight, setHasScrollRight] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [gradientPosition, setGradientPosition] = useState<GradientPosition>(
    {}
  );
  const [numberOfTabs, setNumberOfTabs] = useState(0);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const updatePositionRef = useRef(
    debounce(() => {
      if (hasMounted && scrollAreaRef?.current) {
        const rect = scrollAreaRef.current.getBoundingClientRect();
        setGradientPosition({ top: rect.top, left: rect.left });
      }
    }, 200)
  );

  const handleScrollRef = useRef(
    throttle((container: HTMLElement, children: HTMLElement[]) => {
      const currentScroll = container.scrollLeft;
      setHasScrollLeft(currentScroll > 0);
      setHasScrollRight(
        currentScroll < container.scrollWidth - container.clientWidth
      );

      if (currentScroll > 0) {
        setHasScrolled(true);
      }

      const newTabIndex = children.findIndex(
        (child, index) =>
          child.offsetLeft <= currentScroll &&
          (index === children.length - 1 ||
            children[index + 1].offsetLeft > currentScroll)
      );

      if (newTabIndex !== -1 && newTabIndex !== currentTabIndex) {
        setCurrentTabIndex(newTabIndex);
      }
    }, 200)
  );

  const classNames = {
    gradientContainer:
      'fixed pointer-events-none w-full z-40 flex flex-col justify-center',
    gradientWrapper: 'absolute w-full inset-0 flex flex-col justify-center',
    continuousGradient: `absolute h-full to-${gradientColor} w-6 via-${gradientColor} top-0 from-transparent`,
    stepGradient:
      'absolute h-full w-20 top-0 from-transparent via-white to-white',
    clippedGradient: 'clip-[polygon(0 0, 100% 0, 100% 100%, 0 100%)]'
  };

  const scrollSingle = useCallback(
    (direction?: 'left' | 'right') => {
      const container = scrollAreaRef.current;
      if (!container) return [];

      const children = Array.from(
        container.querySelectorAll('.group.tab')
      ) as Array<HTMLElement>;

      if (direction === 'left') {
        if (currentTabIndex === 1) {
          container.scrollTo({ left: 0, behavior: 'smooth' });
          setCurrentTabIndex(0);
        } else if (currentTabIndex > 0) {
          setCurrentTabIndex(currentTabIndex - 3);
          const targetScroll =
            children[
              currentTabIndex > 2 ? currentTabIndex - 3 : currentTabIndex
            ].offsetLeft;
          container.scrollTo({
            left: targetScroll - children[currentTabIndex].offsetWidth,
            behavior: 'smooth'
          });
        }
      }

      if (direction === 'right') {
        if (currentTabIndex < numberOfTabs - 7) {
          setCurrentTabIndex(currentTabIndex + 3);
          const targetScroll = children[currentTabIndex + 3].offsetLeft;
          container.scrollTo({
            left: targetScroll + children[currentTabIndex + 3].offsetWidth,
            behavior: 'smooth'
          });
        } else {
          container.scrollTo({
            left: container.scrollWidth,
            behavior: 'smooth'
          });
        }
      }
    },
    [scrollAreaRef, currentTabIndex, numberOfTabs]
  );

  const scrollToEnd = useCallback(
    (direction: 'left' | 'right') => {
      const container = scrollAreaRef.current;
      if (!container) return;
      const scrollDelta = 275;

      if (direction === 'right') {
        const remainingRight =
          container.scrollWidth - container.scrollLeft - container.clientWidth;
        if (remainingRight < scrollDelta) {
          container.scrollLeft = container.scrollWidth;
        } else {
          container.scrollLeft += scrollDelta;
        }
      } else if (direction === 'left') {
        const remainingLeft = container.scrollLeft;
        if (remainingLeft < scrollDelta) {
          container.scrollLeft = 0;
        } else {
          container.scrollLeft -= scrollDelta;
        }
      }
    },
    [scrollAreaRef]
  );

  const scroll = scrollMode === 'continuous' ? scrollToEnd : scrollSingle;

  useEffect(() => {
    const updatePosition = updatePositionRef.current;

    if (hasMounted) {
      updatePosition();
      window.addEventListener('scroll', updatePosition);
      window.addEventListener('resize', updatePosition);

      return () => {
        updatePosition.cancel();
        window.removeEventListener('scroll', updatePosition);
        window.removeEventListener('resize', updatePosition);
      };
    }
  }, [hasMounted]);

  useEffect(() => {
    const container = scrollAreaRef.current;
    if (!container) return;

    const children = Array.from(
      container.querySelectorAll('.group.tab')
    ) as Array<HTMLElement>;
    setNumberOfTabs(children.length);

    const handleScroll = () => handleScrollRef.current(container, children);
    container.addEventListener('scroll', handleScroll);

    return () => {
      handleScrollRef.current.cancel();
      container.removeEventListener('scroll', handleScroll);
    };
  }, [scrollAreaRef, currentTabIndex]);

  if (!showArrows) return null;

  return (
    <div className="align-center scroll-padding-16 z-10 flex snap-x items-center">
      <div
        className={classNames.gradientContainer}
        style={{ ...gradientPosition }}
      >
        <div
          className={classNames.gradientWrapper}
          style={{
            height: scrollAreaRef.current?.offsetHeight,
            width: scrollAreaRef.current?.offsetWidth
          }}
        >
          {scrollMode === 'step' ? (
            <>
              {currentTabIndex > 0 && (
                <div
                  className={cx(
                    classNames.stepGradient,
                    classNames.clippedGradient,
                    'left-0 bg-gradient-to-l'
                  )}
                />
              )}

              {currentTabIndex < numberOfTabs - 7 && (
                <div
                  className={cx(
                    classNames.stepGradient,
                    classNames.clippedGradient,
                    'right-0 bg-gradient-to-r'
                  )}
                />
              )}
            </>
          ) : (
            <>
              {hasScrolled && hasScrollLeft && (
                <div
                  className={cx(
                    classNames.continuousGradient,
                    classNames.clippedGradient,
                    {
                      'left-0 bg-gradient-to-l': hasScrollLeft
                    }
                  )}
                  aria-hidden="true"
                />
              )}
              {hasScrollRight && (
                <div
                  className={cx(
                    classNames.continuousGradient,
                    classNames.clippedGradient,
                    {
                      'right-0 bg-gradient-to-r': hasScrollRight
                    }
                  )}
                  aria-hidden="true"
                />
              )}
            </>
          )}
        </div>
      </div>

      {scrollMode === 'continuous' ? (
        <>
          {hasScrolled && hasScrollLeft && (
            <ScrollButton
              direction="left"
              scroll={scrollToEnd}
              adjustPosition={adjustArrowButtonPosition}
            />
          )}
          {hasScrollRight && (
            <ScrollButton
              direction="right"
              scroll={scrollToEnd}
              adjustPosition={adjustArrowButtonPosition}
            />
          )}
        </>
      ) : (
        <>
          {currentTabIndex > 0 && hasScrollLeft && (
            <ScrollButton
              direction="left"
              scroll={scroll}
              adjustPosition={adjustArrowButtonPosition}
            />
          )}

          {currentTabIndex < numberOfTabs - 7 && hasScrollRight && (
            <ScrollButton
              direction="right"
              scroll={scroll}
              adjustPosition={adjustArrowButtonPosition}
            />
          )}
        </>
      )}
    </div>
  );
};
