import Image from 'components/image';
import { memo } from 'react';

import { client } from 'f';
import type { ShimmerImageProps } from './ShimmerImage.props';

export const getBase64Shimmer = ({
  width,
  height
}: Pick<ShimmerImageProps, 'width' | 'height'>) => {
  const svg = `<svg width="${width}" height="${height}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#333" offset="20%" />
      <stop stop-color="#222" offset="50%" />
      <stop stop-color="#333" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${width}" height="${height}" fill="#333" />
  <rect id="r" width="${width}" height="${height}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${width}" to="${width}" dur="1s" repeatCount="indefinite"  />
</svg>`;
  return client ? window.btoa(svg) : Buffer.from(svg).toString('base64');
};

const ShimmerImage = ({
  src,
  alt,
  width,
  height,
  onLoad,
  sizes,
  ...props
}: ShimmerImageProps): React.ReactNode => (
  <Image
    alt={alt}
    src={src}
    placeholder="blur"
    blurDataURL={`data:image/svg+xml;base64,${getBase64Shimmer({
      width,
      height
    })}`}
    width={width}
    height={height}
    onLoad={onLoad}
    sizes={sizes}
    {...props}
  />
);

export default memo(ShimmerImage);
