import { useCallback, useEffect } from 'react';

import type { UseClickOutsideProps } from './useClickOutside.props';

/**
 * useClickOutside is a custom hook that detects clicks outside of the dropdown and triggers the provided callback.
 * It is used to close the dropdown menu when the user clicks outside of it.
 * @param ref - The ref of the dropdown element.
 * @param onClickOutside - The callback function called when a click is detected outside the dropdown.
 */
export const useClickOutside = ({
  ref,
  onClickOutside,
  portalContainerId
}: UseClickOutsideProps): void => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const portalElement = portalContainerId
        ? document.getElementById(portalContainerId)
        : null;
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        (!portalElement || !portalElement.contains(event.target as Node))
      ) {
        onClickOutside();
      }
    },
    [onClickOutside, ref, portalContainerId]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, ref, handleClickOutside, portalContainerId]);
};
