import cx from 'classnames';
import { useCallback, useEffect, useRef } from 'react';

import { Chip, ScrollArrows, Text } from 'components/@tedui';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from 'components/shared/Tabs';
import { useLocalStorage } from 'lib/hooks/useLocalStorage';

import { useTrackClickEvent } from '../../../analytics';
import { splitAmpersandTopics } from '../../../utils';

import { useInstantSearch } from 'react-instantsearch';
import { TOPICS_MODAL_CONSTANTS } from '..';
import type { Topic } from '../TopicsModal.props';
import { useModalStore } from '../store';
import { countCheckedTopicsForTab } from '../utils';

type TopicsModalTabsProps = {
  items: Topic[];
};

export function TopicsModalTabs({
  items
}: TopicsModalTabsProps): React.ReactNode {
  const {
    checkedTopicState,
    selectAllStatus,
    tabIndex,
    setSelectAllStatus,
    setCheckedTopicState,
    setTabIndex,
    localCheckedTopicState,
    setLocalCheckedTopicState,
    setLocalSelectedTopicsCount
  } = useModalStore();
  const { results } = useInstantSearch();

  const tabs = TOPICS_MODAL_CONSTANTS.TABS;
  const tabList = useRef(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  const data = items;
  const trackClick = useTrackClickEvent(
    results
      ? {
          index: results.index,
          nbHits: results.nbHits
        }
      : undefined
  );
  const [, setCategoryContext] = useLocalStorage('category', '');

  const classNames = {
    listContainer:
      'flex overflow-auto scroll-smooth scrollbar-hide border-b-thin border-black/16',
    list: 'flex w-full flex-nowrap gap-6 lg-tui:gap-10 px-3 lg-tui:px-12',
    tab: cx(
      'tab outline-inside group text-gray-500 transition-colors hover:text-gray-700 focus:text-gray-700',
      'border-red-500 aria-selected:border-b-thickest aria-selected:font-bold aria-selected:text-textPrimary-onLight'
    ),
    tabText: 'relative pb-2 flex gap-2 items-center',
    tabCount: 'inline-flex w-auto justify-center rounded-full bg-gray-300 px-2',
    panel:
      'h-60 overflow-y-scroll pt-5 scrollbar-hide xl-tui:pt-6 px-3 lg-tui:px-12 pb-4 lg-tui:pb-5',
    panelContent: 'mb-4 flex flex-wrap gap-2 lg-tui:mb-5'
  };

  const anyRefinableInTab = (tabName: string) => {
    return tabs[tabName].some(topicItem => {
      const topic = data.find(item =>
        item.children.some(child => child.value === topicItem.toLowerCase())
      );

      return topic && topic.children.some(child => child.canRefine);
    });
  };

  const handleTabChange = useCallback(
    (index: number) => {
      setTabIndex(index);
      const currentTab = data[index];
      if (currentTab) {
        handleTabClick(currentTab.tabName);
      }
    },
    [data, setTabIndex]
  );

  const handleOnChange = useCallback(
    (keyTopic: string, topic: string) => {
      let newCheckedTopics = { ...localCheckedTopicState };

      if (!localCheckedTopicState?.[keyTopic]?.includes(topic)) {
        if (!localCheckedTopicState[keyTopic]) {
          newCheckedTopics = { ...localCheckedTopicState, [keyTopic]: [topic] };
        } else {
          newCheckedTopics = {
            ...localCheckedTopicState,
            [keyTopic]: [...localCheckedTopicState[keyTopic], topic]
          };
        }
      } else {
        newCheckedTopics = {
          ...localCheckedTopicState,
          [keyTopic]: localCheckedTopicState?.[keyTopic]?.filter(
            (checkedTopic: string) => checkedTopic !== topic
          )
        };
      }

      // Check if all items are selected, and set the selectAllStatus accordingly
      const allSelected = tabs[keyTopic].every(t =>
        newCheckedTopics[keyTopic]?.includes(t.toLowerCase())
      );
      setSelectAllStatus({ ...selectAllStatus, [keyTopic]: allSelected });

      setLocalCheckedTopicState(newCheckedTopics);
    },
    [
      localCheckedTopicState,
      selectAllStatus,
      setLocalCheckedTopicState,
      setSelectAllStatus,
      tabs
    ]
  );

  const handleSelectAll = useCallback(
    (keyTopic: string) => {
      const selectAll = !selectAllStatus[keyTopic];

      setSelectAllStatus({ ...selectAllStatus, [keyTopic]: selectAll });
      setCategoryContext(keyTopic);

      trackClick({
        eventName: 'select_all_topics',
        ...(selectAll
          ? {
              additionalEvents: {
                category: keyTopic,
                topics: tabs[keyTopic].map(child => child.toLowerCase())
              }
            }
          : {})
      });

      if (selectAll) {
        setLocalCheckedTopicState({
          ...localCheckedTopicState,
          [keyTopic]: tabs[keyTopic].map(child => child.toLowerCase())
        });
      } else {
        setLocalCheckedTopicState({
          ...localCheckedTopicState,
          [keyTopic]: []
        });
      }
    },
    [
      localCheckedTopicState,
      selectAllStatus,
      setCategoryContext,
      setLocalCheckedTopicState,
      setSelectAllStatus,
      tabs,
      trackClick
    ]
  );

  const handleTabClick = useCallback(
    (tabName: string) => {
      const splitTabName = splitAmpersandTopics([tabName]).map(topic =>
        topic.toLowerCase()
      );
      const allCurrentTopics = Object.values(checkedTopicState).flat();
      const allTopics = [...allCurrentTopics, ...splitTabName];
      setCategoryContext(tabName);

      trackClick({
        eventName: 'topic_modal_category_selection',
        additionalEvents: {
          category: tabName,
          topics: allTopics
        }
      });
    },
    [checkedTopicState, setCategoryContext, trackClick]
  );

  useEffect(() => {
    const newSelectAllStatus = { ...selectAllStatus };

    data.forEach(({ tabName }) => {
      const allTopicsInTab = tabs[tabName].map(t => t.toLowerCase());
      const checkedTopicsInTab = checkedTopicState?.[tabName] || [];

      const allSelected = allTopicsInTab.every(topic =>
        checkedTopicsInTab.includes(topic)
      );
      newSelectAllStatus[tabName] = allSelected;
    });

    setSelectAllStatus(newSelectAllStatus);
  }, [checkedTopicState, data]);

  useEffect(() => {
    if (Object.keys(localCheckedTopicState).length > 0) {
      setCheckedTopicState(localCheckedTopicState);
    }
  }, []);

  useEffect(() => {
    const uniqueTopicsSet = new Set<string>();

    if (Object.keys(localCheckedTopicState).length === 0) {
      setLocalSelectedTopicsCount(0);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(localCheckedTopicState).forEach(([_, topics]) => {
      if (!topics) {
        return;
      }

      (topics as string[]).forEach(topic => {
        uniqueTopicsSet.add(topic);
      });
    });

    setLocalSelectedTopicsCount(uniqueTopicsSet.size);
  }, [localCheckedTopicState]);

  return (
    <Tabs index={tabIndex} onChange={handleTabChange}>
      <div className="w-full overflow-hidden">
        <div className={classNames.listContainer} ref={scrollAreaRef}>
          <TabList ref={tabList} className={classNames.list}>
            {data.map(({ tabName }, index) => {
              const checkedCount = countCheckedTopicsForTab(
                tabName,
                localCheckedTopicState
              );

              return (
                <Tab key={tabName} className={classNames.tab} index={index}>
                  <div className={classNames.tabText}>
                    <div className="whitespace-nowrap">{tabName}</div>
                    {checkedCount ? (
                      <div className={classNames.tabCount}>
                        <Text variant="label" tag="span">
                          {checkedCount}
                        </Text>
                      </div>
                    ) : null}
                  </div>
                </Tab>
              );
            })}
          </TabList>
          <ScrollArrows
            key={Date.now()}
            showArrows
            scrollAreaRef={scrollAreaRef}
            scrollMode="step"
          />
        </div>
      </div>

      <TabPanels>
        {data.map(({ children, tabName }, index) => {
          return (
            <TabPanel key={tabName} className={classNames.panel} index={index}>
              <div className={classNames.panelContent}>
                {children.map(({ value, displayLabel, canRefine }) => (
                  <div key={value}>
                    <Chip
                      text={displayLabel}
                      id={value}
                      onChange={() => handleOnChange(tabName, value)}
                      checked={localCheckedTopicState?.[tabName]?.includes(
                        value
                      )}
                      disabled={!canRefine}
                      aria-disabled={!canRefine}
                    />
                  </div>
                ))}
              </div>

              <Chip
                text="Select All"
                id={`${tabName}-select-all`}
                onChange={() => handleSelectAll(tabName)}
                checked={selectAllStatus?.[tabName]}
                disabled={!anyRefinableInTab(tabName)}
              />
            </TabPanel>
          );
        })}
      </TabPanels>
    </Tabs>
  );
}
