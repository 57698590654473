import cx from 'classnames';

import ContainerProps from './Container.props';

const Container = ({
  children,
  className = '',
  id,
  testId
}: ContainerProps) => {
  const containerClassName = cx(
    'container max-w-full 2xl-tui:max-w-tui-content',
    {
      [className]: className
    }
  );

  return (
    <div className={containerClassName} data-testid={testId} id={id}>
      {children}
    </div>
  );
};

export default Container;
