import { useEffect, useState } from 'react';

import { Modal, Text } from 'components/@tedui';
import { ModalType } from 'components/@tedui/Modal/Modal.props';
import Divider from 'components/divider';

import { SubtitleGroup } from './SubtitleGroup';
import type { SubtitleModalProps } from './SubtitleModal.props';

/**
 * SubtitleModal provides an interface for selecting subtitle languages.
 * It fetches available languages and allows users to choose their preferred language for subtitles.
 * @param isOpen - A boolean to control the modal visibility.
 * @param close - A function to close the modal.
 * @param onSelect - A function to refine the search results by the selected language.
 * @param languages - A list of languages to display in the modal.
 * @returns The SubtitleModal component with language selection options.
 */
export function SubtitleModal({
  isOpen,
  close,
  onSelect,
  languages
}: SubtitleModalProps): React.ReactNode {
  const [subtitleLanguges, setSubtitleLanguages] = useState([]);

  useEffect(() => {
    const transformedItems = languages.map(item => ({
      ...item,
      label: item.label.charAt(0).toUpperCase() + item.label.slice(1)
    }));
    const sortedItems = transformedItems.sort((a, b) =>
      a.label > b.label ? 1 : -1
    );

    const groupedItems = sortedItems.reduce((acc, item) => {
      const firstLetter = item.label[0].toUpperCase();
      acc[firstLetter] = acc[firstLetter] || { items: [] };
      acc[firstLetter].items.push({ name: item.label, slug: item.value });
      return acc;
    }, {});

    const alphabetizedLangList = Object.keys(groupedItems)
      .sort()
      .map(letter => ({
        letter,
        items: groupedItems[letter].items
      }));

    setSubtitleLanguages(alphabetizedLangList);
  }, [languages]);

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={close}
      type={ModalType.Large}
      addPadding
      stickyCloseButton
    >
      <div className="flex flex-col">
        <section className="sticky top-0 mb-4 flex-col bg-white md-tui:pt-6">
          <Text tag="p" variant="header3" isBold>
            Subtitle language
          </Text>
          <div className="pt-3 md-tui:pt-6">
            <Divider />
          </div>
        </section>
        <div
          className="no-scrollbar sticky top-0 max-h-3/5-screen flex-col overflow-y-auto pb-4"
          tabIndex={-1}
        >
          {subtitleLanguges.map((group, index) => {
            const isLastGroup = index === subtitleLanguges?.length - 1;
            return (
              <SubtitleGroup
                key={group.letter}
                data={group}
                isLastGroup={isLastGroup}
                onSelect={onSelect}
              />
            );
          })}
        </div>
      </div>
    </Modal>
  );
}
