type AddToListIconProps = {
  className?: string;
  /** Optional: Appends 'data-testid' attribute used for testing purposes. */
  testId?: string;
  added?: boolean;
};

export const AddToListIcon = ({
  className,
  testId,
  added
}: AddToListIconProps) => {
  return added ? (
    <svg
      className={className}
      data-testid={testId}
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.613525 4.36383C0.613525 3.94962 0.949312 3.61383 1.36353 3.61383H15.909C16.3232 3.61383 16.659 3.94962 16.659 4.36383C16.659 4.77804 16.3232 5.11383 15.909 5.11383H1.36353C0.949312 5.11383 0.613525 4.77804 0.613525 4.36383Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.613525 10.5457C0.613525 10.1314 0.949312 9.79565 1.36353 9.79565H15.909C16.3232 9.79565 16.659 10.1314 16.659 10.5457C16.659 10.9599 16.3232 11.2957 15.909 11.2957H1.36353C0.949312 11.2957 0.613525 10.9599 0.613525 10.5457Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4967 13.4383C25.8071 13.7126 25.8363 14.1866 25.5619 14.4969L19.3744 21.4969C19.2321 21.658 19.0275 21.7502 18.8125 21.7502C18.5976 21.7502 18.3929 21.658 18.2506 21.4969L15.4381 18.3151C15.1637 18.0047 15.1929 17.5308 15.5033 17.2564C15.8136 16.9821 16.2876 17.0113 16.5619 17.3217L18.8125 19.8677L24.4381 13.5035C24.7124 13.1931 25.1864 13.1639 25.4967 13.4383Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2046 16.3638C11.2046 16.778 10.8688 17.1138 10.4546 17.1138H1.36368C0.949467 17.1138 0.613681 16.778 0.613681 16.3638C0.613681 15.9496 0.949467 15.6138 1.36368 15.6138H10.4546C10.8688 15.6138 11.2046 15.9496 11.2046 16.3638Z"
        fill="#121212"
      />
    </svg>
  ) : (
    <svg
      className={className}
      data-testid={testId}
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.479492 3.27271C0.479492 2.96204 0.731332 2.71021 1.04199 2.71021H11.9511C12.2617 2.71021 12.5136 2.96204 12.5136 3.27271C12.5136 3.58337 12.2617 3.83521 11.9511 3.83521H1.04199C0.731332 3.83521 0.479492 3.58337 0.479492 3.27271Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.479492 7.90912C0.479492 7.59846 0.731332 7.34662 1.04199 7.34662H11.9511C12.2617 7.34662 12.5136 7.59846 12.5136 7.90912C12.5136 8.21978 12.2617 8.47162 11.9511 8.47162H1.04199C0.731332 8.47162 0.479492 8.21978 0.479492 7.90912Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2921 8.43738C15.6027 8.43738 15.8546 8.68922 15.8546 8.99988V15.8181C15.8546 16.1287 15.6027 16.3806 15.2921 16.3806C14.9814 16.3806 14.7296 16.1287 14.7296 15.8181V8.99988C14.7296 8.68922 14.9814 8.43738 15.2921 8.43738Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3319 12.2726C19.3319 12.5833 19.08 12.8351 18.7694 12.8351H11.9512C11.6405 12.8351 11.3887 12.5833 11.3887 12.2726C11.3887 11.9619 11.6405 11.7101 11.9512 11.7101H18.7694C19.08 11.7101 19.3319 11.9619 19.3319 12.2726Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.42285 12.2727C8.42285 12.5834 8.17101 12.8352 7.86035 12.8352H1.04217C0.731509 12.8352 0.47967 12.5834 0.47967 12.2727C0.47967 11.962 0.731509 11.7102 1.04217 11.7102H7.86035C8.17101 11.7102 8.42285 11.962 8.42285 12.2727Z"
        fill="#121212"
      />
    </svg>
  );
};
