export enum ModalType {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE'
}

type ModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  type?: ModalType;
  stickyCloseButton?: boolean;
  addPadding?: boolean;
  isTall?: boolean;
  hasMaxWidth?: boolean;
  showCloseButton?: boolean;
  testId?: string;
  isScrollable?: boolean;
  children?: React.ReactNode;
};

export default ModalProps;
