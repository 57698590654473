import { useCallback } from 'react';
import Text from '../../Text/Text';
import { RadioButtonProps } from './RadioButton.props';

function RadioButton({
  name,
  option,
  onChange,
  selected = false,
  testId
}: RadioButtonProps): React.ReactNode {
  const handleChange = useCallback(
    e => {
      e.stopPropagation();
      const selectedOption = e.target.value;
      onChange(selectedOption);
    },
    [onChange]
  );
  return (
    <div
      data-testid={testId}
      className="flex items-center"
      onClick={handleChange}
    >
      <input
        type="radio"
        name={name}
        className="mr-2 h-3 w-3 accent-textPrimary-onLight"
        value={option}
        checked={selected}
        onChange={handleChange}
        data-testid={`${testId}_input`}
      />
      <Text
        testId={`${testId}_label`}
        variant="body2"
        UNSAFE_className="cursor-pointer text-tui-sm text-black"
      >
        {option}
      </Text>
    </div>
  );
}

export default RadioButton;
