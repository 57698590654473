type SendIconProps = {
  className?: string;
  /** Optional: Appends 'data-testid' attribute used for testing purposes. */
  testId?: string;
};

export const SendIcon = ({ className, testId }: SendIconProps) => {
  return (
    <svg
      className={className}
      data-test-id={testId}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6673 1.10227C17.887 1.32194 17.887 1.67809 17.6673 1.89776L9.41728 10.1478C9.19761 10.3674 8.84146 10.3674 8.62179 10.1478C8.40212 9.92809 8.40212 9.57194 8.62179 9.35227L16.8718 1.10227C17.0915 0.882598 17.4476 0.882598 17.6673 1.10227Z"
        fill="#121212"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6673 1.10227C17.8201 1.25506 17.8718 1.48188 17.8005 1.68584L12.5505 16.6858C12.4743 16.9035 12.2729 17.0526 12.0425 17.062C11.812 17.0714 11.5992 16.9392 11.5055 16.7285L8.59338 10.1762L2.04108 7.26403C1.83032 7.17036 1.6981 6.95753 1.7075 6.72709C1.7169 6.49664 1.86602 6.29529 2.08371 6.21909L17.0837 0.969095C17.2877 0.897711 17.5145 0.949472 17.6673 1.10227ZM3.79451 6.81223L9.24799 9.236C9.37524 9.29255 9.477 9.39431 9.53355 9.52156L11.9573 14.975L16.3527 2.41687L3.79451 6.81223Z"
        fill="#121212"
      />
    </svg>
  );
};
