import { Button } from 'components/@tedui';

import type { FooterProps } from './Footer.props';

export function Footer({
  selectedTopicsCount,
  reset,
  showResults
}: FooterProps): React.ReactNode {
  return (
    <footer className="flex flex-col items-stretch justify-between gap-2 border-t-thin border-black/16 bg-gray-50 px-3 py-4 text-center sm-tui:flex-row sm-tui:items-center lg-tui:px-12">
      <span>{selectedTopicsCount} topics selected</span>
      <div className="flex justify-center gap-5">
        <div className="min-w-[12rem]">
          <Button text="Reset" variant="tertiary" onClick={reset} />
        </div>

        <Button text="Show results" isFullWidth={false} onClick={showResults} />
      </div>
    </footer>
  );
}
