import { DivideProps } from '../Grid.props';
import type { GenerateClasses } from './utils.props';

export const generateClasses = ({
  breakpoint,
  classPrefix,
  value
}: GenerateClasses): string =>
  value ? `${breakpoint}-tui:${classPrefix}-${value} ` : '';

export function renderClasses({
  prop,
  classPrefix,
  nestedProp
}: {
  prop?: Record<string, unknown>;
  classPrefix: string;
  nestedProp?: string;
}): string {
  return Object.entries(prop || {}).reduce((acc, [key, val]) => {
    const value = nestedProp
      ? (val as Record<string, unknown>)[nestedProp]
      : val;
    return acc + generateClasses({ breakpoint: key, classPrefix, value });
  }, '');
}

export const renderDivideClasses = (divideProp?: {
  [key: string]: DivideProps;
}): string => {
  if (!divideProp) return '';

  return Object.entries(divideProp).reduce((acc, [breakpoint, divide]) => {
    const xClass = divide.x ? `${breakpoint}-tui:divide-x-${divide.x} ` : '';
    const yClass = divide.y ? `${breakpoint}-tui:divide-y-${divide.y} ` : '';
    const bothClass = divide.both
      ? `${breakpoint}-tui:divide-${divide.both} `
      : '';
    return acc + xClass + yClass + bothClass;
  }, '');
};
