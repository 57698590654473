import create from 'zustand';

import { findKeyTopics } from './utils';

export const [useModalStore] = create(set => ({
  isModalOpen: false,
  checkedTopicState: {},
  localCheckedTopicState: {},
  selectAllStatus: {},
  selectedTopicsCount: 0,
  localSelectedTopicsCount: 0,
  tabIndex: 0,

  openModal: () => set({ isModalOpen: true }),
  closeModal: () => set({ isModalOpen: false }),
  setCheckedTopicState: state => set({ checkedTopicState: state }),
  setSelectAllStatus: status => set({ selectAllStatus: status }),
  setSelectedTopicsCount: count => set({ selectedTopicsCount: count }),
  setTabIndex: index => set({ tabIndex: index }),
  discardLocalModalState: () =>
    set({ localCheckedTopicState: {}, localSelectedTopicsCount: 0 }),
  setLocalCheckedTopicState: state => set({ localCheckedTopicState: state }),
  setLocalSelectedTopicsCount: count =>
    set({ localSelectedTopicsCount: count }),

  resetSelectedTopics: () =>
    set({ checkedTopicState: {}, selectAllStatus: {} }),

  addSelectedTopic: topicValue => {
    const keyTopics = findKeyTopics(topicValue);

    keyTopics.forEach(topic => {
      set(prevState => {
        const currentTopicValuesSet = new Set(
          prevState.checkedTopicState[topic] || []
        );

        currentTopicValuesSet.add(topicValue);

        return {
          checkedTopicState: {
            ...prevState.checkedTopicState,
            [topic]: [...currentTopicValuesSet]
          }
        };
      });
    });
  },

  removeSelectedTopic: topicValue => {
    const keyTopics = findKeyTopics(topicValue);

    keyTopics.forEach(keyTopic => {
      set(prevState => {
        return {
          checkedTopicState: {
            ...prevState.checkedTopicState,
            [keyTopic]: prevState.checkedTopicState[keyTopic]?.filter(
              t => t !== topicValue
            )
          }
        };
      });
    });
  },

  transferLocalStateToCheckedState: () =>
    set(state => {
      return {
        checkedTopicState: {
          ...state.checkedTopicState,
          ...state.localCheckedTopicState
        },
        localModalState: {}
      };
    })
}));
