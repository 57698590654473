import crush from '@tedconf/js-crushinator-helpers';
// eslint-disable-next-line no-restricted-imports
import type { ImageLoaderProps } from 'next/image';

const validateCrushinatorSupport = (src: string) => !src.endsWith('.svg');

// Default dimensions to use when not provided
const DEFAULT_WIDTH = 960;
const DEFAULT_QUALITY = 80;

export default function crushLoader({ src, width, quality }: ImageLoaderProps) {
  const imageWidth = width || DEFAULT_WIDTH;
  const imageQuality = quality || DEFAULT_QUALITY;

  // Remove any existing query parameters for a clean base URL.
  const baseSrc = src.split('?')[0];

  // Construct new query parameters including width and quality.
  const queryParams = new URLSearchParams();
  queryParams.set('w', String(imageWidth));
  queryParams.set('q', String(imageQuality));
  const updatedSrc = `${baseSrc}?${queryParams.toString()}`;

  // If the image is supported, use the crush helper; otherwise, return the updated URL.
  return validateCrushinatorSupport(src)
    ? crush(baseSrc, { width: imageWidth, quality: imageQuality })
    : updatedSrc;
}
