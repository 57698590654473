import cx from 'classnames';
import { kebabCase } from 'lodash-es';
import { useRouter } from 'next/router';

import { useEffect, useRef, useState } from 'react';
import { useOnWindowResize } from 'rooks';

import { useDarkMode } from 'components/theme/DarkModeProvider';
import Tab from './Tab';
import TabsProps from './Tabs.props';

const Tabs = ({ testId = 'tabs', tabs, className }: TabsProps) => {
  const router = useRouter();
  const activeLink = useRef<HTMLAnchorElement>(null);
  const tabContainer = useRef<HTMLDivElement>(null);
  const [hasLeftOverflow, setHasLeftOverflow] = useState(false);
  const [hasRightOverflow, setHasRightOverflow] = useState(false);

  const { isDarkMode } = useDarkMode();

  const setOverflowValues = () => {
    const { scrollWidth, offsetWidth, scrollLeft } =
      tabContainer.current as HTMLDivElement;
    const hasOverflow = scrollWidth > offsetWidth;

    if (!hasOverflow) {
      setHasLeftOverflow(false);
      setHasRightOverflow(false);
      return;
    }

    setHasLeftOverflow(scrollLeft > 0);
    setHasRightOverflow(scrollLeft < scrollWidth - offsetWidth);
  };

  useOnWindowResize(setOverflowValues, true, true);

  const handleScrollTo = () => {
    if (
      tabContainer.current &&
      activeLink.current &&
      typeof tabContainer.current.scrollTo === 'function'
    ) {
      tabContainer.current.scrollTo({
        left:
          activeLink.current.offsetLeft -
          (tabContainer.current.offsetWidth - activeLink.current.offsetWidth) /
            2
      });

      setOverflowValues();
    }
  };

  useEffect(() => {
    handleScrollTo();

    document.fonts.ready.then(() => {
      setOverflowValues();
    });
  }, []);

  const tabListClasses = cx(
    'flex w-full touch-pan-x gap-6 overflow-scroll border-b-thin border-white pb-8 pr-6 scrollbar-hide',
    className
  );

  const fadeContainerClasses = cx(
    'pointer-events-none absolute top-0 h-6 w-10 to-transparent transition-all',
    isDarkMode ? 'from-black' : 'from-white'
  );

  return (
    <div
      ref={tabContainer}
      onScroll={() => setOverflowValues()}
      className={tabListClasses}
      data-testid={testId}
    >
      <div
        className={cx(
          fadeContainerClasses,
          'bg-gradient-to-r',
          hasLeftOverflow ? 'left-0' : '-left-10'
        )}
      />
      <div
        className={cx(
          fadeContainerClasses,
          'bg-gradient-to-l',
          hasRightOverflow ? 'right-0' : 'right-[-1px]'
        )}
      />
      {tabs.map(({ link, onClick, text, isActive, badge }) => (
        <Tab
          ref={
            !!router.pathname.includes(link) || !!isActive
              ? activeLink
              : undefined
          }
          key={link}
          isActive={router.pathname.includes(link) || isActive}
          onClick={onClick}
          link={link}
          text={text}
          testId={`${testId}-${
            typeof text === 'string'
              ? kebabCase(text)
              : kebabCase(text?.props?.defaultMessage[0]?.value)
          }-tab`}
          badge={badge}
        />
      ))}
    </div>
  );
};

export default Tabs;
