import { PhotoSize } from 'api';

/**
 * The getImageFromSet function has multiple return types based on the value of the 'returnSet' argument.
 * If 'returnSet' is true, the function returns an object of type PhotoSize.
 * If 'returnSet' is false or undefined, the function returns a string (URL of the image).
 * To handle these different return types, we use function overloads in TypeScript.
 * Function overloads allow us to define multiple type signatures for a single function.
 * @param imageSets - An array of image sets (PhotoSize objects).
 * @param ratio - The desired aspect ratio.
 * @param returnSet - If true, return the entire image set; if false, return the image URL.
 * @returns The image URL (string) if 'returnSet' is false; the image set (PhotoSize) if 'returnSet' is true.
 * @see {@link https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads|TypeScript Function Overloads}
 */

function getImageFromSet(
  imageSets: PhotoSize[],
  ratio: string,
  returnSet: true
): PhotoSize | null;

function getImageFromSet(
  imageSets: PhotoSize[],
  ratio: string,
  returnSet?: false
): string | null;

function getImageFromSet(
  imageSets: PhotoSize[],
  ratio: string,
  returnSet = false
): string | PhotoSize | null {
  if (imageSets && imageSets.length) {
    // Get set with the exact ratio
    const imageSet = imageSets.filter(
      imageSet =>
        imageSet.aspectRatioName === ratio ||
        imageSet.aspectRatio?.name === ratio
    );

    if (imageSet.length && imageSet[0].url) {
      if (returnSet) {
        return imageSet[0];
      }

      return imageSet[0].url;
    }

    // Fallback set
    const fallbackImageSet = imageSets[0];

    if (fallbackImageSet.url) {
      if (returnSet) {
        return fallbackImageSet;
      }

      return fallbackImageSet.url;
    }
  }

  return null;
}

export default getImageFromSet;
