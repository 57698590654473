type HeartIconProps = {
  className?: string;
  /** Optional: Appends 'data-testid' attribute used for testing purposes. */
  testId?: string;
  filled?: boolean;
};

export const HeartIcon = ({ className, testId, filled }: HeartIconProps) => {
  return filled ? (
    <svg
      className={className}
      data-testid={testId}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5575 2.72439C15.316 2.41011 16.129 2.24835 16.95 2.24835C17.771 2.24835 18.584 2.41011 19.3424 2.72439C20.1009 3.03866 20.79 3.4993 21.3705 4.07997C21.951 4.66033 22.4116 5.34936 22.7258 6.10773C23.0401 6.8662 23.2018 7.67917 23.2018 8.50018C23.2018 9.32119 23.0401 10.1342 22.7258 10.8926C22.4115 11.651 21.9509 12.3401 21.3703 12.9205L12.5303 21.7605C12.2374 22.0534 11.7626 22.0534 11.4697 21.7605L2.62967 12.9205C1.45732 11.7482 0.798706 10.1581 0.798706 8.50018C0.798706 6.84223 1.45732 5.25219 2.62967 4.07985C3.80201 2.9075 5.39205 2.24889 7.05 2.24889C8.70794 2.24889 10.298 2.9075 11.4703 4.07985L12 4.60952L12.5295 4.07997C13.1099 3.4993 13.7991 3.03866 14.5575 2.72439Z"
        fill="#121212"
      />
    </svg>
  ) : (
    <svg
      className={className}
      data-testid={testId}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6157_60434)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6873 2.04318C12.2562 1.80748 12.8659 1.68616 13.4816 1.68616C14.0974 1.68616 14.7071 1.80748 15.276 2.04318C15.8448 2.27889 16.3617 2.62436 16.797 3.05987C17.2324 3.49514 17.5778 4.01191 17.8135 4.58069C18.0492 5.14955 18.1705 5.75927 18.1705 6.37503C18.1705 6.99078 18.0492 7.60051 17.8135 8.16936C17.5778 8.73818 17.2323 9.25499 16.7969 9.69027C16.7968 9.69031 16.7969 9.69024 16.7969 9.69027L10.1669 16.3203C9.94721 16.5399 9.59105 16.5399 9.37139 16.3203L2.74138 9.69027C1.86213 8.81102 1.36816 7.61848 1.36816 6.37503C1.36816 5.13157 1.86213 3.93904 2.74138 3.05978C3.62064 2.18052 4.81317 1.68656 6.05663 1.68656C7.30009 1.68656 8.49262 2.18052 9.37188 3.05978L9.76913 3.45703L10.1663 3.05987C10.6016 2.62436 11.1184 2.27889 11.6873 2.04318ZM16.0013 3.85518C15.6705 3.52419 15.2777 3.26163 14.8453 3.0825C14.413 2.90336 13.9496 2.81116 13.4816 2.81116C13.0137 2.81116 12.5503 2.90336 12.1179 3.0825C11.6856 3.26163 11.2928 3.52419 10.962 3.85518L10.1669 4.65027C9.94721 4.86994 9.59105 4.86994 9.37139 4.65027L8.57638 3.85527C7.9081 3.18699 7.00172 2.81156 6.05663 2.81156C5.11154 2.81156 4.20516 3.18699 3.53688 3.85527C2.8686 4.52355 2.49316 5.42994 2.49316 6.37503C2.49316 7.32012 2.8686 8.2265 3.53688 8.89478L9.76913 15.127L16.0014 8.89478C16.3324 8.56395 16.595 8.17105 16.7742 7.73872C16.9533 7.30639 17.0455 6.843 17.0455 6.37503C17.0455 5.90705 16.9533 5.44366 16.7742 5.01133C16.595 4.579 16.3323 4.18601 16.0013 3.85518Z"
          fill="#121212"
        />
      </g>
      <defs>
        <clipPath id="clip0_6157_60434">
          <rect
            width="18"
            height="18"
            fill={'white'}
            transform="translate(0.769531)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
