import { MIXPANEL_PRODUCT } from 'lib/analytics/constants';
import mixpanel from 'lib/analytics/mixpanel';

export type HOMEPAGE_TALK_CARD_EVENTS =
  | 'talk_thumbnail'
  | 'talk_play_icon'
  | 'talk_button'
  | 'talk_title';

const sendHomepageTalkCardEvents = (
  context: HOMEPAGE_TALK_CARD_EVENTS,
  autoplayState?: boolean,
  prefix?: string,
  link?: string
): void => {
  mixpanel.track('homepage_click', {
    product: MIXPANEL_PRODUCT,
    component: 'Card Media Component',
    event_interaction_context: `${prefix || ''}${context}`,
    autoplay_state: autoplayState,
    ...(link && { outbound_url: link })
  });
};

export { sendHomepageTalkCardEvents };
