import cx from 'classnames';

import { Button, Icon, Text } from 'components/@tedui';
import { Link } from 'components/router';

import { useDarkMode } from 'components/theme/DarkModeProvider';
import type TitleBarProps from './TitleBar.props';

const TitleBar = ({
  title = { label: '', variant: 'header4', isBold: false },
  iconName: icon,
  iconSize = 'medium',
  secondaryAction,
  addMargin = true
}: TitleBarProps): React.ReactNode => {
  const { isDarkMode } = useDarkMode();
  const iconClasses = cx({
    'text-tui-3xl': iconSize === 'large',
    'text-tui-lg': iconSize === 'medium',
    'text-tui-base': iconSize === 'small',
    'text-white': isDarkMode
  });

  return (
    <div
      data-testid="Ribbon Title Bar"
      className={cx('flex items-end justify-between', {
        'mb-2 lg-tui:mb-4': addMargin
      })}
    >
      <div className="flex items-center gap-2">
        {icon && (
          <Icon
            iconName={icon}
            testId={`ribbon-${icon}`}
            className={iconClasses}
          />
        )}
        {title.label && (
          <Text
            variant={title.variant}
            tag="h4"
            isBold={title.isBold}
            color={{ isOnLightSurface: !isDarkMode }}
          >
            {title.label}
          </Text>
        )}
      </div>

      {secondaryAction && (
        <Link href={secondaryAction.link} isZenithLink>
          <Button
            text={secondaryAction.text}
            onClick={secondaryAction.onClick}
            variant="inline"
            testId={secondaryAction.testId}
            isDarkBackground={isDarkMode}
            iconOptions={{
              iconName: 'chevron-right',
              iconPosition: 'trailing'
            }}
            textProps={{
              color: { isOnLightSurface: !isDarkMode },
              isBold: false,
              useNewTextStyles: true,
              weight: 'font-normal'
            }}
          />
        </Link>
      )}
    </div>
  );
};

export default TitleBar;
