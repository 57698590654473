import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Icon, Text } from 'components/@tedui';

import CardDatePanelProps from './CardDatePanel.props';

const CardDatePanel = ({
  date,
  callToAction = { iconName: '', actionText: '', link: {} },
  testId
}: CardDatePanelProps) => {
  const { formatDate, formatTime, formatDateToParts } = useIntl();
  const { start, end } = date;
  const { iconName, actionText, link } = callToAction;

  const formattedMonth = useMemo(() => {
    const startDate = new Date(start);

    if (startDate.getMonth() === 8) {
      return 'Sept';
    }

    return formatDate(startDate, { month: 'short' });
  }, [formatDate, start]);

  const timeZone = formatDateToParts(new Date(start), {
    timeZoneName: 'short'
  }).find(part => part.type === 'timeZoneName')?.value;

  return (
    <div
      className="flex max-w-[80px] flex-col justify-between bg-black p-2 xl-tui:max-w-[96px]"
      data-testid={testId}
    >
      <div className="flex flex-col">
        {/* Month */}
        <Text
          variant="body1"
          tag="p"
          testId="datePanel--month"
          color={{ color: 'primary', isOnLightSurface: false }}
        >
          {formattedMonth}
        </Text>
        {/* Day */}
        <Text
          variant="display"
          tag="h4"
          testId="datePanel--day"
          color={{ color: 'primary', isOnLightSurface: false }}
        >
          {formatDate(new Date(start), { day: '2-digit' })}
        </Text>
        {/* Time */}
        <div className="border-t-thin border-outline-onDark pt-1">
          <Text
            variant="label"
            isBold
            tag="p"
            testId="datePanel--time"
            color={{ color: 'primary', isOnLightSurface: false }}
          >
            <span>
              {formatTime(new Date(start), {
                hour: 'numeric',
                hour12: true
              })
                .replace(/pm/gi, '')
                .trim()}
            </span>
            <span className="mx-05">-</span>
            <span>
              {formatTime(new Date(end), {
                hour: 'numeric',
                formatMatcher: 'basic',
                hour12: true
              })}
            </span>
            <span> {timeZone}</span>
          </Text>
        </div>
      </div>
      {callToAction && (
        <a className="flex items-center" data-testid="datePanel--cta" {...link}>
          {iconName && <Icon iconName={iconName} className="mr-1 text-white" />}
          <Text
            variant="body2"
            isBold
            color={{ color: 'primary', isOnLightSurface: false }}
          >
            <span className="underline">{actionText}</span>
          </Text>
        </a>
      )}
    </div>
  );
};

export default CardDatePanel;
