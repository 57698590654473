import cx from 'classnames';
import { useEffect, useState } from 'react';

import { Button } from 'components/@tedui';
import { client } from 'f';

import type { FloatingActionButtonProps } from './FloatingActionButton.props';

const FloatingActionButton = ({
  layout = 'default',
  onClick
}: FloatingActionButtonProps): React.ReactNode => {
  const [isPageScrolled, setIsPageScrolled] = useState(false);

  const handleClick = () => {
    if (client) window.scrollTo({ top: 0, behavior: 'smooth' });

    if (onClick) onClick();
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 150;

      if (window.scrollY > scrollThreshold && !isPageScrolled) {
        setIsPageScrolled(true);
      } else if (window.scrollY <= scrollThreshold && isPageScrolled) {
        setIsPageScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isPageScrolled]);

  const classNames = {
    container: cx(
      'z-50 ml-auto flex h-12 w-12 flex-col justify-center rounded-sm border-thin border-black border-opacity-16 bg-white shadow-lg'
    ),
    containerFixed: cx('fixed bottom-12 right-4', {
      'md-tui:right-12 lg-tui:right-10 xl-tui:right-16': layout === 'condensed',
      'md-tui:right-2 lg-tui:right-4 xl-tui:right-8': layout === 'default'
    })
  };

  if (!isPageScrolled) return null;

  return (
    <div
      data-testid="Floating Action Button Container"
      className={cx(classNames.container, classNames.containerFixed)}
    >
      <Button
        type="button"
        iconOptions={{
          iconName: 'arrow-up',
          iconSize: 'large'
        }}
        onClick={handleClick}
        variant="inline"
        testId="Floating Action Button"
      />
    </div>
  );
};

export default FloatingActionButton;
