import { createContext, useContext } from 'react';

import { useModalStore } from './store';

type TopicsModalContextType = {
  openModal: () => void;
  closeModal: () => void;
};

const TopicsModalContext = createContext<TopicsModalContextType>({
  openModal: () => {},
  closeModal: () => {}
});

export const useTopicsModalContext = (): TopicsModalContextType => {
  const context = useContext(TopicsModalContext);
  if (!context) {
    throw new Error(
      'useTopicsModalContext must be used within a TopicsModalProvider'
    );
  }
  return context;
};

type TopicsModalProviderProps = {
  children: React.ReactNode;
};

export const TopicsModalProvider = ({
  children
}: TopicsModalProviderProps): React.ReactNode => {
  const { openModal, closeModal } = useModalStore();

  return (
    <TopicsModalContext value={{ openModal, closeModal }}>
      {children}
    </TopicsModalContext>
  );
};
