const LoadingAnimation = (): React.ReactNode => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="taukqcqopwqn873i7rnkftxh"
      viewBox="0 0 90 90"
    >
      <g
        transform="translate(45,45) rotate(60)"
        style={{
          animation: '1.67s linear infinite both lgyja9sk9d3e0od0pfrw6r79_t'
        }}
      >
        <g
          opacity=".4"
          style={{
            animation: '1.67s linear infinite both cnm6x9wdsba8hq466dl9i1ah_o'
          }}
        >
          <path
            fill="#757b80"
            d="M0 38.5c8.5 0 16-17.1 16-38.5c0-21.4-7.5-38.5-16-38.5c-8.5 0-16 17.1-16 38.5c0 21.4 7.5 38.5 16 38.5Zm0 1.5c-9.7 0-17.5-17.9-17.5-40c0-22.1 7.8-40 17.5-40c9.7 0 17.5 17.9 17.5 40c0 22.1-7.8 40-17.5 40Z"
          />
        </g>
      </g>
      <g
        transform="translate(45,45) rotate(120)"
        style={{
          animation: '1.67s linear infinite both l0n8hi5ae42n5ae0td70lfe9_t'
        }}
      >
        <g
          opacity=".4"
          style={{
            animation: '1.67s linear infinite both cmrryy6n5vzvcootss40x797_o'
          }}
        >
          <path
            fill="#ff352d"
            d="M0 38.5c8.5 0 16-17.1 16-38.5c0-21.4-7.5-38.5-16-38.5c-8.5 0-16 17.1-16 38.5c0 21.4 7.5 38.5 16 38.5Zm0 1.5c-9.7 0-17.5-17.9-17.5-40c0-22.1 7.8-40 17.5-40c9.7 0 17.5 17.9 17.5 40c0 22.1-7.8 40-17.5 40Z"
          />
        </g>
      </g>
      <g
        transform="translate(45,45)"
        style={{
          animation: '1.67s linear infinite both u7lccxe4lb9kafiiwa55wwtx_t'
        }}
      >
        <g
          opacity=".4"
          style={{
            animation: '1.67s linear infinite both lnkyqqiz5eh16xo1akoqp3i8_o'
          }}
        >
          <path
            fill="#c3cdd4"
            d="M0 38.5c8.5 0 16-17.1 16-38.5c0-21.4-7.5-38.5-16-38.5c-8.5 0-16 17.1-16 38.5c0 21.4 7.5 38.5 16 38.5Zm0 1.5c-9.7 0-17.5-17.9-17.5-40c0-22.1 7.8-40 17.5-40c9.7 0 17.5 17.9 17.5 40c0 22.1-7.8 40-17.5 40Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default LoadingAnimation;
