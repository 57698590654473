import cx from 'classnames';
import { isEmpty } from 'lodash-es';

import type CardProps from './Card.props';

import CardActionBar from './CardActionBar';
import CardDatePanel from './CardDatePanel';

const Card = ({
  ref,
  children,
  actionBar = null,
  datePanel = null,
  layout = 'vertical',
  testId,
  isNewCard = false,
  UNSAFE_className
}: CardProps & {
  ref?: React.RefObject<HTMLDivElement | null>;
}) => {
  const isDatePanelVisible =
    datePanel && !isEmpty(datePanel) && layout === 'horizontal' && !actionBar;

  const cardContainerClassName = cx('flex', {
    'flex-col': layout === 'vertical',
    'flex-row': layout === 'horizontal',
    'h-80 rounded-md bg-black md-tui:h-80': isNewCard
  });

  return (
    <div className={cardContainerClassName} ref={ref}>
      {isDatePanelVisible && (
        <CardDatePanel
          date={{ start: datePanel.date.start, end: datePanel.date.end }}
          callToAction={datePanel.callToAction}
          testId={datePanel.testId}
        />
      )}
      <div
        className={cx('flex w-full', cardContainerClassName, UNSAFE_className)}
        data-testid={testId}
      >
        {children}
      </div>
      {actionBar && !isEmpty(actionBar) && (
        <CardActionBar
          captionText={actionBar.captionText}
          callToAction={actionBar.callToAction}
        />
      )}
    </div>
  );
};

export default Card;
