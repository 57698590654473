import router, { NextRouter } from 'next/router';

export const splitAmpersandTopics = (topics: string[]): string[] => {
  return topics.flatMap(topic => topic.split(' & ').map(t => t.toLowerCase()));
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

function getCurrentSearchParams() {
  if (typeof window === 'undefined') return new URLSearchParams();
  return new URLSearchParams(window.location.search);
}

export function updateUrlWithParams(
  router: NextRouter,
  newParams: Record<string, string | number | null>
) {
  const currentParams = getCurrentSearchParams();

  // Update or add new params
  Object.entries(newParams).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      currentParams.delete(key);
    } else {
      currentParams.set(key, value.toString());
    }
  });

  // Remove empty params
  Array.from(currentParams.entries()).forEach(([key, value]) => {
    if (value === '') currentParams.delete(key);
  });

  const newUrl = `/talks?${currentParams.toString()}`;
  router.push(newUrl, undefined, { shallow: true });
}

export const waitForRouteChange = () => {
  return new Promise<void>(resolve => {
    const handleRouteChangeComplete = () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      resolve();
    };
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
  });
};
