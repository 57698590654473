import cx from 'classnames';
import { Link } from 'components/router';

import Text from 'components/@tedui/Text';
import { useDarkMode } from 'components/theme/DarkModeProvider';
import TabProps from './Tab.props';

const Tab = ({
  isActive = false,
  link,
  onClick,
  testId,
  text,
  badge,
  ref
}: TabProps & {
  ref?: React.RefObject<HTMLAnchorElement | null>;
}) => {
  const { isDarkMode } = useDarkMode();
  const tabClasses = cx('whitespace-nowrap pb-1', {
    'border-b-thin': isActive,
    'border-black': isActive && !isDarkMode,
    'border-white': isDarkMode && isActive,
    'flex items-center gap-x-1': !!badge
  });

  return (
    <Link
      href={link}
      ref={ref}
      tabIndex={0}
      className={tabClasses}
      onClick={onClick}
      data-testid={testId}
    >
      <Text
        variant="body2"
        color={{
          color: isActive ? 'primary' : 'tertiary',
          isOnLightSurface: !isDarkMode,
          override: isDarkMode && !isActive ? '#707070' : undefined
        }}
        testId={`${testId}-text`}
        UNSAFE_className={cx(
          isDarkMode
            ? 'hover:!text-textPrimary-onDark'
            : 'hover:!text-textPrimary-onLight'
        )}
      >
        {text}
      </Text>
      {badge && badge(isActive)}
    </Link>
  );
};

export default Tab;
