import { isValidElement, useCallback } from 'react';

import { useDropdownStore } from '../../store';

import type { DropdownItemProps } from '../../DropdownItem/DropdownItem.props';
import type {
  UseKeyboardEvents,
  UseKeyboardEventsReturn
} from './useKeyboardEvents.props';

/**
 * useKeyboardEvents is a custom hook that handles keyboard navigation within the Dropdown component.
 * It allows users to navigate through the list of options and select items using the keyboard.
 * @param isOpen - Indicates whether the dropdown menu is open.
 * @param highlightedIndex - The index of the currently highlighted option.
 * @param childrenArray - The array of child items within the dropdown.
 * @param handleSelectItem - The callback function called when an item is selected.
 * @param setHighlightedIndex - The function to set the highlightedIndex state of the dropdown.
 * @returns An object containing the handleKeyDown function.
 */
export const useKeyboardEvents = ({
  label,
  isOpen,
  highlightedIndex,
  childrenArray,
  handleSelectItem,
  setHighlightedIndex
}: UseKeyboardEvents): UseKeyboardEventsReturn => {
  const { toggleDropdown } = useDropdownStore();

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      const { key } = event;

      if (
        key === 'ArrowDown' ||
        key === 'ArrowUp' ||
        key === 'Enter' ||
        key === 'Escape'
      ) {
        event.preventDefault();
      }

      if (key === 'ArrowDown') {
        if (isOpen) {
          const nextIndex = (highlightedIndex + 1) % childrenArray.length;
          setHighlightedIndex(nextIndex);
        }

        if (!isOpen) {
          const nextIndex = 0;
          setHighlightedIndex(nextIndex);
          toggleDropdown(label);
        }
      }

      if (key === 'ArrowUp' && isOpen) {
        const prevIndex =
          (highlightedIndex - 1 + childrenArray.length) % childrenArray.length;
        setHighlightedIndex(prevIndex);
      }

      if (key === 'Enter') {
        if (isOpen) {
          toggleDropdown(null);

          if (highlightedIndex !== -1) {
            const selectedItem = childrenArray[highlightedIndex];
            if (isValidElement<DropdownItemProps>(selectedItem)) {
              handleSelectItem(selectedItem.props);
            }
          }
        }

        if (!isOpen) toggleDropdown(label);
      }

      if (key === 'Escape' && isOpen) {
        toggleDropdown(null);
      }

      if (key === 'Tab') {
        if (isOpen) toggleDropdown(null);
      }
    },
    [
      childrenArray,
      handleSelectItem,
      highlightedIndex,
      isOpen,
      label,
      toggleDropdown,
      setHighlightedIndex
    ]
  );

  return { handleKeyDown };
};
