import IconProps from './Icon.props';
import { AddToListIcon } from './svgs/addTolistIcon';
import { HeartIcon } from './svgs/heartIcon';
import { LinkedInIcon } from './svgs/linkedIn';
import { MessageSquareIcon } from './svgs/messageSquareIcon';
import { SendIcon } from './svgs/sendIcon';
import { TakeawaysIcon } from './svgs/takeawaysIcon';

export const SVGIcon = ({ iconName, className, testId }: IconProps) => {
  switch (iconName) {
    case 'takeaways': {
      return <TakeawaysIcon className={className} testId={testId} />;
    }
    case 'message-square':
      return <MessageSquareIcon className={className} testId={testId} />;
    case 'add-to-list':
      return <AddToListIcon className={className} testId={testId} />;
    case 'added-to-list':
      return (
        <AddToListIcon added={true} className={className} testId={testId} />
      );
    case 'send':
      return <SendIcon className={className} testId={testId} />;
    case 'heart':
      return <HeartIcon className={className} testId={testId} />;
    case 'heart-filled':
      return <HeartIcon filled={true} className={className} testId={testId} />;
    case 'linkedin':
      return <LinkedInIcon className={className} testId={testId} />;
    default:
      return <></>;
  }
};
