import { UseRefinementListProps } from 'react-instantsearch';
import { TOPICS_MODAL_CONSTANTS } from './constants';

const normalizeInput = (input: string): string => input.toLowerCase();

const valueToKeyTopicMapping = Object.entries(
  TOPICS_MODAL_CONSTANTS.TABS
).reduce(
  (acc, [key, values]) => {
    if (Array.isArray(values)) {
      values.forEach(value => {
        const lowerValue = normalizeInput(value);
        if (!acc[lowerValue]) {
          acc[lowerValue] = [];
        }
        acc[lowerValue].push(key);
      });
    }
    return acc;
  },
  {} as Record<string, string[]>
);

export const findKeyTopics = (topicValue: string): string[] => {
  const keyTopics = valueToKeyTopicMapping[topicValue];
  return keyTopics ? Array.from(new Set(keyTopics)) : [];
};

export const transformItems: UseRefinementListProps['transformItems'] =
  items => {
    const tabs = TOPICS_MODAL_CONSTANTS.TABS;
    const transformedTabs = [];

    const getRefItem = child => {
      const defaultItem = {
        count: 0,
        isRefined: false,
        value: normalizeInput(child),
        label: child,
        displayLabel: child,
        canRefine: false // not available to refine in search results-will be disabled
      };
      const foundItem = items.find(
        item => item.value === normalizeInput(child)
      );

      if (foundItem) {
        return {
          ...foundItem,
          displayLabel: child,
          canRefine: true
        };
      }
      return defaultItem;
    };

    Object.keys(tabs).forEach(tabName => {
      const children = tabs[tabName].map(child => getRefItem(child));

      transformedTabs.push({
        tabName,
        children
      });
    });

    return transformedTabs;
  };

export function countCheckedTopicsForTab(
  tabName: string,
  checkedState: { [x: string]: string | unknown[] }
): number {
  return checkedState?.[tabName]?.length || 0;
}
