import cx from 'classnames';

import Icon from '../Icon';
import Text from '../Text';

import { BadgeProps } from './Badge.props';

const Badge = ({
  backgroundColor = 'black',

  iconOptions = {
    iconName: '',
    iconPosition: 'leading'
  },

  isDarkBackground = true,
  testId,
  text,

  textProps = {
    variant: 'label',
    weight: 'font-semibold',
    useNewTextStyles: true,
    color: { isOnLightSurface: !isDarkBackground },
    testId: `${testId}-text`,
    UNSAFE_className: '!leading-none !tracking-[0.3px] !text-[10px]'
  },

  UNSAFE_className
}: BadgeProps) => {
  const { iconName: icon, iconPosition } = iconOptions;
  const iconOnTheRight = iconPosition === 'trailing';

  const iconClasses = cx(
    'text-tui-sm',
    isDarkBackground ? 'text-white' : 'text-black',
    iconOnTheRight ? 'order-last ml-1' : 'order-first mr-1'
  );

  const badgeClassName = cx(
    `bg-${backgroundColor}`,
    'flex size-min items-center justify-center whitespace-nowrap rounded-sm p-1 uppercase'
  );
  return (
    <div className={cx(badgeClassName, UNSAFE_className)} data-testid={testId}>
      {icon && (
        <Icon
          iconName={icon}
          testId={`${testId}-${icon}-icon`}
          className={iconClasses}
        />
      )}
      <Text {...textProps}>{text}</Text>
    </div>
  );
};

export default Badge;
