import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useInstantSearch, useMenu } from 'react-instantsearch';

import { Dropdown, DropdownItem } from 'components/@tedui';

import { SubtitleModal } from '../../../Modals';
import { useTrackClickEvent } from '../../../analytics';
import { updateUrlWithParams, waitForRouteChange } from '../../../utils';
import { FILTER_BAR_CONSTANTS } from '../constants';
import { transformItems } from './utils';

export function Subtitles(): React.ReactNode {
  const router = useRouter();
  const ISTALKSPAGE = router.pathname === '/talks';

  const { indexUiState, results } = useInstantSearch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  }>();

  const { refine, items } = useMenu({
    attribute: 'subtitle_languages',
    // the limit param is set to 200 because we want to get all of the subtitle languages
    limit: 200
  });

  const trackClick = useTrackClickEvent(
    results
      ? {
          index: results.index,
          nbHits: results.nbHits
        }
      : undefined
  );

  const initialItems = useMemo(() => {
    return transformItems(items);
  }, [items]);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
    trackClick({
      eventName: 'launch_subtitle_modal'
    });
  }, [trackClick]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleOnSelect = useCallback(
    async (label: string, value: string, context = 'modal') => {
      setSelectedOption({ label, value });

      trackClick({
        eventName: 'subtitle_selection',
        additionalEvents: {
          search_subtitle_language: value,
          event_interaction_context: context
        },
        waitForResultsVolume: true
      });

      updateUrlWithParams(router, {
        language: value
      });

      if (!ISTALKSPAGE) {
        await waitForRouteChange();
        refine(value);
      } else {
        refine(value);
      }

      if (isModalOpen) {
        closeModal();
      }
    },
    [
      trackClick,
      ISTALKSPAGE,
      isModalOpen,
      router,
      indexUiState.sortBy,
      refine,
      closeModal
    ]
  );

  useEffect(() => {
    const languageFromState = indexUiState?.menu?.subtitle_languages;

    if (languageFromState) {
      const currentSubtitleObj = FILTER_BAR_CONSTANTS.subtitleOptions.find(
        option => option.value === languageFromState
      );

      setSelectedOption({
        label:
          currentSubtitleObj?.label ||
          languageFromState.charAt(0).toUpperCase() +
            languageFromState.slice(1),
        value: currentSubtitleObj?.value || languageFromState
      });
    } else {
      setSelectedOption(undefined);
    }
  }, [indexUiState]);

  const renderModal = useMemo(() => {
    if (isModalOpen) {
      return createPortal(
        <SubtitleModal
          isOpen={isModalOpen}
          close={closeModal}
          onSelect={handleOnSelect}
          languages={items}
        />,
        document.body
      );
    }
  }, [isModalOpen, closeModal, handleOnSelect, items]);

  return (
    <>
      <Dropdown label="Subtitles" selectedValue={selectedOption}>
        {initialItems.map(({ label, value }) => (
          <DropdownItem
            key={value}
            label={label}
            value={value}
            onSelect={() => handleOnSelect(label, value, 'dropdown')}
            isSelected={value === selectedOption?.value}
          />
        ))}
        <DropdownItem
          label="See all"
          value="all"
          buttonOptions={{
            asButton: true,
            onClick: openModal
          }}
        />
      </Dropdown>
      {renderModal}
    </>
  );
}
