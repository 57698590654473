import cx from 'classnames';

import GridProps from './Grid.props';

const Grid = ({
  children,
  columns,
  testId = '',
  withPaddingAndMaxWidth = false
}: GridProps) => {
  const gridContainerClassName = cx(
    'relative grid gap-x-3 sm-tui:gap-x-4 md-tui:gap-x-5 2xl-tui:gap-x-6',
    {
      [`grid-cols-${columns.xs}`]: columns.xs,
      [`sm-tui:grid-cols-${columns.sm}`]: columns.sm,
      [`md-tui:grid-cols-${columns.md}`]: columns.md,
      [`lg-tui:grid-cols-${columns.lg}`]: columns.lg,
      [`xl-tui:grid-cols-${columns.xl}`]: columns.xl,
      [`2xl-tui:grid-cols-${columns.xxl}`]: columns.xxl,
      'mx-3 max-w-[1440px] sm-tui:mx-4 md-tui:mx-10 lg-tui:mx-[42px] xl-tui:mx-16':
        withPaddingAndMaxWidth
    }
  );

  return (
    <div className={gridContainerClassName} data-testid={testId}>
      {children}
    </div>
  );
};

export default Grid;
