import cx from 'classnames';

import { Icon, Text } from 'components/@tedui';

import { useDropdownStore } from '../store';
import type { DropdownItemProps } from './DropdownItem.props';

/**
 * DropdownItem is an individual item within the Dropdown component.
 * It can be selected by the user and calls the onSelect callback when clicked.
 * @param label - The display label for the dropdown item.
 * @param value - The value of the dropdown item.
 * @param onSelect - The callback function called when the item is selected.
 * @param buttonOptions - The object used to convert Dropdown item to use a button (usually at the end of a list). Takes onClick and asButton arguments.
 * @param isSelected - A boolean indicating whether the item is currently selected/highlighted.
 * @returns The DropdownItem with the specified label, value, and functionality.
 */
export const DropdownItem = ({
  label,
  value,
  onSelect,
  buttonOptions,
  isSelected
}: DropdownItemProps) => {
  const { toggleDropdown } = useDropdownStore();
  const handleClick = () => {
    if (onSelect && !buttonOptions) {
      onSelect(value);
    }

    if (buttonOptions) {
      buttonOptions.onClick?.();
    }

    toggleDropdown(null);
  };

  return (
    <li
      role="option"
      aria-selected={isSelected}
      className={cx('group hover:bg-gray-300', 'w-35', {
        'bg-gray-300': isSelected
      })}
    >
      <button
        type="button"
        className={cx('w-full p-2 pr-4 text-left', {
          'flex items-center gap-4': buttonOptions
        })}
        onClick={handleClick}
      >
        <Text variant="body2" {...(buttonOptions && { isBold: true })}>
          {label}
        </Text>
        {buttonOptions && <Icon iconName="arrow-right-long" />}
      </button>
      <hr className="mx-2 h-px border-none bg-black/16 group-last-of-type:hidden" />
    </li>
  );
};
