import { Button, LoadingAnimation, Text } from 'components/@tedui';

import { useDarkMode } from 'components/theme/DarkModeProvider';
import type { PaginationProps } from './Pagination.props';

export const Pagination = ({
  visibleItems,
  totalItems,
  showLoadMoreButton = true,
  isLoadingMoreItems = false,
  showCount = true,
  error = false,
  errorMessage = 'Something went wrong. Please try again.',
  onRetry,
  buttonOptions
}: PaginationProps) => {
  const { isDarkMode } = useDarkMode();

  return (
    <div className="mx-auto mt-4 flex w-full max-w-[18.5rem] flex-col gap-3 text-center lg-tui:mt-8">
      {showCount && !isLoadingMoreItems && !error && totalItems > 0 && (
        <Text
          variant="body2"
          tag="p"
          useNewTextStyles
          weight="font-normal"
          isBold={false}
          color={{
            isOnLightSurface: !isDarkMode
          }}
        >
          {visibleItems} of {totalItems}
        </Text>
      )}

      {isLoadingMoreItems && !error && (
        <div
          data-testid="Items List Loading Animation"
          className="mx-auto h-24 w-24"
        >
          <LoadingAnimation />
        </div>
      )}

      {showLoadMoreButton &&
        !isLoadingMoreItems &&
        !error &&
        totalItems > 0 && (
          <div>
            <Button variant="secondary" {...buttonOptions} />
          </div>
        )}

      {error && (
        <div className="flex flex-col items-center gap-2">
          <Text
            variant="body2"
            tag="p"
            useNewTextStyles
            weight="font-normal"
            isBold={false}
            color={{
              isOnLightSurface: !isDarkMode
            }}
          >
            {errorMessage}
          </Text>
          {onRetry && (
            <Button
              variant="secondary"
              onClick={onRetry}
              isDarkBackground={isDarkMode}
            >
              Retry
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
