const sortByOptions = [
  {
    label: 'Newest',
    value: 'newest'
  },
  {
    label: 'Oldest',
    value: 'oldest'
  },
  {
    label: 'Relevance',
    value: 'relevance'
  },
  {
    label: 'Most viewed',
    value: 'popular'
  }
];

const subtitleOptions = [
  {
    // English
    label: 'English',
    value: 'english'
  },
  {
    // Spanish
    label: 'Español',
    value: 'spanish'
  },
  {
    // Japanese
    label: '日本語',
    value: 'japanese'
  },
  {
    // Portuguese, Brazilian
    label: 'Português brasileiro',
    value: 'portuguese, brazilian'
  },
  {
    // Chinese, Traditional
    label: '中文 (繁體)',
    value: 'chinese, traditional'
  }
];

const durationOptions = [
  {
    label: '0-6 minutes',
    start: 0,
    end: 360,
    queryString: '0-6',
    value: '%7B%22start%22:0,%22end%22:360%7D',
    trackingValue: '0-6min'
  },
  {
    label: '6-12 minutes',
    start: 360,
    end: 720,
    queryString: '6-12',
    value: '%7B%22start%22:360,%22end%22:720%7D',
    trackingValue: '6-12min'
  },
  {
    label: '12-18 minutes',
    start: 720,
    end: 1080,
    queryString: '12-18',
    value: '%7B%22start%22:720,%22end%22:1080%7D',
    trackingValue: '12-18min'
  },
  {
    label: '18+ minutes',
    start: 1080,
    queryString: '18',
    value: '%7B%22start%22:1080%7D',
    trackingValue: '18+min'
  }
];

export const FILTER_BAR_CONSTANTS = {
  sortByOptions,
  subtitleOptions,
  durationOptions
} as const;
