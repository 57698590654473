import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { DialogContent, DialogOverlay } from '../Dialog/Dialog';

import { Container, Grid, Icon } from 'components/@tedui';
import { useIsMobile } from 'lib/hooks/useIsBreakpointWidth/useIsMobile';

import { tuiBreakpoints } from '../utils/constants';
import ModalProps, { ModalType } from './Modal.props';

const Modal = ({
  type = ModalType.Large,
  children,
  isOpen,
  onDismiss,
  stickyCloseButton,
  addPadding,
  isTall,
  hasMaxWidth,
  showCloseButton = true,
  testId,
  isScrollable = false
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const { isDesktop: isLargeBreakpoint } = useIsMobile({
    checkDevice: false,
    breakPointType: 'tui'
  });

  const [shouldRenderGrid, setShouldRenderGrid] = useState(true);

  useEffect(() => {
    setShouldRenderGrid(isLargeBreakpoint);
  }, [isLargeBreakpoint]);

  useEffect(() => {
    const { lg } = tuiBreakpoints;
    const handleResize = () => {
      if (modalRef) {
        if (window.innerWidth >= lg) {
          setShouldRenderGrid(true);
        } else {
          setShouldRenderGrid(false);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [modalRef, isLargeBreakpoint]);

  const isSmall = type === ModalType.Small;
  const isMedium = type === ModalType.Medium;
  const isLarge = type === ModalType.Large;

  const overlayClasses = cx(
    'fixed inset-0 z-40 flex h-full animate-fadeIn items-end justify-center bg-black bg-opacity-20',
    {
      'md-tui:block': isSmall || isMedium,
      'xl-tui:block': isLarge,
      'overflow-y-scroll': isScrollable
    }
  );

  const renderModal = () => {
    const modalClasses = cx(
      'relative h-auto min-h-drawer w-full rounded-t-md bg-white',
      {
        'max-h-1/3-screen xl-tui:max-h-1/2-screen': isSmall,
        'max-h-1/2-screen xl-tui:max-h-3/5-screen': isMedium,
        'no-scrollbar animate-slideInModal overflow-y-auto md-tui:min-h-modal md-tui:animate-none md-tui:rounded-md':
          isSmall || isMedium,
        'lg-tui:min-h-modal lg-tui:rounded-md': isLarge,
        'px-5 pt-6 md-tui:pl-12 md-tui:pr-5 md-tui:pt-5': addPadding,
        'max-w-xl': isMedium && hasMaxWidth
      }
    );

    const buttonClasses = cx(
      'z-20 float-right block -translate-y-25 translate-x-25 rounded-full bg-[transparent] p-25 text-black hover:text-gray-700',
      {
        'right-5 top-6 mr-5 mt-6 md-tui:top-5 md-tui:mt-5': !addPadding,
        'top-0': (isSmall || isMedium) && addPadding,
        'sticky z-10': stickyCloseButton,
        'top-6 md:top-5': isLarge,
        'xl-tui:top-0': addPadding && isLarge
      }
    );

    const tallStyles = {
      maxHeight: '90vh'
    };

    return (
      <DialogContent
        style={isTall ? tallStyles : undefined}
        className={modalClasses}
        ref={modalRef as React.RefObject<HTMLDivElement>}
        aria-label="modal"
      >
        {showCloseButton && (
          <button
            type="button"
            className={buttonClasses}
            onClick={onDismiss}
            tabIndex={0}
          >
            <Icon iconName="x" className="text-2xl text-black" />
          </button>
        )}
        <div>{children}</div>
      </DialogContent>
    );
  };

  const renderWithGrid = () => {
    const gridWrapperClasses = cx('hidden h-full', {
      'md-tui:block': isSmall || isMedium,
      'lg-tui:block': isLarge
    });

    const modalClasses = cx(
      'flex h-screen w-full items-center justify-center',
      {
        'm-auto max-w-[730px]': isLarge
      }
    );

    return (
      <Container className={gridWrapperClasses}>
        <Grid columns={{ xs: 1 }}>
          <div className={modalClasses}>{renderModal()}</div>
        </Grid>
      </Container>
    );
  };

  const renderWithoutGrid = () => {
    const wrapperClasses = cx('relative block h-auto', {
      'w-full animate-slideInModalWrapper xl:hidden': isLarge,
      'md:hidden': isSmall || isMedium
    });

    const innerWrapperClasses = cx('max-h-100vh w-full', {
      'h-auto': isSmall || isMedium,
      'h-full': isLarge
    });

    return (
      <div className={wrapperClasses}>
        <div className={innerWrapperClasses}>{renderModal()}</div>
      </div>
    );
  };

  return (
    <DialogOverlay
      className={overlayClasses}
      isOpen={isOpen}
      onDismiss={onDismiss}
      data-testid={testId}
    >
      {/* we need two versions because of different look/behavior across breakpoints and modal types */}
      {shouldRenderGrid ? renderWithGrid() : renderWithoutGrid()}
    </DialogOverlay>
  );
};

export default Modal;
