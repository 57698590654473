const breakpoints = {
  sm: 320,
  md: 700,
  lg: 1100,
  xl: 1400,
  '2xl': 1800,
  '2.5xl': 1935, // this is to handle the maxWidth for Video Player
  '3xl': 2200
};

const adBreakpoints = {
  standard: {
    sm: 808, // mobile
    md: 1055, // Tablet
    lg: 1057 // Desktop
  },
  playlists: {
    sm: 1289, // mobile
    md: 1290, // Tablet
    lg: 1300 // Desktop
  },
  topicResults: {
    sm: 1099, // mobile
    md: 1100, // Tablet
    lg: 1300 // Desktop
  }
};

const prismicBreakpoints = {
  mobileXS: 375,
  mobileSM: 430,
  mobileMD: 639,
  mobileLG: 640,
  tabletPortrait: 768,
  tabletLandScape: 1024,
  desktop: 1280
};

/*
 *  Use default TW breakpoints in place of above breakpoints for all TED UI work
 *  https://tailwindcss.com/docs/screens
 */
const tuiBreakpoints = {
  xs: 320,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1540,
  '3xl': 1920
};

const tuiMaxContainerWidth = '1540px';

module.exports = {
  adBreakpoints,
  breakpoints,
  tuiBreakpoints,
  tuiMaxContainerWidth,
  prismicBreakpoints
};
