import { debounce } from 'lodash-es';
import { useEffect } from 'react';
import { useInstantSearch } from 'react-instantsearch';

import mixpanel from 'lib/analytics/mixpanel';
import { SearchResults, useGenerateMixpanelPayload } from '../utils';

const mainIndex = process.env.NEXT_PUBLIC_ELASTIC_SEARCH_TALKS_INDEX_NAME;

// Modified to accept search results as a parameter
export function useTrackScreenViews(results?: SearchResults): null {
  const { uiState } = useInstantSearch();

  // Pass the results to useGenerateMixpanelPayload
  const [payload] = useGenerateMixpanelPayload(results);

  const sendEventDebounced = debounce(() => {
    // Fix the linter error by ensuring mainIndex is not undefined
    if (!mainIndex || !uiState[mainIndex]) return;

    const stateForIndex = uiState[mainIndex];

    if (!stateForIndex) return;

    mixpanel.track('screen_view', payload);
    // Also Use the new Track PageView function that's built for SPA to send custom Info
    mixpanel.track_pageview(payload);
  }, 3000);

  useEffect(() => {
    sendEventDebounced();

    return () => {
      sendEventDebounced.cancel();
    };
  }, [uiState, payload]);

  return null;
}
