import cx from 'classnames';
import { memo } from 'react';

import type { GridProps } from './Grid.props';
import { renderClasses, renderDivideClasses } from './utils';

const DEFAULTS = {
  columns: {
    xs: 2
  },
  rows: {
    xs: 'auto'
  },
  spacing: {
    xs: {
      row: 3,
      column: 6
    },
    md: {
      row: 5
    },
    lg: {
      column: 8
    }
  }
};

/**
 * The Grid component is a generic layout component that creates a grid using CSS grid.
 * It accepts props for specifying the number of columns and spacing between grid items.
 * @param columns - Specifies the number of columns for each breakpoint.
 * @param spacing - Specifies the spacing (gap) between grid items for each breakpoint.
 * @param children - Child elements to be rendered within the grid.
 * @returns The Grid component with the specified number of columns and spacing.
 */
const Grid = memo(
  ({
    columns: columnsProp,
    rows: rowsProp,
    spacing: spacingProp,
    divide: divideProp,
    addMargin = false,
    children
  }: GridProps) => {
    const columns = { ...DEFAULTS.columns, ...columnsProp };
    const rows = { ...DEFAULTS.rows, ...rowsProp };
    const spacing = { ...DEFAULTS.spacing, ...spacingProp };

    const gridColumnClasses = renderClasses({
      prop: columns,
      classPrefix: 'grid-cols'
    });

    const gridRowClasses = renderClasses({
      prop: rows,
      classPrefix: 'grid-rows'
    });

    const rowSpacingClasses = renderClasses({
      prop: spacing,
      classPrefix: 'gap-x',
      nestedProp: 'row'
    });

    const columnSpacingClasses = renderClasses({
      prop: spacing,
      classPrefix: 'gap-y',
      nestedProp: 'column'
    });

    const outerMarginClasses = cx({
      'xs-tui:mx-3 sm-tui:mx-5 md-tui:mx-10 xl-tui:mx-16': addMargin
    });

    const divideClasses = renderDivideClasses(divideProp);

    const classes = `grid ${gridColumnClasses}${gridRowClasses}${rowSpacingClasses}${columnSpacingClasses}${divideClasses}${outerMarginClasses}`;

    return <div className={classes}>{children}</div>;
  }
);

export default Grid;
