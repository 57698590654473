import { Text } from 'components/@tedui';
import Divider from 'components/divider';

import { SubtitleGroupProps } from './SubtitleGroup.props';

/**
 * SubtitleGroup represents a section of languages grouped by a specific letter.
 * It allows users to browse and select a language for subtitles.
 * @param data - Language data grouped by a letter.
 * @param onSelect - A function to refine the search results by the selected language.
 * @param isLastGroup - A boolean to indicate if it's the last group in the list.
 * @returns The SubtitleGroup component with the given language options.
 */
export function SubtitleGroup({
  data,
  isLastGroup,
  onSelect
}: SubtitleGroupProps): React.ReactNode {
  return (
    <div id={data.letter.toLowerCase()} role="group">
      <div className="md:tui:py-4 flex flex-col items-start py-2 md-tui:flex-row md-tui:pt-4">
        <p className="shrink-0 pb-4 pt-2 text-sm font-bold tracking-normal md-tui:sticky md-tui:top-0 md-tui:w-1/5 md-tui:pb-0 md-tui:text-lg">
          {data.letter}
        </p>
        <ul className="column-count-2 md-tui:column-count-3 lg-tui:column-count-3 w-full pb-3">
          {data.items.map(item => {
            return (
              <li key={item.slug} className="inline-block w-full py-2">
                <button
                  type="button"
                  className="mb-1 block hover:underline"
                  tabIndex={0}
                  onClick={() => onSelect(item.name, item.slug)}
                >
                  <div className="text-left">
                    <Text tag="p" variant="body2">
                      <span className="capitalize">{item.name}</span>
                    </Text>
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      {!isLastGroup && <Divider />}
    </div>
  );
}
