import cx from 'classnames';

import { useEffect, useRef } from 'react';

import {
  Card,
  CardBody,
  CardLabel,
  CardMedia,
  CardTitle
} from 'components/@tedui';
import { GridItem } from 'components/@tedui/Layout';
import { useTrackClickEvent } from 'components/pages/talks/TalkIndex/analytics';
import { Link } from 'components/router';

import getImageFromSet from 'lib/get-image-from-set';
import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import { useOptimizedImage } from 'lib/hooks/useOptimizedImage';
import { formatAsTimestamp } from 'lib/util';

import { PhotoSize } from 'api';
import { useInstantSearch } from 'react-instantsearch';
import type { TalkGridItemProps } from './TalkGridItem.props';

/**
 * The TalkGridItem component is a specialized component that represents an individual talk item within a TalkGrid.
 * It includes information about the talk such as title, speaker, duration, and image.
 * @param talk - The data object for the talk, including title, speaker, duration, slug, and image set.
 * @param isOnResultsPage - A boolean indicating if the component is on the results page.
 * @returns The TalkGridItem component with the talk information and specified layout.
 */
export const TalkGridItem = ({
  talk,
  position,
  isOnResultsPage
}: TalkGridItemProps) => {
  const {
    title,
    duration,
    speakers,
    slug,
    primaryImageSet,
    presenterDisplayName
  } = talk;

  const { results } = useInstantSearch();
  const isMobileWidth = useIsBreakpointWidth({
    size: 'md',
    breakPointType: 'tui'
  });
  const isMobileOnResultsPage = isOnResultsPage && isMobileWidth;
  const imageSrc = getImageFromSet(
    primaryImageSet as PhotoSize[],
    '16x9',
    true
  );
  const trackClick = useTrackClickEvent(
    results
      ? {
          index: results.index,
          nbHits: results.nbHits
        }
      : undefined
  );

  const imageContainerRef = useRef<HTMLDivElement>(null);
  const calculatedDimensions = useRef<{ width: number; height: number }>({
    width: imageSrc?.width || 0,
    height: imageSrc?.height || 0
  });

  const label = speakers
    ? `${speakers?.nodes?.[0]?.firstname} ${speakers?.nodes?.[0]?.lastname}`
    : (presenterDisplayName ?? '');
  const badge = {
    bottomRight: {
      text: formatAsTimestamp(duration)
    }
  };

  useEffect(() => {
    const updateDimensions = () => {
      if (imageContainerRef.current) {
        const { width, height } =
          imageContainerRef.current.getBoundingClientRect();
        calculatedDimensions.current.width = width * 2;
        calculatedDimensions.current.height = height * 2;
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [title]);

  const { src, blurredSrc, setIsLoading } = useOptimizedImage({
    imageUrl: imageSrc?.url ?? '',
    width: isMobileOnResultsPage ? 92 : calculatedDimensions.current.width,
    height: isMobileOnResultsPage ? 63 : calculatedDimensions.current.height
  });

  return (
    <GridItem>
      <Link
        href={`/talks/${slug}`}
        isZenithLink
        data-testid="TalkGrid Talk Item"
      >
        <Card layout={isMobileOnResultsPage ? 'horizontal' : 'vertical'}>
          <div
            ref={imageContainerRef}
            style={{
              height: isMobileOnResultsPage ? '3.25rem' : 'auto',
              width: isMobileOnResultsPage ? '5.75rem' : 'auto'
            }}
          >
            <CardMedia
              badges={badge}
              image={{
                src: src ?? blurredSrc ?? '',
                alt: title,
                aspect: 'rectangle'
              }}
              blurUntilLoaded
              setIsLoading={setIsLoading}
              onClick={() =>
                trackClick({
                  eventName: 'talk_index_click',
                  additionalEvents: {
                    event_interaction_context: 'talk_thumbnail',
                    click_position: position
                  }
                })
              }
            />
          </div>
          <CardBody
            UNSAFE_className="flex-1 md-tui:flex-auto !pt-0 md-tui:!pt-2"
            addHorizontalPadding={isMobileOnResultsPage}
          >
            <div
              role="none"
              onClick={() =>
                trackClick({
                  eventName: 'talk_index_click',
                  additionalEvents: {
                    event_interaction_context: 'talk_title',
                    click_position: position
                  },
                  waitForResultsVolume: true
                })
              }
            >
              <CardTitle
                text={title}
                isBold={!isMobileOnResultsPage}
                truncateText={false}
                UNSAFE_className={cx({
                  '!font-medium': isMobileOnResultsPage
                })}
                variant="subheader2"
              />
            </div>
            {label && (
              <CardLabel
                addBottomMargin={false}
                color={{ color: 'tertiary' }}
                isBold={false}
              >
                {label}
              </CardLabel>
            )}
          </CardBody>
        </Card>
      </Link>
    </GridItem>
  );
};
