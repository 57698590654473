import { FILTER_BAR_CONSTANTS } from '../constants';

const transformItems = items => {
  const labelMapping = FILTER_BAR_CONSTANTS.subtitleOptions.reduce(
    (acc, option) => {
      acc[option.value] = option.label;
      return acc;
    },
    {}
  );

  const itemsMap = items.reduce((acc, item) => {
    acc[item.value] = item;
    return acc;
  }, {});

  return FILTER_BAR_CONSTANTS.subtitleOptions.map(option => {
    const item = itemsMap[option.value];
    return item
      ? {
          ...item,
          label: labelMapping[item.value],
          value: item.value
        }
      : {
          label: labelMapping[option.value],
          value: option.value,
          count: 0,
          isRefined: false
        };
  });
};

export { transformItems };
