import CardCaptionProps from './CardCaption.props';

import Text from '../../Text';

const CardCaption = ({ text, testId }: CardCaptionProps) => (
  <div className="mb-2 leading-none">
    <Text variant="caption" color={{ color: 'tertiary' }} testId={testId}>
      {text}
    </Text>
  </div>
);

export default CardCaption;
