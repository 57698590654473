import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useId,
  useState
} from 'react';

type TabsContextType = {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  keyboardActivation: 'auto' | 'manual';
  registerTab: (index: number) => void;
  registerPanel: (index: number) => void;
};

const TabsContext = createContext<TabsContextType>({
  selectedIndex: 0,
  setSelectedIndex: () => {},
  keyboardActivation: 'auto',
  registerTab: () => {},
  registerPanel: () => {}
});

type TabsProps = {
  children: React.ReactNode;
  index?: number;
  onChange?: (index: number) => void;
  keyboardActivation?: 'auto' | 'manual';
  className?: string;
};

export const Tabs = ({
  children,
  index: controlledIndex,
  onChange,
  keyboardActivation = 'auto',
  className
}: TabsProps) => {
  const [selectedIndex, setSelectedIndex] = useState(controlledIndex || 0);
  const [tabs] = useState(new Set<number>());
  const [panels] = useState(new Set<number>());

  useEffect(() => {
    if (controlledIndex !== undefined) {
      setSelectedIndex(controlledIndex);
    }
  }, [controlledIndex]);

  const handleSelect = useCallback(
    (index: number) => {
      if (controlledIndex === undefined) {
        setSelectedIndex(index);
      }
      onChange?.(index);
    },
    [controlledIndex, onChange]
  );

  const registerTab = useCallback((index: number) => {
    tabs.add(index);
  }, []);

  const registerPanel = useCallback((index: number) => {
    panels.add(index);
  }, []);

  return (
    <TabsContext.Provider
      value={{
        selectedIndex,
        setSelectedIndex: handleSelect,
        keyboardActivation,
        registerTab,
        registerPanel
      }}
    >
      <div className={className} role="tablist">
        {children}
      </div>
    </TabsContext.Provider>
  );
};

type TabListProps = {
  children: React.ReactNode;
  className?: string;
  ref?: React.RefObject<HTMLDivElement>;
};

export const TabList = React.forwardRef<HTMLDivElement, TabListProps>(
  ({ children, className }, ref) => {
    return (
      <div ref={ref} className={className} role="tablist">
        {children}
      </div>
    );
  }
);

TabList.displayName = 'TabList';

type TabProps = {
  children: React.ReactNode;
  className?: string;
  index?: number;
};

export const Tab = ({ children, className, index: tabIndex }: TabProps) => {
  const { selectedIndex, setSelectedIndex, keyboardActivation, registerTab } =
    useContext(TabsContext);
  const id = useId();
  const index = tabIndex ?? 0;

  useEffect(() => {
    registerTab(index);
  }, [index, registerTab]);

  const handleClick = () => {
    setSelectedIndex(index);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (keyboardActivation === 'manual' && event.key !== 'Enter') {
      return;
    }

    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault();
        setSelectedIndex(index);
        break;
    }
  };

  return (
    <button
      role="tab"
      aria-selected={selectedIndex === index}
      aria-controls={`panel-${id}-${index}`}
      id={`tab-${id}-${index}`}
      className={className}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      tabIndex={selectedIndex === index ? 0 : -1}
      type="button"
    >
      {children}
    </button>
  );
};

type TabPanelsProps = {
  children: React.ReactNode;
  className?: string;
};

export const TabPanels = ({ children, className }: TabPanelsProps) => {
  return <div className={className}>{children}</div>;
};

type TabPanelProps = {
  children: React.ReactNode;
  className?: string;
  index?: number;
};

export const TabPanel = ({
  children,
  className,
  index: panelIndex
}: TabPanelProps) => {
  const { selectedIndex, registerPanel } = useContext(TabsContext);
  const id = useId();
  const index = panelIndex ?? 0;

  useEffect(() => {
    registerPanel(index);
  }, [index, registerPanel]);

  if (selectedIndex !== index) {
    return null;
  }

  return (
    <div
      role="tabpanel"
      aria-labelledby={`tab-${id}-${index}`}
      id={`panel-${id}-${index}`}
      className={className}
      tabIndex={0}
    >
      {children}
    </div>
  );
};
